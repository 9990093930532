import axios from "axios";
import { Team, TeamDetail, TeamPatch, TeamRoles } from "../types/team.types";
import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types";
import { generateURLQuery } from "../../../utils/urlQueryGenerator";
import { Employee } from "../../employee/types/employee.type";
import { SpendingDemandExtended } from "../../spendingDemand/types/spendingDemand.types";


/*===================================================================
=     TEAM API              =
====================================================================*/
const BASE_URL = process.env.REACT_APP_URL_BACKEND

/**
 * Fetch Team Filter Options
 */
export interface FetchTeamFilterOption{
    creatorUserId?: string|null;
    pageSize?: number | null;
    currentPage?: number | null;
    managerEmployeeId?: string |null;
    memberEmployeeId?: string | null;
    projectId?: string | null;
    role?: TeamRoles | null;

}

/**
 * 
 */
export const fecthTeamAPIProtected = (filterOptions: FetchTeamFilterOption,   setUploadProgressPoncentage?: (progress: number) => void | null) => {

  const query = generateURLQuery([
  {
    key: 'extendedfields',
    value: 'project'
  }, 
  {
    key: "pageSize",
    value: filterOptions.pageSize
  },
  {
    key: "currentPage",
    value: filterOptions.currentPage
  },
  {
    key: "projectId",
    value: filterOptions.projectId
  },
  {
    key: "creatorUserId",
    value: filterOptions.creatorUserId
  },
  {
    key: "managerEmployeeId",
    value: filterOptions.managerEmployeeId
  },
  {
    key: "memberEmployeeId",
    value: filterOptions.memberEmployeeId
  
  },
  {
    key:"role",
    value: filterOptions.role
  }
])
  //Headers
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.get<ResponseAPIWithPagination<TeamDetail>>(
    `${BASE_URL}/teams${query}`);
}

const getFetchTeamAPI = async (filterOptions: FetchTeamFilterOption, page?: number) => {

  const data = (await fecthTeamAPIProtected({pageSize: 8, currentPage:page,...filterOptions })).data;

  return data 
}




const fecthSingleTeamProtected = (teamId: string) => {

  return axios.get<TeamDetail>(
    `${BASE_URL}/teams/${teamId}?extendedfields=project,manager`);

}

const getFecthSingleTeamAPI = async (teamId: string) => {
  return (await fecthSingleTeamProtected(teamId)).data
}


const fetchMembersListOfTeamProtected = (teamId: string) => {
  return axios.get<ResponseAPIWithPagination<Employee>>(
    `${BASE_URL}/teams/${teamId}/employees`);
  }

const fetchTeamAllSpendingDemandProtected = async (teamId: string, currentMnagaerEmployeeId: string | null | undefined, page?: number) => {
  return axios.get<ResponseAPIWithPagination<SpendingDemandExtended>>(
    `${BASE_URL}/teams/${teamId}/spendingdemands?extendedfields=user&orderBy=-spending_demand_date`
  )
}

const getFetchTeamAllSpendingDemand = async (teamId: string, currentMnagaerEmployeeId: string | null | undefined, ) => {
  return (await fetchTeamAllSpendingDemandProtected(teamId,currentMnagaerEmployeeId)).data

}
const getFetchMembersListOfTeam = async (teamId: string) => {
  return (await fetchMembersListOfTeamProtected(teamId)).data

}

const importTeamByCSV = async (csvData: string) => {
  return (await axios.post(`${BASE_URL}/teams/import`, {csv_data: csvData})).data;
}

const createNewTeam = async (team: TeamPatch) => {
  return (await axios.post<Team>(`${BASE_URL}/teams`, team)).data;
}
const updateTeam = async (idTeam: string, team: TeamPatch) => {
  return (await axios.put<Team>(`${BASE_URL}/teams/${idTeam}`, team)).data;
}
const deleteTeam = async (idTeam: string) => {
  return (await axios.delete(`${BASE_URL}/teams/${idTeam}`)).data;
}
export default {
  getFetchTeamAPI,
  getFecthSingleTeamAPI,
  getFetchMembersListOfTeam,
  getFetchTeamAllSpendingDemand,
  importTeamByCSV,
  createNewTeam,
  updateTeam,
  deleteTeam,
  fecthTeamAPIProtected
}