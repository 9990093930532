/*===================================================================
=     APP COMPTA ROOT              =
====================================================================*/
import "./App.scss";
import Home from "./page/Home/Home";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/configureStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LayoutContextProvider } from "./utils/LayoutContext/LayoutContext";
import { themeMJB } from "./styles/theme/themeMaterialUI";
import { InfoModalProvider } from "./components/organismes/InfoModalV2/context/infoModalV2Context";


function App() {
  const defaultTheme = createTheme(themeMJB);
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
  });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={defaultTheme}>
      
            <BrowserRouter>
              <LayoutContextProvider>
                <InfoModalProvider>
                <Home />
                </InfoModalProvider>
              </LayoutContextProvider>
            </BrowserRouter>
        
          </ThemeProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
