/*================================================================
 * URL Query Generator
 *================================================================*/

export interface QueryURL {
  key: string;
  value: string | null | undefined | number;
}
/**
 * Generates a URL query string from a list of query
 * @param queryList 
 * @returns 
 */
export const generateURLQuery = (queryList: QueryURL[]) => {
  const resultQuery = queryList.filter(
    (query) => query.value !== undefined && query.value !== null
  );

  const res = resultQuery.reduce((acc, query, index) => {
    if (index === 0) {
      acc = "?";
    }

    if (query.value === null || query.value === undefined) {
      return acc;
    }
    
    return (
      acc +
      `${query.key}=${query.value}${index < resultQuery.length - 1 ? "&" : ""}`
    );
  }, "");

  return res;
};


export function updateUrlParameter(url: string, paramName: string, newValue: string | null): string {
  // Create a regular expression to search for the parameter in the URL
  const regex = new RegExp(`([?&])${paramName}=.*?(&|$)`, 'i');

  // Check if the parameter exists in the URL
  if (url.match(regex)) {
      // If the new value is not null, replace it with the new value
      if (newValue !== null) {
          url = url.replace(regex, `$1${paramName}=${newValue}$2`);
      } else {
          // If the new value is null, remove the parameter from the URL
          url = url.replace(regex, '');
      }
  } else {
      // If the parameter does not exist, add it to the URL if the new value is not null
      if (newValue !== null) {
          const separator = url.includes('?') ? '&' : '?';
          url = url + separator + `${paramName}=${newValue}`;
      }
  }

  return url;
}