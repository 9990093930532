/*===================================================================
=       USE SPENDING DEMAND CORE                   =
====================================================================*/
/**
 * 
 * 
 */

import { AxiosError } from "axios";
import { handlerErrorFunctions } from "../../../../utils/errorHlandlerUtils";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import SpendingDemandAPI from "../../services/SpendingDemandAPI";
import { FilterSpendingDemand } from "../../types/spendingDemand.types";
import SpendingDemandListFilterPresenter from "../../components/SpendingDemandList/SpendingDemandListFilter/SpendingDemandListFilterPresenter";

import useSpendingDemandFilter from "../useSpendingDemandFilter";

export interface SpendingDemandSettings {
  handleNetworkErrorFunction:
    | ((error: AxiosError<unknown, any>, SreenContext: string | null) => void)
    | null;
  handleInternErrorFunction:
    | ((errorMessage: string | null, errorCode: number | null) => void)
    | null;
  getCurrentPage: (() => number | null) | null;

  getCurrentFilter : () => FilterSpendingDemand;

  employeeId: string;
  userId: string;

}



export const useSpendingDemandCore = ( {handleNetworkErrorFunction,handleInternErrorFunction,getCurrentPage, getCurrentFilter, employeeId  }:SpendingDemandSettings
) => {
    const  {handleNetworkError, handleInternError} = handlerErrorFunctions( handleNetworkErrorFunction,handleInternErrorFunction )


    const currentFilter = getCurrentFilter();
    const [page, setPage] = useState(1); 
    const [filter, setFilter] = useState<FilterSpendingDemand | null>(currentFilter)
    const currentPage = getCurrentPage ? getCurrentPage() : null ;

    const {filter: filterV2} = useSpendingDemandFilter()
    useEffect(() => {
      if(currentPage){
        setPage(currentPage)
      }
        setFilter(filterV2)
      
    },[currentPage, currentFilter, filterV2])

    const allSpendingDemandListQuery = useQuery({
      queryFn: () => SpendingDemandAPI.getFetchAllSpendingDemand(10, page,  filter, employeeId, false ),
      queryKey: ["spendingdemand", {page, filter}]
    })

    useEffect(() => {
      if(allSpendingDemandListQuery.isError){
        handleNetworkError(allSpendingDemandListQuery.error as AxiosError, "spendingDemandList")
      }
    },[allSpendingDemandListQuery.isError])

    return {
      allSpendingDemandList:{
        data: allSpendingDemandListQuery.data,
        isLoading: allSpendingDemandListQuery.isLoading,
        isError: allSpendingDemandListQuery.isError,
        error: allSpendingDemandListQuery.error,
        isFetching: allSpendingDemandListQuery.isFetching,
        isSuccess: allSpendingDemandListQuery.isSuccess,
      }
    }
} 

export default useSpendingDemandCore