import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';


export interface LoaderProps {
    centered ?: boolean,
    paddingTop ?: string,
    size ?: number
}

export default function Loader({centered, paddingTop, size}: LoaderProps) {

    const dynamicStyle = centered ? {display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: paddingTop || "0"} : {}
  return (
    <div style={dynamicStyle}>
      <CircularProgress size={size} color='secondary'/>
    </div>
  )
}
