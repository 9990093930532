/*===================================================================
=       USE SPENDING DEMAND                 =
====================================================================*/
/**
 * 
 * 
 */

import { useLocation, useParams } from "react-router-dom"
import { useSingleSpendingDemandCore } from "./core/useSingleSpendingDemand"
import useSpendingDemandCore from "./core/useSpendingDemandCore"
import { FilterSpendingDemand } from "../types/spendingDemand.types";
import useUserAuth from "../../auth/hook/useUserAuth";
import useErrorHandler from "../../../hook/useErrorHandler";

export const useSpendingDemand = () => {

    const location : string = useLocation().search;
    let  pageParams  = new URLSearchParams(location).get('page');

    const {userInfo} = useUserAuth()
    const getCurrentPage = () => pageParams ? parseInt(pageParams) : null
    const { addTokenIntheHeaderRequest} = useUserAuth()
    addTokenIntheHeaderRequest()
    const getCurrentFilter : () => FilterSpendingDemand= () => {
     
        let  statusParams  = new URLSearchParams(location).get('statusSpendingDemand');
        let  orderByParams  = new URLSearchParams(location).get('orderBySpendingDemand');


        const statusList = statusParams ? statusParams.split(",") : undefined
        const orderBy = orderByParams || undefined

        return {
            status : statusList,
            orderBy

        }
    }

    const {handleInternError, handleNetworkError} =useErrorHandler()

    const settings = {
        handleNetworkErrorFunction: handleNetworkError,
        handleInternErrorFunction: handleInternError,
        getCurrentPage,
        getCurrentFilter,
        employeeId: userInfo?.user_employee_id || "null",
        userId: userInfo?.user_id || "null"
    }
    return useSpendingDemandCore(settings)
}

export const useSingleSpendingDemand = (idSingleSpendingDemand?: string) => {
    const {spendingDemandId} =useParams()
    const IdFound = idSingleSpendingDemand || spendingDemandId|| ''
    const {userInfo,  addTokenIntheHeaderRequest} = useUserAuth()
    const {handleInternError, handleNetworkError} =useErrorHandler()
    addTokenIntheHeaderRequest()
    return useSingleSpendingDemandCore(IdFound, handleNetworkError, handleInternError, userInfo?.user_employee_id || "")
}
