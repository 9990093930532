import React from "react";
import SearchPickEmployeeView from "./SearchPickEmployeeView/SearchPickEmployeeView";
import { Employee } from "../../types/employee.type";
import useDynamicSearch from "../../../../hook/useDynamicSearch";
import EmployeeAPI from "../../services/employeeAPI";
import { MemberListViewItem } from "../../../team/components/common/MembersListView/MemberListView";
import Pagination from "../../../../components/organismes/Pagination/Pagination";
import { Pagination as pagniationType } from "../../../../types/responsesAPI.types";
import useErrorHandler from "../../../../hook/useErrorHandler";

export interface SearchPickEmployeePresenterProps {
  onClickEmployeeFound: (employee: Employee) => void;
   employeeProhibitedList?: employeeProhibitedItem[];
}
export interface employeeProhibitedItem {
  employee_id: string;
  /* Why is prohibded*/
  description: string;
}

export default function SearchPickEmployeePresenter({
  onClickEmployeeFound,
  employeeProhibitedList,
}: SearchPickEmployeePresenterProps) {
  const [result, setResult] = React.useState<Employee[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [paginationInfo, setPaginationInfo] = React.useState<pagniationType | null>(null);

  const {handleNetworkError} = useErrorHandler()
  const memberListItemConverter = (employee: Employee): MemberListViewItem => {

    if(employeeProhibitedList){
      const isProhibited = employeeProhibitedList.find((item) => item.employee_id === employee.employee_id);
      if(isProhibited){
        return {
          firstName: employee.employee_firstname,
          lastName: employee.employee_lastname,
          email: isProhibited.description
        };
      }
    }
    return {
      firstName: employee.employee_firstname,
      lastName: employee.employee_lastname,
      plusIconRight: true,
      onClick: () => {
        onClickEmployeeFound(employee);
      },
    };
  };

  const employeeListConverter = (
    employees: Employee[]
  ): MemberListViewItem[] => {

    return employees.map((employee) => memberListItemConverter(employee));
  };
  const getSearch = (query: string, callback: () => void) => {
    EmployeeAPI.searchEmployeeProtectedAPI(query, currentPage, 10)
      .then((response) => {
        setResult(response.data || []);
        setPaginationInfo(response.pagination);
        callback();
      })
      .catch((error) => {
        handleNetworkError(error, "employeeSearch")
        console.error(error);
        callback();
      });
  };


  const [query, setQuery, isLoadingResult, refrechSearch] = useDynamicSearch(getSearch, 0);
  const onSetPagination = (page: number) => {
    setCurrentPage(page);
    refrechSearch();
  }

  const onSetQuery = (query: string) => {
    setCurrentPage(1);
    setPaginationInfo(null);
    setQuery(query);
  }  
  return (
    <>
    <SearchPickEmployeeView
      searchValue={query}
      setSearchValue={onSetQuery}
      isLoading={isLoadingResult}
      employeeFound={employeeListConverter(result)}
      nbEmployeeFound={paginationInfo?.total_count}
      currentPage={currentPage} setCurrentPage={  onSetPagination} lastPage={paginationInfo?.total_pages }
    />

    </>
  );
}
