import React from 'react'
import ListBoxItem from '../../../../../../components/molecules/ListBoxItem/ListBoxItem' 
import { numberToStingWithSpaces } from '../../../../../../utils/numberDisplayer' 
import './spendingDemandListView.scss'
import EmptyListIcon from '../../../../../../components/atoms/EmptyListIcon/EmptyListIcon'
export interface ItemSpending {
    id: string,
    title?: string,
    userFirstName?: string,
    userLastName?: string,
    date?: string,
    createdDate?: string
    grossAmmount?: number
    status?: string
    pinColor?: string
    withPin?: boolean
}
export interface SpendingDemandListViewProps{
    spendingList: ItemSpending[]
    emptyMessage?: string
}

export default function SpendingDemandListView({spendingList, emptyMessage}: SpendingDemandListViewProps) {
    if(emptyMessage && spendingList.length === 0) {return <EmptyListIcon message={emptyMessage} marginTop={'80px'}/>}
    return(
    <ul className='spending-list'>{
        spendingList.map((spending, index) => (
            <li className ='spending-list-item' key={index}>
                <ListBoxItem 
                    titleSize='1.1rem'
                    headerTitleLeft={spending.createdDate}
                    titleLeft={spending.title} 
                    subTitleLeft={spending.userFirstName+' '+spending.userLastName}
                    titleRight={'- '+numberToStingWithSpaces(spending.grossAmmount || 0)}
                    titleLabelRight='FCFA'
                    subTitleRight={spending.status}
                    pinColor={spending.pinColor}
                    withPin={spending.withPin}
                    linkTo={'/spending_demand/'+spending.id}
                    />
            </li>
        ))
    }</ul>
  )
}
