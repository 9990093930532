/*===================================================================
=    Spending API                     =
====================================================================*/
/**
 * Sending api request
 * 
 * All API protected required the use call of 
 */
import axios, { AxiosResponse } from "axios";

import {
  FileToSend,
  Spending,

  SpendingDemandRequest,
  SpendingExtended,
  SpendingPatch,
  SpendingReq,
  spendingDemandPatch,
} from "../types/spending.types";
import { SpendingDemand, SpendingDemandExtended } from "../../spendingDemand/types/spendingDemand.types";


const BASE_URL = process.env.REACT_APP_URL_BACKEND
/**
 * fetch all the list of spending demands of the usr from the server
 * @returns
 */
export function fecthSpendingListAPIProtected() {
  return axios.get(`${BASE_URL}/spendings`);
}

/**
 * Add Demand Spending to the server
 * @param spending
 * @returns
 */
export function AddSpendingAPIProtected(
  spendingDemand: SpendingReq,
  setUploadProgressPoncentage?: (progress: number) => void | null
) {
  //Headers
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.post(`${BASE_URL}/spendings`, spendingDemand, headers);
}

/**
 * Add Demand Spending to the server
 * @param spending
 * @returns
 */
export function patchSpendingAPIProtected(
  spendingPatch: SpendingPatch,
  spendingId: string,
  setUploadProgressPoncentage?: (progress: number) => void | null
) {
  //Headers
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.patch(
    `${BASE_URL}/spendings/${spendingId}`,
    spendingPatch,
    headers
  );
}

/**
 * fetch list of spending Categories
 */
export function fetchSpendingCategoriesListAPIProtected() {
  return axios.get(`${BASE_URL}/spendings/categories`);
}

/**
 * upload
 */
export function uploadDocumentAPIProtected(
  fileToSendList: FileToSend[],
  setUploadProgressPoncentage: (progress: number) => void | null,
  uploadProgressPourcentage: number | null
) {
  const data = new FormData();

  //set file in Form Data
  fileToSendList.forEach((file) => {
    const document = {
      name: file.name,
      uri: file.uri,
      type: file.mimeType,
    };
    data.append("documents", document as any);
  });

  //Headers
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },

    transformRequest: (data: FormData) => {
      return data;
    },

    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };

  return axios.post(`${BASE_URL}/upload`, data, headers);
}


// DEMAND 
export function fecthSpendingDemandsListUserAPIProtected(){
  return axios.get<any,AxiosResponse<SpendingDemand[]>>(`${BASE_URL}/spendings/user/demands`);
}

// DEMAND 
export function fecthSpendingDemandsListAdminAPIProtected(){
  return axios.get<any,AxiosResponse<SpendingDemandExtended[]>>(`${BASE_URL}/spendings/demands?extendedfields=user,project`);
}

/**
 * Add Demand Spending to the server
 * @param spending
 * @returns
 */
export function AddSpendingDemandAPIProtected(
  spendingDemand: SpendingDemandRequest,
  setUploadProgressPoncentage?: (progress: number) => void | null
) {
  //Headers
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.post(`${BASE_URL}/spendings/demands/`, spendingDemand, headers);
}

/**
 * Add Demand Spending to the server
 * @param spending
 * @returns
 */
export function patchSpendingDemandAPIProtected(
  spendingPatch: spendingDemandPatch,
  spendingId: string,
  setUploadProgressPoncentage?: (progress: number) => void | null
) {
  //Headers
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.patch(
    `${BASE_URL}/spendings/demands/${spendingId}`,
    spendingPatch,
    headers
  );
}

/**
 * get Single Spending
 * @param spendingId Spending Id
 * @param setUploadProgressPoncentage catch the sending progress 
 */
export function getSingleSpendingProtectedAPI(spendingId: string,   setUploadProgressPoncentage?: (progress: number) => void | null) {
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.get<any,AxiosResponse<SpendingExtended>>(`${BASE_URL}/spendings/${spendingId}`, headers)
  
}

/**
 * get Single Spending demand 
 * @param spendingDemandId Spending demand Id
 * @param setUploadProgressPoncentage catch the sending progress 
 */
export function getSingleSpendingDemandProtectedAPI(spendingDemandId: string,   setUploadProgressPoncentage?: (progress: number) => void | null) {
  const headers = {
    onUploadProgress: setUploadProgressPoncentage
      ? ({ loaded, total }: any) => {
          setUploadProgressPoncentage((loaded / total) * 100);
        }
      : (data: any) => {},
  };
  return axios.get<any,AxiosResponse<SpendingDemandExtended>>(`${BASE_URL}/spendings/demands/${spendingDemandId}?extendedfields=user,project`, headers)
  
}