import { AxiosError } from "axios";

export const handlerErrorFunctions = ( handleNetworkErrorFunction:
    | ((error: AxiosError<unknown, any>, SreenContext: string | null) => void)
    | null,
  handleInternErrorFunction:
    | ((errorMessage: string | null, errorCode: number | null) => void)
    | null) => {
    const handleInternError = (
        errorMessage: string | null,
        errorCode: number | null
      ) => {
    
        if (handleInternErrorFunction) {
          handleInternErrorFunction(errorMessage, errorCode);
        }
      };
    
      const handleNetworkError = (
        error: AxiosError<unknown, any>,
        SreenContext: string | null
      ) => {
        if (handleNetworkErrorFunction) {
          handleNetworkErrorFunction(error, SreenContext);
        }
      };

      return {handleNetworkError, handleInternError};
}