import React from 'react'
import styles from './HistoricStatusView.module.scss'
import Timeline, { TimelineItem } from '../../../../../../components/molecules/Timeline/Timeline'


export interface HistoricStatusViewProps {
    items?: TimelineItem[]
    
}
function HistoricStatusView({items} : HistoricStatusViewProps) {
  return <Timeline items={items}/>
}

export default HistoricStatusView
