import React from "react";
import styles from "./ModalAddProjectView.module.scss";
import ArrowBack from "./assets/arrow-back.svg";
import SearchPickEmployee from "../../../employee/components/SearchPickEmployee";
import SearchPickProject from "../SearchPickProject";
import { Project } from "../../types/project.type";
import { on } from "events";
import Button from "../../../../components/molecules/Button/Button";
export interface ModalAddProjectViewProps {
  onClose?: () => void;
  labelCloseBtn?: string;
  title?: string;
  setProject?: (project: Project) => void;
  setProjectList?: (project: Project[]) => void;
  multiple?: boolean;
  titleListSelected?: {single: string, plural: string}
  projectPickedList?: Project[] | null;
  emptySelectedMessage? : string;
  onSave?: () => void;
}

export interface projectProhibitedItem {
  project_id: string;
  /* Why is prohibded*/
  description: string;
}

export const ModalAddProjectView = ({
  onClose,
  labelCloseBtn,
  title,
  setProject,
  setProjectList,
  multiple,

  projectPickedList,
  emptySelectedMessage="Aucun projet",
  titleListSelected={single:"projet", plural:"Projets"},
  onSave
}: ModalAddProjectViewProps) => {
  return (
    <div className={styles["modal-search"]}>
      {onClose && (
        <button
          className={styles["close-btn"]}
          onClick={(e) => {
            onClose();
          }}
        >
          <img
            className={styles["close-btn__icon"]}
            src={ArrowBack}
            alt="Retourner"
          />
          <span className={styles["close-btn__label"]}>{labelCloseBtn}</span>
        </button>
      )}
      <h1 className={styles["modal-search__title"]}>{title}</h1>
      <div className={styles["content-main"]}>
        {multiple && <>
        <div
          className={[
            styles["content-side"],
            styles["content-side--member"],
          ].join(" ")}
        >
          <h2 className={styles["content-side__title"]}>
            {  projectPickedList &&  projectPickedList?.length > 1 ? titleListSelected.plural: titleListSelected.single } ({ projectPickedList?.length || 0})
          </h2>
          <div className={styles["menber-list"]}>
            <p> list </p>
          </div>
        </div>


        <div className={styles["separator-container"]}>
          <div className={styles["separator-container__separator"]} />
        </div>
        </>
}
        <div
          className={[
            styles["content-side"],
            styles["content-side--search-employee"],
          ].join(" ")}
        >
          <p className={[styles["content-side__sub-title"]].join(" ")}>
            Rechercher un projet {multiple && 'à ajouter à la liste'}
          </p>
          <SearchPickProject onClickCallback={onClose} setProject={(project) => {setProject && setProject(project)} }/>
        </div>
      </div>

      <div className={styles["btn-save"]}>
        {onSave &&
          <Button label="Sauvegarder" style="primary" maxWidth="150px" onClick={() => {onSave()}} iconArrow/>
        }
      </div>
    </div>
  );
}
export function ModalAddProjectViewV0({
  onClose,
  labelCloseBtn = "Retourner",
  title,
  setProject
}: ModalAddProjectViewProps) {
  return (
    <div className={styles["modal"]}>
      {onClose && (
        <button
          className={styles["close-btn"]}
          onClick={(e) => {
            onClose();
          }}
        >
          <img
            className={styles["close-btn__icon"]}
            src={ArrowBack}
            alt="Retourner"
          />
          <span className={styles["close-btn__label"]}>{labelCloseBtn}</span>
        </button>
      )}
      <h1 className={styles["modal__title"]}>{title}</h1>
      <div className={styles["content-main"]}>
        <SearchPickProject onClickCallback={onClose} setProject={(project) => {setProject && setProject(project)} }/>
      </div>
    </div>
  );
}

export default  ModalAddProjectView 