import axios from "axios";
import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types";
import { Project } from "../types/project.type";

const BASE_URL = process.env.REACT_APP_URL_BACKEND

const searchProjectProtectedAPI = async (query: string, page: number = 0, pageSize = 0) => {
    return (await axios.get<ResponseAPIWithPagination<Project>>(
        `${BASE_URL}/api/v2/projects/search?query=${query}&pageSize=${pageSize}&currentPage=${page}`)).data;
}

const getSingleProjectProtectedAPI = async (projectId: string) => {
    return (await axios.get<Project>(
        `${BASE_URL}/api/v2/projects/${projectId}`)).data;
}

const ProjectAPI = {
    searchProjectProtectedAPI,
    getSingleProjectProtectedAPI

}

export default ProjectAPI;