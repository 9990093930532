/*===================================================================
=     SPENDING DEMAND PAGE            =
====================================================================*/
/**
 * Spending 
 */
import React, { useEffect } from 'react'
import './spendingDemandListPage.scss';
import SpendingDemandList from '../../../features/spendingDemand/components/SpendingDemandList';



const  SpendingDemandPage = () => {

  return (
    
    <div className='page'>
    <div className='page--title'>Demande de dépenses</div>
      <SpendingDemandList.Filter/>
      <SpendingDemandList.List/>
    </div>
  )
}

export default SpendingDemandPage
