import React from 'react'
import LoginForm from '../../features/auth/components/LoginForm'



export const DashboardPage = () => {
  return (
    <div>
     <LoginForm/>
    </div>
  )
}



export default DashboardPage

