/*===================================================================
=      Spending Demand Reducer                       =
====================================================================*/
/**
 * Spending Demand Reducer
 */

import { FilterSpendingDemandsList } from "../../spending/redux/spending.reducer";
import { FilterSpendingDemand } from "../types/spendingDemand.types";

import types from "./spendingDemand.types"

export interface ISpendingDemandState{
    filter:  FilterSpendingDemand| null;
}

//=============== INITIAL STATE ==================
const initialState: ISpendingDemandState = {
    filter: null
}

//=============== RDUCER ==================
const spendingDemandReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.SET_FILTER :
            return {
                ...state,
                filter: action.payload
            }
        default:
            return state
    }
}

export default spendingDemandReducer