/*===================================================================
=       FILE READER                   =
====================================================================*/
/**
 * File Reader
 */

/**
 * 
 * @param url 
 * @returns 
 */
export const getDataURLFromUrlFile = (url: string)=>{
 return new Promise((resolve, reject) => {

    fetch(url).then((res) => {

       res.blob().then((blob) =>{
        let reader = new FileReader();
        reader.addEventListener(
         "load",
         () => {
             resolve(reader.result)
         },
         false,
       );
        reader.readAsDataURL(blob)

       }
       
       ).catch(
        (err) => {
            reject(err)
        }
       )



    }).catch(
        (err) => {
            reject(err+' \n File not found \n');
        }
       )

 }) 
}