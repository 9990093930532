import { useState } from 'react';
import projectAPI from '../../services/projectAPI';
import { Project } from '../../types/project.type';
import SearchPickProjectView from './SearchPickProjectView/SearchPickProjectView'
import useDynamicSearch from '../../../../hook/useDynamicSearch';
import { Pagination as pagniationType } from "../../../../types/responsesAPI.types";
import Pagination from '../../../../components/organismes/Pagination/Pagination';
import useErrorHandler from '../../../../hook/useErrorHandler';

interface SearchPickProjectPresenterProps {
  setProject : (project : Project) => void;
  project?: Project  | null;
  onClickCallback? : () => void;

}
export default function SearchPickProjectPresenter({setProject, onClickCallback}: SearchPickProjectPresenterProps) {
  const [result, setResult] = useState<Project[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState<pagniationType | null>(null);
  const {handleNetworkError} = useErrorHandler()
  
  const getSearch = (query: string, callback: () => void) => {
    projectAPI.searchProjectProtectedAPI(query, currentPage, 5)
      .then((response) => {
        setResult(response.data || []);
        setPaginationInfo(response.pagination);
        callback();
      })
      .catch((error) => {
        handleNetworkError(error, "employeeSearch")
        callback();
      });
  };
  const [query, setQuery, isLoadingResult,refrechSearch] = useDynamicSearch(getSearch, 0);

  const onSetPagination = (page: number) => {
    setCurrentPage(page);
    refrechSearch();
  }

  const onSetQuery = (query: string) => {
    setCurrentPage(1);
    setPaginationInfo(null);
    setQuery(query);
  }  
  
  return (
  

      <SearchPickProjectView setSearchValue={onSetQuery} searchValue={query} isLoading={isLoadingResult} results={result.map(item =>{return{ title : item.project_name,
        description : item.project_desc,
        onClick : () => {
          setProject(item);
          if (onClickCallback) {
            onClickCallback();
          }

        }
        
      }})}
      
      currentPage={currentPage} setCurrentPage={onSetPagination} lastPage={paginationInfo?.total_pages }
      nbFound={paginationInfo?.total_count}
      />
      

    
  )
}
