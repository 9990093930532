import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'


export interface OptionList {
    value : string;
    label : string;
    disable?: boolean;
    message?: string;
}

export interface SelectInputProps {
    handleChange: (value: any) => void;
    value?: any;
    options?: OptionList[],
    label: string;
}

export default function SelectInput({ handleChange, value, options, label}: SelectInputProps) {
    const handleChangeSelect = (event: SelectChangeEvent) => {
        handleChange(event.target.value as any);
      };
  return (
    <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={label}
            onChange={handleChangeSelect}
        >
            {options?.map(option => (
                <MenuItem value={option.value} disabled ={option.disable}>
                   
                    <>{option.label}  {option.message && <>- {option.message}</>}</>
            
                </MenuItem>
            ))}
        </Select>
    </FormControl>
  )
}
