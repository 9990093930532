/*===================================================================
=       USER AUTH ACTIONS                         =
====================================================================*/
import { IUser, IUserEmployee } from "../types/user.types";
import { userAuthTypes } from "./userAuth.types";

export const setIsLoadingUserAuthAction = (isLoading: boolean) => ({
    type: userAuthTypes.SET_IS_LOADING_USER_AUTH,
    payload: isLoading
})

export const setAccessTokenAction = (token: string) => ({
    type: userAuthTypes.SET_ACCESS_TOKEN,
    payload: token
})

export const setIsAuthAction = (siAuth: boolean) => ({
    type: userAuthTypes.SET_IS_AUTH,
    payload: siAuth
})

export const setUserDataAction = (userData: IUser | null) => ({
    type: userAuthTypes.SET_USER_DATA,
    payload: userData
})

export const logoutUserAction = () => ({
    type: userAuthTypes.LOGOUT_USER,
    payload: null
})

export const setEmployeeData = (employeeData: IUserEmployee | null) => ({
    type: userAuthTypes.SET_EMPLOYEE_DATA,
    payload: employeeData
})

export const setIsEmployee = (isEmployee: boolean) => ({
    type: userAuthTypes.SET_IS_EMPLOYEE,
    payload: isEmployee
})

