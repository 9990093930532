import React, { useState } from 'react'
import { Project } from '../../types/project.type';
import ModalAddProjectView from '../ModalAddProjetView/ModalAddProjectView';
import SelectButton from '../../../../components/atoms/SelectButton/SelectButton';
import ModalAddProject from '../ModalAddProjetView';

export interface SelectEmployeeListBtnProps {
    titleModal?: string;
    setProjectList?: (manager: Project[]) => void;
    currentProjectList?: Project[] | null | undefined;
    titleListSelected?: { single: string; plural: string };
    label?: string;
    isLoading?: boolean;
    placeholder?: string;
    withDeleteAllBtn?: boolean;
  }
export default function SelectProjectBtn({
    setProjectList,
    titleModal = "Ajouter des projets",
    currentProjectList,
    label,
    isLoading,
    withDeleteAllBtn,
  
    titleListSelected = {
      single: "Projet Sélectionné",
      plural: "Projets Sélectionnés",
    },
    placeholder= "Tous",
  }: SelectEmployeeListBtnProps) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const handleModalOpen = () => {
      setIsModalOpen(true);
    };
  
    const handleModalClose = () => {
      setIsModalOpen(false);
    };
  return (
    <>
          {isModalOpen && (
            <ModalAddProject
                onClose={handleModalClose}
                titleModal={titleModal}
   
                setProject={(project) => {
                    setProjectList &&setProjectList([project])
                }}
            />
      )}
      <SelectButton
            label={label}
            getNameItem={(item: Project) => item.project_name}
            valueList={currentProjectList}
            onClick={handleModalOpen}
            isLoading={isLoading}
            placeholder={placeholder} 
            deleteAllItems={ withDeleteAllBtn ? () => { setProjectList &&setProjectList([])} : undefined}
/>

    </>
  )
}
