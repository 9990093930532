import React from "react";
import ReturnBackBtn from "../../../../../components/atoms/ReturnBackBtn/ReturnBackBtn";
import { useSingleTeam, useTeam } from "../../../hooks";

export default function GoBackToSingleTeamBtnPresenter() {
  const { isLoading, singleTeamData } = useSingleTeam();
  if (isLoading || !singleTeamData) return <></>;
  return (
    <ReturnBackBtn
      label="Retourner"
      linnkTo={"/teams/" + singleTeamData.team_id}
    />
  );
}
