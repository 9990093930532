/*===================================================================
=       Info Modale Container          =
====================================================================*/
/**
 * 
 */

import React, { FC } from 'react'
import InfoModalView, { InfoModalViewProps } from './InfoModalView/InfoModalView'


import { useInfoModalV2 } from '../../context/infoModalV2Context'






/**
 * 
 * @param InfoModalView 
 * @returns 
 */
const withInfoModalData = (InfoModalView: FC<InfoModalViewProps>) => {

  return function Container () {

    const {closeModal, configData, isShow } = useInfoModalV2()

    return (
      <>
        {isShow &&
          <InfoModalView 
            handleClose={closeModal}
            title={configData?.title}
            contentText={configData?.message}
            buttonLabel={configData?.labelBtn}
            iconType={configData?.iconType}
            noCloseButton={configData?.noBtnClose || false}
            progressBarvalue={configData?.progressBarvalue}
            loaderMode={configData?.loaderMode || false}
          />
        }
      </>
    )
  }
}



export default  withInfoModalData(InfoModalView)