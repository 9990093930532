import React, { FC } from 'react'
import styles from './Timeline.module.scss'

import start from './assets/icon-start.svg'
import validIcon from './assets/valid.svg'
import crossIcon from './assets/cross.svg'


export type IconType = 'start' | 'valid' | 'cross'

export interface TimelineItem {
    iconType?: IconType,
    title?: string,
    description?: string
    IconRender?: FC 
    color?: string
    isDone?: boolean
}
export interface TimelineProps {

    items?: TimelineItem[]
}

const getIconSrc = (icon: IconType | undefined) => {
    switch (icon) {
        case 'start':
            return start
        case 'valid':
            return validIcon
        case 'cross':
            return crossIcon
        default:
            return ''
    }

}

function Timeline({items}:  TimelineProps ) {

    if(!items) {
        return <></>
    }

    const nbDone = items.filter(item => item.isDone).length
    const allToDo = items.length
    const fullHeight = (nbDone-1) * 100 / allToDo
    const dynamicStyle = {
        height: allToDo === nbDone ? '100%': `calc(${fullHeight}% + 15px)`
    }

  return (
    <div className={styles['timeline']}>
      <div className={styles['line']}>
    <div className={styles['line__full']} style={ dynamicStyle }/>
    </div>
    <div className={styles['content']}>
        {items.map((item, index) => (
            <TimelineItem 
            key={index}
            title={item.title}
            description={item.description}
            IconRender={ item.iconType !== undefined ? (() => <img src={getIconSrc(item.iconType)} />) : undefined}
            color={item.color}
            isActive={item.color === undefined ? item.isDone : undefined}
            />
        ))}
    </div>      
    </div>
  )
}

interface TimelineItemProps {
    IconRender?: FC,
    title?: string,
    description?: string 
    color?: string
    isActive?: boolean
}

function TimelineItem( {title, description, IconRender, color, isActive}: TimelineItemProps) {



    const dynamicStyle = {
        backgroundColor: isActive ?"#A6C185" : color || '#dedede' 
    }
    return (
        <div  className={styles['timeline-item']}>
            <div style={dynamicStyle} className={[styles['timeline-item__dote'] ].join(" ")}>
                {   IconRender &&
                    <div className={styles['icon']}>
                    <IconRender />
                    </div>
                }
            </div>
        <div className={styles['timeline-item-content']}>

            { title &&
            <h3 className={styles["timeline-item-content__title"]}>{title}</h3>
            }
            { description &&
            <p className={styles["timeline-item-content__description"]}>{description}</p>
                }
        </div>
        </div>
    )
}

export default Timeline
