import React from 'react'
import { Project } from '../../types/project.type';
import ModalAddProjectView, { projectProhibitedItem } from './ModalAddProjectView';
import { on } from 'events';

interface ModalAddProjectPresenterProps {
    onClose?: () => void;
    currentProjectList?: Project[] | null;
 
    setProjectList?: (project: Project[]) => void;
    setProject?: (project: Project) => void;
    multiple?: boolean;
    titleModal?: string;
    ProjectProhibitedList?: projectProhibitedItem[];
    titleListSelected?: {single: string, plural: string}
    

}
export default function ModalAddProjectPresenter({
    onClose,
    currentProjectList,
    setProjectList,
    setProject,
    multiple,
    titleModal,
    ProjectProhibitedList,
    titleListSelected,


}: ModalAddProjectPresenterProps) {
  return (
    <ModalAddProjectView
    onClose={onClose}
    title={titleModal}
    multiple={multiple}
    setProject={setProject}
    setProjectList={setProjectList}
    projectPickedList={currentProjectList}
    titleListSelected={titleListSelected}
    


/>
  )
}
