/*================================================================
 *                TeamHeaderTitle
 *================================================================*/

import React from "react";
import Badge from "../../../../../components/molecules/Bagde/Badge";
import peopleIcon from "../../../assets/people.svg";
import projectIcon from "../../../assets/compas.svg";
import moneyIcon from "../../../assets/money-white.svg";

import "./teamHeaderTitleView.scss";
export interface TitleHeaderViewProps {
  title: string;
  subtitle?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  projectName?: string;
  membersCount?: number;
  role?: string | null;
}
export default function TeamHeaderTitleView({
  title,
  description,
  createdAt,
  updatedAt,
  membersCount,
  projectName,
  role,
}: TitleHeaderViewProps) {
  return (
    <div className="team-header-info">
      <h1 className="team-header-info__title">{title}</h1>
      <div className="badge-container">
        {membersCount !== undefined && (
          <Badge
            iconSource={peopleIcon}
            text={membersCount + " employé" + (membersCount > 1 ? "s" : "")}
            color="black"
          />
        )}
        {projectName !== undefined && (
          <Badge iconSource={projectIcon} text={projectName} color="primary" />
        )}

        {role && role === 'Comptable' && (
          <Badge
            iconSource={moneyIcon}
            text={role}
            color="blue"
          />
        )}
      </div>
      {description && description !== "" && (
        <div className="description">
          <h2 className="description__label">Description</h2>
          <p className="description__text">{description}</p>
        </div>
      )}
      {createdAt && (
        <p className="team-header-info__creationdate">
          Crée le {createdAt}{" "}
          {createdAt !== updatedAt && <>et mise à jour le {updatedAt}</>}
        </p>
      )}
      

      {role && 
        <p className="team-header-info__role"> Rôle: {role}</p>
        }
    </div>
  );
}
