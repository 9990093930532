/*===================================================================
=                        LOAN DEMAND SORTER FILTER                        =
====================================================================*/

import { SpendingDemand } from "../../spendingDemand/types/spendingDemand.types";
import { FilterSpendingDemandsList, SorterSpendingDemandsList } from "../redux/spending.reducer";
import { Spending } from "../types/spending.types";


/**
 * 
 * @param filter 
 * @param list 
 * @returns 
 */
export const spendingListFilter = (filter:  FilterSpendingDemandsList | null | undefined, list: Spending[]) => {
    if( filter && filter.status){
        const status = filter.status
        if(status.length <= 0){
            return list;
        }
        return list.filter(loanDemand => status.includes(loanDemand.spending_status))
    }

    return list;
}


export const  spendingListSorter = (sorter: SorterSpendingDemandsList| null | undefined, list: Spending[]) => {


    if(sorter?.orderby === 'spending_date_asc'){
        return list.sort((a, b) =>{
            const aDate = new Date(a.spending_date).getTime()
            const bDate = new Date(a.spending_date).getTime()

            return aDate - bDate;
        } )
    }

    if(sorter?.orderby === 'spending_date_desc'){
        return list.sort((a, b) =>{
            const aDate = new Date(a.spending_date).getTime()
            const bDate = new Date(a.spending_date).getTime()

            return bDate - aDate;
        } )
    }

    return list;

}

export const spendingDemandListFilter = (filter:  FilterSpendingDemandsList | null | undefined, list: SpendingDemand[]) => {
    if( filter && filter.status){
        const status = filter.status
        if(status.length <= 0){
            return list;
        }
        return list.filter(loanDemand => status.includes(loanDemand.spending_demand_status))
    }

    return list;
}


export const  spendingDemandListSorter = (sorter: SorterSpendingDemandsList| null | undefined, list: SpendingDemand[]) => {


    if(sorter?.orderby === 'spending_date_asc'){
        return list.sort((a, b) =>{
            const aDate = new Date(a.spending_demand_date).getTime()
            const bDate = new Date(a.spending_demand_date).getTime()

            return aDate - bDate;
        } )
    }

    if(sorter?.orderby === 'spending_date_desc'){
        return list.sort((a, b) =>{
            const aDate = new Date(a.spending_demand_date).getTime()
            const bDate = new Date(a.spending_demand_date).getTime()

            return bDate - aDate;
        } )
    }

    return list;

}