import React from 'react'
import UpadateTeamForm from '../../../features/team/components/UpdateSingleTeam/UpdateTeamForm'
import ReturnBackBtn from '../../../components/atoms/ReturnBackBtn/ReturnBackBtn'
import UpdateSingleTeam from '../../../features/team/components/UpdateSingleTeam'
import GenericGoBackBtn from '../../../components/organismes/GenericGoBackBtn/GenericGoBackBtn'

export default function UpdateTeamPage() {

  return (
    <div>
    <GenericGoBackBtn/>
    <UpdateSingleTeam.Form/>
    </div>
  )
}
