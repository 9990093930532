/*===================================================================
=              Validation input                             =
====================================================================*/

//TODO put reggex validation for email input
/**
 * Check the validity of the password and email
 * @param email 
 * @param password 
 * @returns 
 */
export const inputAuthValidation = (email: string, password: string) => {
    if(email === "" ||
     email === null ||
     password === "" ||
     password === null) {
        return false;
    }
    return true;
}

export const inputAuthValisationEmployee = (email: string, password: string, matricule: string) => {
    if(!inputAuthValidation) return false;
    if (matricule === "" || matricule === null) return false;
    return true;
}