import { TextField } from '@mui/material'
import React from 'react'

export interface TextInputProps {
    label?: string;
    value?: unknown;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
    multiline?: boolean | undefined
    rows?: number | undefined
    placeholder?: string | undefined
    error?: boolean
    style?: React.CSSProperties | undefined

}
export default function TextInput({
    label,
    value,
    onChange,
    multiline,
    rows,
    placeholder,
    error,
    style
}: TextInputProps) {
  return (
    <>
        <TextField
          style={style}
          error={error}
          id="outlined-multiline-static"
          label={label}
          multiline ={multiline}
          rows={rows}
          fullWidth
          value={value}
          onChange={onChange}
          placeholder={placeholder}
         
        />
    </>
  )
}
