
import styles from "./projectInfoCardView.module.scss";
import { Link } from "react-router-dom";
import iconDelete from './assets/close-icon.svg'

export interface ProjectInfoCardViewProps {
  description?: string;
  title?: string;
  location?: string;
  onClick?: () => void;
  linkTo?: string;
  noDescriptionLabel?: boolean;
  maxWidth?: string;
  maxCharDescription?: number;
  onDelete?: () => void;
}
export default function ProjectInfoCardView({
  title,
  description,
  noDescriptionLabel,
  maxWidth,
  maxCharDescription,
  onClick,
  linkTo,
  onDelete,
}: ProjectInfoCardViewProps) {
  const style = {
    maxWidth: maxWidth || "350px",
  };
  if (
    maxCharDescription &&
    description &&
    description.length > maxCharDescription
  ) {
    description = description.slice(0, maxCharDescription) + "...";
  }

  const content = (
    <>
      {onDelete && (
        <button
          className={styles["project-info__delete-btn"]}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <img src={iconDelete} />
        </button>
      )}
      <h3 className={styles["project-info__title"]}>{title}</h3>
      {description && (
        <>
          {!noDescriptionLabel && (
            <h4 className={styles["project-info__description-label"]}>
              Description
            </h4>
          )}
          <p className={styles["project-info__description-text"]}>
            {description}
          </p>
        </>
      )}
    </>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} style={style}  className={[styles["project-info"], styles["project-info--clickable"]].join(" ")}>
        {content}
      </Link>
    );
  }

  return (
    <div
      style={style}
      className={[styles["project-info"], onClick && styles["project-info--clickable"]].join(" ")}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick();
      }}

    >
      {content}
    </div>
  );
}
