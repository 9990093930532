/*===================================================================
=       GENERAL INFO VIEW                     =
====================================================================*/
import { fireEvent } from "@testing-library/react";
import FilesList, {
  FileItem,
} from "../../../../../../components/molecules/FilesList/FilesList";
import TextBox from "../../../../../../components/molecules/TextBox/TextBox";
import styles from "./GeneralInfoView.module.scss";
import Timeline, { TimelineItem } from "../../../../../../components/molecules/Timeline/Timeline";
import HistoricStatusPresenter from "../../HistoricStatus/HistoricStatusPresenter";

export interface User {
  firstname?: string;
  lastname?: string;
}

export interface GeneralInfoViewProps {
  userName?: string;
  userLastname?: string;
  statut?: string;
  colorStatus?: string;
  paymentDate?: string;
  amount?: string;
  description?: string;
  updateAtDate?: string;
  projectName?: string;
  remak?: string;
  filesList?: FileItem[] | null;
  managerWaitingValidation?: User[];

}
export default function GeneralInfoView({
  userLastname,
  userName,
  statut,
  colorStatus,
  paymentDate,
  description,
  amount,
  projectName,
  updateAtDate,
  remak,
  filesList,
  managerWaitingValidation
}: GeneralInfoViewProps) {
  const managerListWaitingValidationString = managerWaitingValidation
    ?.map((manager) => manager?.firstname + " " + manager?.lastname)
    .join(", ") || "";

  const statusContent = [
    {
      title: "Statut",
      text: statut,
    }
  ];

  if (managerWaitingValidation && managerWaitingValidation.length > 0 && statut === "En attente de traitement") {
    statusContent.push({
      title: "En attente de la validation de",
      text: managerListWaitingValidationString,
    });
  }



  return (
    <div className={styles["general-info"]}>
      <div className={[styles["row"], styles["row--date-taxes"]].join(" ")}>
        <div
          className={[
            styles["container-item"],
            styles["container-item--left"],
          ].join(" ")}
        >
          <TextBox
            color="primary"
            content={[
              {
                title: "Montant Previsionel TTC",
                text: amount,
              },
            ]}
          />
        </div>
        <div
          className={[
            styles["container-item"],
            styles["container-item--right"],
          ].join(" ")}
        >
          <TextBox
            content={statusContent}
          />
        </div>
      </div>
      <div className={[styles["row"], styles["row--date"]].join(" ")}>
        <div
          className={[
            styles["container-item--date"],
            styles["container-item"],
            styles["container-item--left"],
          ].join(" ")}
        >
          <TextBox
            content={[
              {
                title: "Date de paiement",
                text: paymentDate,
              },
              {
                title: "Date de modification",
                text: updateAtDate,
              },
            ]}
          />
        </div>
        <div
          className={[
            styles["container-item--historic-status"],
            styles["container-item"],
            styles["container-item--right"],
          ].join(" ")}
        >
          <TextBox
            content={[
              {
                title: "Historique de traitement",
                ItemRender: () => (<HistoricStatusPresenter/>)
              },
            ]}
          />
        </div> 

      </div>

      <div className={[styles["row"], styles["row--description"]].join(" ")}>
        <div
          className={[
            styles["container-item"],
            styles["container-item--letf"],
          ].join(" ")}
        >
          <TextBox
            
            content={[
              {
              
                title: "Description",
                text: description,
              },
            ]}
          />
        </div>
      </div>
      <div className={[styles["row"], styles["row--employee"]].join(" ")}>
        <div
          className={[
            styles["container-item"],
            styles["container-item--letf"],
          ].join(" ")}
        >
           <TextBox
            content={[
              {
                title: "Employé(e)",
                text: userName + " " + userLastname,
              },
            ]}
          />
        </div>
      </div>

      {projectName && projectName !== "" && (
      <div className={[styles["row"], styles["row--project"]].join(" ")}>
        <div
          className={[
            styles["container-item"],
            styles["container-item--letf"],
          ].join(" ")}
        >
          <TextBox
            content={[
              {
                title: "Projet",
                text: projectName,
              },
            ]}
          />
        </div>
      </div>
      )}

      {
        remak && remak !== "" &&
      
      <div className={[styles["row"], styles["row--remarks"]].join(" ")}>
        <div
          className={[
            styles["container-item"],
            styles["container-item--letf"],
          ].join(" ")}
        >
          <TextBox
            content={[
              {
                title: "Remarque",
                text: remak,
              },
            ]}
          />
        </div>
      </div>
    }

      {filesList && filesList.length > 0 && (
        <div className={[styles["row"], styles["row--files"]].join(" ")}>
          <div
            className={[
              styles["container-item"],
              styles["container-item--letf"],
            ].join(" ")}
          >
            <FilesList filesList={filesList} label="Justificatifs" />
          </div>
        </div>
      )}
    </div>
  );
}
