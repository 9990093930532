import React from "react";
import GeneralInfoTeamFromView from "../GeneralInfoTeamFormView/GeneralInfoTeamFormView";

import AddSearchNewMemberBtn from "../AddSearchNewMemberBtn";
import { Employee } from "../../../../../employee/types/employee.type";
import styles from "./TeamCreateUpdateFormView.module.scss";

import AddProjectBtn from "../AddProjectBtn/AddProjectBtn";
import { Project } from "../../../../../project/types/project.type";
import ProjectInfoCardView from "../../../../../project/components/ProjectInfoCardView/ProjectInfoCardView";
import AddManagerBtn from "../AddManagerBtn/AddManagerBtn";
import BigBadgeImage from "../../../../../../components/molecules/BigBadgeImage/BigBadgeImage";
import MemberListView from "../../../common/MembersListView/MemberListView";
import { employeeProhibitedItem } from "../../../../../employee/components/SearchPickEmployee/SearchSelectEmployeePresenter";
import Button from "../../../../../../components/molecules/Button/Button";
import { isError } from "util";

import binIcon from "./assets/bin.svg";
import ModalCard from "../../../../../../components/organismes/ModalCard/ModalCard";
import { TeamOption, TeamRoles } from "../../../../types/team.types";
export interface TeamCreateUpdateFormViewProps {
  teamName: string;
  setTeamName: (teamName: string) => void;
  teamDescription: string;
  setTeamDescription: (teamDescription: string) => void;
  project: Project | null;
  setProject: (project: Project | null) => void;
  membersList: Employee[];
  setMembersList: (membersList: Employee[]) => void;
  manager: Employee | null;
  setManager: (manager: Employee) => void;
  onSubmitForm?: () => void;
  teamRole?: TeamRoles;
  setTeamRole?: (teamRole: TeamRoles) => void;
  teamRoleOptions?: TeamOption[];
  employeeProhibitedListForManager?: employeeProhibitedItem[];
  employeeProhibitedListForMembers?: employeeProhibitedItem[];
  submitBtnDisabled?: boolean;
  isLoadingSending?: boolean;
  isErrorName?: boolean;
  isErrorDescription?: boolean;
  isErrorProject?: boolean;
  isErrorMembers?: boolean;
  isErrorManager?: boolean;
  submitBtnLabel?: string;
  isLoadingData?: boolean;
  onDeleteTeam?: () => void;
  isLoadingTeamOption?: boolean;
}
export default function TeamCreateUpdateFormView({
  teamName,
  setTeamName,
  teamDescription,
  setTeamDescription,
  project,
  setProject,
  membersList,
  setMembersList,
  manager,
  setManager,
  employeeProhibitedListForManager,
  employeeProhibitedListForMembers,
  isErrorDescription,
  isErrorName,
  onSubmitForm,
  isErrorManager,
  isErrorProject,
  isErrorMembers,
  submitBtnDisabled,
  isLoadingSending,
  submitBtnLabel = "Envoyer",
  isLoadingData,
  onDeleteTeam,
  setTeamRole,
  teamRole,
  teamRoleOptions,
  isLoadingTeamOption,
}: TeamCreateUpdateFormViewProps) {
  const [modalIsOpen, setModalOpen] = React.useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openDeleteModal = () => {
    setModalOpen(true);
  };
  const handleComfirmDelete = () => {
    onDeleteTeam && onDeleteTeam();
    closeModal();
  };

  if (isLoadingSending || isLoadingData || isLoadingTeamOption) {
    <p>Loading...</p>;
  }
  return (
    <>
      {modalIsOpen && (
        <ModalCard
          title="Attention"
          description="Voulez-vous vraiment supprimer cette équipe ?"
          onClose={closeModal}
          handleValidate={handleComfirmDelete}
          withCancelBtn
          labelValidate="Supprimer"
        />
      )}
      <div className={styles["form-struct"]}>
        <GeneralInfoTeamFromView
          setNameValue={setTeamName}
          nameValue={teamName}
          setDescriptionValue={setTeamDescription}
          descriptionValue={teamDescription}
          isErrorDescription={isErrorDescription}
          isErrorName={isErrorName}
          teamRoleOptions={teamRoleOptions}
          teamRoleValue={teamRole}
          setTeamRoleValue={setTeamRole}
        />
        <div className={styles["footer-bloc"]}>
          <div
            className={[
              styles["footer-content-side"],
              styles["footer-content-side--left"],
            ].join(" ")}
          >
            <div
              className={[
                styles["content-side-item"],
                styles["content-side-item--member"],
                isErrorMembers && styles["content-side-item--error"],
              ].join(" ")}
            >
              <h2
                className={[
                  styles["content-side-item__title"],
                  styles["content-side-item__title--member"],
                  isErrorMembers && styles["content-side-item__title--error"],
                ].join(" ")}
              >
                Membre{membersList.length > 1 && "s"} ({membersList.length})
              </h2>
              {membersList.length > 0 && (
                <div
                  className={[
                    styles["content-side-item__content"],
                    styles["content-side-item__content--member"],
                  ].join(" ")}
                >
                  <MemberListView
                    members={membersList.map((employee) => {
                      return {
                        firstName: employee.employee_firstname,
                        lastName: employee.employee_lastname,
                      };
                    })}
                    maxHeight={"500px"}
                    ordered
                  />
                </div>
              )}

              <div className={[styles["content-side-item__btn"]].join(" ")}>
                <AddSearchNewMemberBtn
                  employeeProhibitedList={employeeProhibitedListForMembers}
                  currentMembersList={membersList}
                  setNewMemberList={setMembersList}
                  label={
                    membersList.length === 0
                      ? "Ajouter des membres"
                      : "Modifier la liste"
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={[
              styles["footer-content-side"],
              styles["footer-content-side--right"],
            ].join(" ")}
          >
            {teamRole !== "COMPTA_ADMIN" && (
              <div
                className={[
                  styles["content-side-item"],
                  styles["content-side-item--manager"],
                  isErrorManager && styles["content-side-item--error"],
                ].join(" ")}
              >
                <h2
                  className={[
                    styles["content-side-item__title"],
                    styles["content-side-item__title--manager"],
                    isErrorManager && styles["content-side-item__title--error"],
                  ].join(" ")}
                >
                  Manager
                </h2>
                {manager && (
                  <div
                    className={[
                      styles["content-side-item__content"],
                      styles["content-side-item__content--manager"],
                    ].join(" ")}
                  >
                    <MemberListView
                      members={[
                        {
                          firstName: manager.employee_firstname,
                          lastName: manager.employee_lastname,
                        },
                      ]}
                    />
                  </div>
                )}

                <div className={[styles["content-side-item__btn"]].join(" ")}>
                  <AddManagerBtn
                    setManager={setManager}
                    employeeProhibitedList={employeeProhibitedListForManager}
                    label={
                      manager ? "Changer de manager" : "Assigner un manager"
                    }
                  />
                </div>
              </div>
            )}
            {teamRole !== "COMPTA_ADMIN" && (
              <div
                className={[
                  styles["content-side-item"],
                  styles["content-side-item--project"],
                  isErrorProject && styles["content-side-item--error"],
                ].join(" ")}
              >
                <h2
                  className={[
                    styles["content-side-item__title"],
                    styles["content-side-item__title--project"],
                    isErrorProject && styles["content-side-item__title--error"],
                  ].join(" ")}
                >
                  Projet
                </h2>
                {project && (
                  <div
                    className={[
                      styles["content-side-item__content"],
                      styles["content-side-item__content--project"],
                    ].join(" ")}
                  >
                    <ProjectInfoCardView
                      title={project?.project_name}
                      description={project?.project_desc}
                      onDelete={() => setProject(null)}
                    />
                  </div>
                )}
                <div
                  className={[
                    styles["content-side-item__btn"],
                    styles["content-side-item__btn--project"],
                  ].join(" ")}
                >
                  <AddProjectBtn
                    setProject={setProject}
                    label={project ? "Changer de projet" : "Associer un projet"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles["form-struct__submit-btn"]}>
          {onSubmitForm && (
            <Button
              label={submitBtnLabel}
              onClick={() => {
                onSubmitForm();
              }}
              isSubmit
              iconArrow
              disabled={submitBtnDisabled}
            />
          )}
        </div>
        <div className={styles["form-struct__delete-btn"]}>
          {onDeleteTeam && (
            <Button
              label={"Supprimer l'équipe"}
              onClick={() => {
                openDeleteModal();
              }}
              style="gray"
              IconComponentRight={() => <img src={binIcon} />}
            />
          )}
        </div>
      </div>
    </>
  );
}
