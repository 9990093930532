import React from "react";
import styles from "./pagination.module.scss";
import { Link } from "react-router-dom";
import { on } from "events";

function Pagination({
  currentPage,
  lastPage = 0,
  linkBase,
  nbBtnToDisplay = 8,
  paramKey = "page",
  setCurrentPage,
  centered
}: {
  currentPage?: number;
  lastPage?: number;
  linkBase?: string;
  nbBtnToDisplay?: number;
  paramKey?: string;
  setCurrentPage?: (page: number) => void;
  centered?: boolean;
}) {
  if (lastPage <= 1) {
    return <></>;
  }
  const buttonPropsList = [];

  const separator = linkBase?.includes("?") ? "&" : "?";

  const nextlink =
    currentPage && currentPage < lastPage
      ? linkBase + separator + paramKey + "=" + (currentPage + 1).toString()
      : undefined;
  const prevlink =
    currentPage && currentPage > 1
      ? linkBase + separator + paramKey + "=" + (currentPage - 1).toString()
      : undefined;

  const onClickNext =
    setCurrentPage && currentPage && currentPage < lastPage ? () => setCurrentPage(currentPage + 1) : undefined;

  const onClickPrev =
    setCurrentPage && currentPage && currentPage > 1 ? () => setCurrentPage(currentPage - 1) : undefined;

  if (currentPage && lastPage) {
    const nbTodisplayDivi = Math.floor(nbBtnToDisplay / 2);

    const nbMaxDisplay =
      currentPage +
      nbTodisplayDivi +
      (currentPage - nbTodisplayDivi - 1 < 0
        ? (currentPage - nbTodisplayDivi - 1) * -1
        : 0);

    const nbMinDisplay =
      (currentPage || 0) -
      nbTodisplayDivi -
      (currentPage + nbTodisplayDivi > lastPage
        ? currentPage + nbTodisplayDivi - lastPage
        : 0);

    for (let i = 1; i <= lastPage; i++) {
      if (nbMinDisplay <= i && nbMaxDisplay >= i) {
        buttonPropsList.push({
          label: i.toString(),
          link: !setCurrentPage
            ? linkBase + separator + paramKey + "=" + i.toString()
            : undefined,
          actived: i === currentPage,
          onClick: setCurrentPage ? () => setCurrentPage(i) : undefined,
        });
      }
    }
  }

  return (
    <div className={[styles["pagination"],centered && styles["pagination--centered"]].join(" ")}>
      <PrevNextBtn
        label="Précédent"
        link={!setCurrentPage ? prevlink : undefined}
        onClick={onClickPrev}
      />
      {buttonPropsList.map((buttonProps, index) => (
        <Button key={index} {...buttonProps} />
      ))}
      <PrevNextBtn
        label="Suivant"
        link={!setCurrentPage ? nextlink : undefined}
        onClick={onClickNext}
        isNext={true}
      />
    </div>
  );
}

const Button = ({
  actived,
  label,
  link,
  onClick,
}: {
  actived?: boolean;
  label: string;
  link?: string;
  onClick?: () => void;
}) => {
  const content = (
    <div
      className={[
        styles["button"],
        styles["button" + (actived ? "--actived" : "--not-actived")],
      ].join(" ")}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick();
            }
          : undefined
      }
    >
      <p
        className={[
          styles["button__label"],
          styles["button__label" + (actived && "--actived")],
        ].join(" ")}
      >
        {label}
      </p>
    </div>
  );
  if (link) {
    return (
      <Link to={link} style={{ textDecoration: "none" }}>
        {content}
      </Link>
    );
  }

  if(onClick){
    return content;
  }

  return <></>
};

const ArrowIcon = ({ isNext }: { isNext?: boolean }) => {
  return (
    <svg
      className={[
        styles["button__icon-arrow"],
        styles["button__icon-arrow" + (isNext ? "--next" : "--prev")],
      ].join(" ")}
      width="10"
      height="15"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.17972 0.375989L10.7212 8.79815C10.8226 8.89842 10.8945 9.00704 10.9371 9.12401C10.9797 9.24099 11.0007 9.36631 11 9.5C11 9.63369 10.979 9.75902 10.9371 9.87599C10.8952 9.99296 10.8232 10.1016 10.7212 10.2018L2.17972 18.6491C1.94316 18.883 1.64746 19 1.29262 19C0.937786 19 0.63364 18.8747 0.380184 18.624C0.126728 18.3733 8.0349e-08 18.0809 1.09567e-07 17.7467C1.38785e-07 17.4125 0.126728 17.1201 0.380184 16.8694L7.83179 9.5L0.380186 2.13061C0.143627 1.89666 0.0253464 1.60823 0.0253464 1.26533C0.0253464 0.922428 0.152075 0.62598 0.405531 0.375989C0.658987 0.125329 0.954685 -8.78189e-07 1.29263 -8.48646e-07C1.63057 -8.19102e-07 1.92627 0.12533 2.17972 0.375989Z"
        fill="black"
      />
    </svg>
  );
};

const PrevNextBtn = ({
  label,
  link,
  isNext,
  onClick,
}: {
  label: string;
  link?: string;
  isNext?: boolean;
  onClick?: () => void;
}) => {
  const content = (
    <div
      className={[
        styles["button"],
        styles["button--not-actived"],
        styles["button" + (isNext ? "--next" : "--prev")],
      ].join(" ")}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick();
            }
          : undefined
      }
    >
      <div
        className={[
          styles["button__icon"],
          styles["button__icon" + (isNext ? "--next" : "--prev")],
        ].join(" ")}
      >
        <ArrowIcon isNext={isNext} />
      </div>
    </div>
  );
  if (link) {
    return (
      <Link to={link} style={{ textDecoration: "none" }}>
        {content}
      </Link>
    );
  }

  if(onClick){
    return content;
  }

  return <></>
};
export default Pagination;
