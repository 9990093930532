import React from 'react'
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import useUserAuth from '../../../features/auth/hook/useUserAuth';
export interface SideButton {
    name: string; 
    path: string;
    icon?: string;
}
export interface SideBarProps {
    sideButtonList: SideButton[]
}

export const Button = () => {

}
export const SideBar = ({sideButtonList}: SideBarProps) => {
    const {isUserAuthentificated, logoutUser}= useUserAuth()
    
  return (

    


            <ul style={{ listStyleType: "none", padding: 0 }}>
                {isUserAuthentificated ? <p>connecté</p> : <p>déconnecté</p> }
                <button onClick={(e) => {
                    e.preventDefault();
                    logoutUser();
                }}>Se déconnecté</button>
            {
                sideButtonList.map((sideButton) => {
                    return <li><Link to={sideButton.path}>{sideButton.name}</Link><DashboardIcon/></li>
                })
            }

            </ul>
 
  )
}

export default SideBar