export const _downloadFile = (
    filePath : string,
    fileName :string,
 
      
        contentType: string
    
  ) => {
    fetch( filePath, {
      method: 'GET',
      headers: {
        'Content-Type': contentType,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        if(link?.parentNode){
            link.parentNode.removeChild(link);
        }

      });
  };
