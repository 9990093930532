/*===================================================================
=       Number Dipayer Helper                  =
====================================================================*/
/**
 * Number Dipkayer Helper
 */


export function numberToStingWithSpaces(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}