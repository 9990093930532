import React from 'react'
import styles from './Button.module.scss'
import { Link } from 'react-router-dom';
import arrowBlack from './assets/arrow-icon-black.svg';

export interface ButtonProps {

  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: 'primary' | 'secondary' | 'tercery' |'red' | 'green' | 'black' | 'gray' ;
  bgColorHex?: string;
  fontColorHex?: string;
  hoverColorHex?: string;
  IconComponent?: React.FC;
  IconComponentRight?: React.FC;
  IconComponentLeft?: React.FC;
  linkTo?: string;
  isSubmit?: boolean;
  maxWidth?: string;
  iconArrow?: boolean;
  height?: string;
}

export default function Button({label, onClick, disabled, style = 'primary', bgColorHex, fontColorHex, hoverColorHex, IconComponent, linkTo,  IconComponentRight, maxWidth, iconArrow,   IconComponentLeft, height}: ButtonProps) {

  const btnStyle = {
    color: fontColorHex || 'none',
    backgroundColor: bgColorHex || 'none',
    maxWidth: maxWidth || 'none',
    height: height || 'none',
  }

  const disabledStyle = disabled? "disabled" : "not-disabled"
  const Content = () => (
    <div style={btnStyle} className={[styles['btn-content'],styles['btn-content--'+style], styles['btn-content--'+style+'--'+disabledStyle], disabled && styles['btn-content--disabled']].join(" ")}> 
      {IconComponentLeft && <div className={[styles['btn-content__icon-component-left'],!label && styles['btn-content__icon-component-left--no-label'] ].join(" ")}><IconComponentLeft /></div>}
      {IconComponent && <IconComponent />}
  
    
      {label && <span className={[styles['btn-content__label']].join(" ")}>{label}</span>}
      { IconComponentRight && <div className={[styles['btn-content__icon-component-right'], !label && styles['btn-content__icon-component-right--no-label'] ].join(" ")}><IconComponentRight /></div>}
      {iconArrow && <img className={styles['btn-content__arrow-icon']} src={arrowBlack} alt="arrow" />}
    </div>
  )

  if(linkTo && !disabled) {
    return (
      <Link className={styles['link']} to={linkTo}>
   
          <Content />

      </Link>
    )
  }

  return (
    <button className={[styles['button'], disabled && styles['button--disabled'] ].join(" ")} onClick={(e) => {
      e.preventDefault()
      if (onClick && !disabled) onClick()
    }}>
      <Content />
    </button>
  )
}
