/*===================================================================
=      CONFIGURE STORE  =
====================================================================*/
/**
 * 
 */
import logger from 'redux-logger'
import { Store,applyMiddleware } from 'redux'
import { 
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import { Tuple, configureStore } from '@reduxjs/toolkit';

import storage from "redux-persist/lib/storage";


import rootReducer, { IRootState } from './rootReducer'


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userAuth'],

}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store: Store<IRootState> = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  }
)



let persistor = persistStore(store)

export {
  store,
  persistor,
};