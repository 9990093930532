/*================================================================
 *SingleTeamListView
 *================================================================*/
import React from 'react'
import ListBoxItem from '../../../../../components/molecules/ListBoxItem/ListBoxItem';
import styles from './teamList.module.scss';

import compas from '../../../assets/compas.svg'
import people from '../../../assets/people.svg'
import money from '../../../assets/money-white.svg'
import Badge from '../../../../../components/molecules/Bagde/Badge';

export interface SingleTeamListView {
  name: string;
  memberCount: number;
  projectName?: string;
  handleClick?: () => void;
  linkTo: string
  role?: string | null;
}

export function SingleTeamListView({name, memberCount, projectName, handleClick, linkTo, role}: SingleTeamListView) {
  return (
    <div className={styles['single-team-list']}>
      <ListBoxItem 
        titleLeft={name}
        withIconArrowRight={true}
        handleClick={handleClick}
        linkTo={linkTo}
        ButtomComponent={() => (<div className={styles['tag-list']}>
          <Badge 
            iconSource={people}
            text={memberCount+ ' employé'+(memberCount > 1 ? 's' : '')}
            color='black'
            />
          { projectName &&
            <Badge 
              iconSource={compas}
              text={projectName}
              color='primary'
              />
          }
          {
            role && role === 'Comptable' && 
            <Badge 
              iconSource={money}
              text={role}
              color='blue'
              />
          }
        </div>)}  
      />
    </div>
  )
}


