import React, { MouseEventHandler } from "react";
import styles from "./AproveDisaproveActionBtnView.module.scss";
import { TextField } from "@mui/material";
import Button from "../../../../../../components/molecules/Button/Button";

export interface BtnProps {
  onClick: () => void;
  disable?: boolean;
}

export interface AproveDisaproveActionViewProps {
  // Define the props for the component here

  approveBtn: BtnProps;
  refuseBtn: BtnProps;
  askForUpdateBtn: BtnProps;
  remaksValue: string;
  setRemaks: (remaks: string) => void;
}

const AproveDisaproveActionView: React.FC<AproveDisaproveActionViewProps> = ({
  approveBtn,
  askForUpdateBtn,
  refuseBtn,
  remaksValue,
  setRemaks,
}) => {
  // Implement the component logic here

  return (
    <div className={styles["aprove-disprove-view"]}>
      <div className={styles["aprove-disprove-view__text"]}>
        <TextField
          id="outlined-multiline-static"
          label="Remarque"
          multiline
          rows={4}
          fullWidth
          value={remaksValue}
          onChange={(e) => setRemaks(e.target.value)}
        />
      </div>
      <div>
        <div className={styles["keyboard"]}>
          <div className={[styles["keyboard__button"]].join(" ")}>
            <Button
              label="Valider"
              onClick={approveBtn.onClick}
              disabled={approveBtn.disable}
              style="green"
       
            />
          </div>
          <div className={[styles["keyboard__button"]].join(" ")}>


          <Button
            label="Refuser"
            onClick={refuseBtn.onClick}
            disabled={approveBtn.disable}
            style="red"
          />
          </div>
          <div className={[styles["keyboard__button"]].join(" ")}>
          <Button
            label="Demander une correction"
            onClick={askForUpdateBtn.onClick}
            disabled={approveBtn.disable}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AproveDisaproveActionView;
