import { IActionReducer } from "../../../types/action.types";
import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types";
import { FetchTeamFilterOption } from "../services/teamAPI";
import { TeamDetail } from "../types/team.types";
import { teamTypes } from "./team.types";

export interface ITeamState {
    teamList: ResponseAPIWithPagination<TeamDetail> | null
    isLoadingTeam: boolean
    filterTeamList: FetchTeamFilterOption | null
    currentPage: 1
}

const INITIAL_STATE: ITeamState = {
    teamList: null,
    isLoadingTeam: false,
    filterTeamList: null,
    currentPage: 1

}

const teamReducer = (state: ITeamState = INITIAL_STATE, action: IActionReducer) => {
    switch (action.type) {
        case teamTypes.SET_TEAM_LIST:{
            return {
                ...state,
                teamList: action.payload
            }
        }

        case teamTypes.SET_IS_LOADING_TEAM:{
            return {
                ...state,
                isLoadingTeam: action.payload
            }
        }

        case teamTypes.SET_FILTER_TEAM_LIST:{
            return {
                ...state,
                filterTeamList: action.payload
            }
        }

        case teamTypes.SET_CURRENT_PAGE:{
            return {
                ...state,
                currentPage: action.payload
            }
        }
        default:
            return state
    }
}

export default teamReducer;