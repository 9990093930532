import styles from './CreateTeamPage.module.scss'
import { CreateTeam } from '../../../features/team/components/CreateTeam'
import ReturnBackBtn from '../../../components/atoms/ReturnBackBtn/ReturnBackBtn'
import GenericGoBackBtn from '../../../components/organismes/GenericGoBackBtn/GenericGoBackBtn'

export default function CreateTeamPage() {
  return (
    <div className={styles['page']}>
      <div className={styles['page__return-btn']}><GenericGoBackBtn/></div>
        <div className={styles['page__title']}>Nouvelle équipe</div>
        <div className={styles['page__content']}>
            <CreateTeam.CreateTeamForm />
        </div>
    </div>
  )
}
