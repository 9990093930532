import DashboardPage from "../page/DashboardPage/DashboardPgae";
import SingleSpendingDemandPage from "../page/SpendingDemand/SingleSpendingDemandPage/SingleSpendingDemandPage";
import SpendingDemandPage from "../page/SpendingDemand/SpendingDemandPage/SpendingDemandPage";
import SingleTeamPage from "..//page/Team/SingleTeamPage/SingleTeamPage";
import TeamListPage from "../page/Team/TeamListPage/TeamListPage";
import CreateTeamPage from "../page/Team/CreateTeamPage/CreateTeamPage";
import UpdateTeamPage from "../page/Team/UpdateTeamPage/UpdateTeamPage";

export const homeRoutes = [
    {
      path: "/",
      name: "Dashboard",
      main: () => <DashboardPage/>,
      isInSideBar: true
    },
    {
      path: "/spending_demand",
      name: "Demande de Dépense",
      main: () => <SpendingDemandPage/>,
      isInSideBar: true
    },
    {
      path: "/spending_demand/:spendingDemandId",
      name: "detail",
      main: () => <SingleSpendingDemandPage/>,
    },

  
    {
      path: "teams",
      name: "Equipes",
      main: () => <TeamListPage/>,
      isInSideBar: true
    },
    {
      path: "teams/new",
      name: "Nouvelle équipes",
      main: () => <CreateTeamPage/>,
      isInSideBar: true
    },
  
    {
      path: "teams/:teamId",
      name: "Detail",
      main: () => <SingleTeamPage/>
    },
    {
      path: "/spending_demand/:teamId/edit",
      name: "detail",
      main: () => <UpdateTeamPage/>,
    },
  ];

  export default homeRoutes