import { FilterSpendingDemand } from "../types/spendingDemand.types";


export const getQueryFilterIntervalValue = (min : any, max : any) => {
    if(min === undefined && max === undefined){
        return null
    }
    let result = "";

    let resultMin = "";
    let resultMax = "";
    let separator = ""
    if (min !== undefined){
        resultMin += ">=" + min;
    }
    if (max !== undefined){
        resultMax += "<=" + max;
    }

    
    if(resultMin !== "" && resultMax !== ""){
        separator = ','
    }
    result = resultMin +separator+ resultMax
    return result;

}


export interface ResultQueryFilter {
    queryString: string;
    queryArray: {key: string, value: string | null}[]

}

export const FilterpendingDemandConverterToQuery = (filter : FilterSpendingDemand | null | undefined, startWith?: "?" | "&" ) : ResultQueryFilter=> {



    if (filter === null || filter === undefined){
        return {
            queryString: "",
            queryArray : []
        }
    }
    const spendingDemandStatus = filter.status?.join(",") || null
    const spendingUserId = filter.userId?.join(",") || null
    const spendingDemandOrderBy = filter.orderBy || null
    const spendingDemandAmount =  getQueryFilterIntervalValue(filter.amount?.min, filter.amount?.max)
    const spendingDemandAmountTTC = getQueryFilterIntervalValue(filter.amoutTTC?.min, filter.amoutTTC?.max)
    const spendingDemandDate = getQueryFilterIntervalValue(filter.date?.min, filter.date?.max)
    const spendingDemandUpdateDate = getQueryFilterIntervalValue(filter.updateDate?.min, filter.updateDate?.max)
    const spendingDemandToProcessStatus = filter.toProcessStatus !== undefined ? (filter.toProcessStatus ? "true" : "false") : null

    const managerWaitingValidation = filter.managerWaitingValidation?.join(",") || null
    let result = ""+
    (spendingDemandStatus ? 'status=' + spendingDemandStatus : "") +
    (spendingDemandOrderBy ? '&orderBy=' + spendingDemandOrderBy : "") +
    (spendingDemandAmount ? '&amount=' + spendingDemandAmount : "") +
    (spendingDemandAmountTTC ? '&amountTTC=' + spendingDemandAmountTTC : "") +
    (spendingDemandDate ? '&date=' + spendingDemandDate : "") +
    (spendingDemandUpdateDate ? '&updateDate=' + spendingDemandUpdateDate : "") +
    (spendingDemandToProcessStatus != null ?'&toProcessStatus='+spendingDemandToProcessStatus: "" )+
    (managerWaitingValidation ? '&managerWaitingValidation=' + managerWaitingValidation : "") 



    const resultArray = [
        {
            key: 'status',
            value: spendingDemandStatus
        },
        {
            key: 'orderBy',
            value: spendingDemandOrderBy
        },
        {
            key: 'amount',
            value: spendingDemandAmount
        },
        {
            key: 'amountTTC',
            value: spendingDemandAmountTTC
        },
        {
            key: 'date',
            value: spendingDemandDate
        },
        {
            key: 'updateDate',
            value: spendingDemandUpdateDate
        },
        {
            key: 'user',
            value: spendingUserId
        
        },
        {
            key: 'managerWaitingValidation',
            value: managerWaitingValidation
        }

    ]
    if (result !== "") {
        result += (startWith || "?")+result;
    }
  
    return {
        queryString: result,
        queryArray : resultArray
    }

}