
import ImportBtnPresenter from "./ImportBtn/ImportBtnPresenter";
import TeamListPresenter from "./TeamList/TeamListPresenter";
import CreateNewTeamBtnPresenter from "./CreateNewTeamBtn/CreateNewTeamBtnPresenter";
import TeamFilterPresenter from "./TeamFilter/TeamFilterPresenter";

const TeamList = TeamListPresenter;

const TeamListConponents = {
    List : TeamList,
    ImportBtn : ImportBtnPresenter,
    CreateNewTeamBtn: CreateNewTeamBtnPresenter,
    Filter: TeamFilterPresenter
}
export default TeamListConponents;