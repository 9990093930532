/*===================================================================
=       TeamListPage                           =
====================================================================*/
import TeamList from "../../../features/team/components/TeamList";

import styles from "./teamListPage.module.scss";

const TeamListPage = () => {
  return (
    <div className={styles["team-list-page"]}>
      <div className={styles["header"]}>
        <div className={styles["btn"]}>
          <TeamList.CreateNewTeamBtn />
        </div>
        <div className={styles["btn"]}>
          <TeamList.ImportBtn />
        </div>
      </div>
      <div className={styles["team-list-page__title"]}>Équipes</div>
      <TeamList.Filter />
      <TeamList.List />
    </div>
  );
};

export default TeamListPage;
