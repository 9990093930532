/*===================================================================
=               USER AUTH REDUCER                                   =
====================================================================*/


import { IActionReducer } from "../../../types/action.types"
import { IUser, IUserEmployee } from "../types/user.types";
import { userAuthTypes } from "./userAuth.types";

/*======== TYPES =========== */

export interface IUserAuthState {
    /**
     * Specifies if the user is authenticated or not
     */
    isAuth: boolean,
    /**
     * Access token
     */
    accessToken: string | null,
    /**
     * Sepecifies if the user information si loading
     */
    isLoadingUserAuth: boolean,
    /**
     * Data of the user
     */
    userData: IUser | null

    /**
     * Employee data
     * is null if the user is not employee
     */
    employeeData: IUserEmployee | null,
    /**
     * specifies if the employee information is loading
     */
    isEmployee: boolean,
}

/*======== INITIAL_STATE =========== */
/**
 * Initial state of user auth reducer
 */
const INITIAL_STATE : IUserAuthState = {
    isAuth: false, 
    accessToken: null,
    isLoadingUserAuth: false,
    userData: null,
    isEmployee: false,
    employeeData: null,
}

/*======== REDUCER =========== */
const userAuthReducer = (state: IUserAuthState = INITIAL_STATE, action: IActionReducer) => {
    switch (action.type) {
        case userAuthTypes.SET_USER_DATA:{
            return {
                ...state, 
                userData: action.payload
            }
        }
        
        case userAuthTypes.SET_IS_AUTH:{
            return {
              ...state,
                isAuth: action.payload
            }
        }
        case userAuthTypes.SET_ACCESS_TOKEN:{
            return {
              ...state,
                accessToken: action.payload
            }
        }

        case userAuthTypes.SET_IS_LOADING_USER_AUTH:{
            return {
             ...state,
                isLoadingUserAuth: action.payload
            }
        }

        case userAuthTypes.LOGOUT_USER: {
            return {
                ...INITIAL_STATE,
            }
        }

        case userAuthTypes.SET_IS_EMPLOYEE:{
            return {
                ...state, isEmployee: action.payload
            }
        }

        case userAuthTypes.SET_EMPLOYEE_DATA:{
            return {
               ...state, 
                employeeData: action.payload
            }
        }
        default:
            return state;
    }
}

export default userAuthReducer;