import React, { useState } from "react";
import styles from "./FilesList.module.scss";
import { Link } from "react-router-dom";
import { _downloadFile } from "./utils";
import ReactSimpleImageViewer from "react-simple-image-viewer";

import dowloadIcon from "./assets/download.svg";
import eyeIcon from "./assets/eye.svg";
export interface FileItem {
  src: string;
  filename: string;
  filetype: string;
  handleDelete?: () => void;
  handleDownload?: () => void;
  handlePreview?: () => void;
}

export interface FilesListProps {
  filesList: FileItem[];
  label?: string;
}
export default function FilesList({ filesList, label }: FilesListProps) {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImageSrc, setCurrentImageSrc] = useState("");
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };
      const openImageViewer = (index: number, imagesrc: string) => {
        setCurrentImageSrc(imagesrc);
        setCurrentImage(0);
        setIsViewerOpen(true);

      };
  return (
    <div className={styles['file-container']}>
      <h2 className={styles['file-container__title']}>{label}</h2>
    <ul className={styles['file-list']}>
      {filesList.map((file, index) => (

        <li className={styles['file-item']} key={index}>
        <p className={[styles['file-item__name']].join(" ")}>{String(file.filename).split('.')[0]}</p>
        <div className={[styles['btn-keyboard']].join(" ")}>
          <div className={[styles['btn-keyboard__btn'], styles['btn-keyboard__btn--viewer']].join(" ")}>
          <Button
            onClick={() => openImageViewer(index, file.src)}
            icon="eye"
          />
          </div>
          <div className={[styles['btn-keyboard__btn'], styles['btn-keyboard__btn--download']].join(" ")}>
          <Button
          onClick={() => {
            _downloadFile(file.src, file.filename, file.filetype);
          }}
            icon="download"
          />
          </div>
        </div>
        </li>

       
      ))}

        {isViewerOpen && (
        <ReactSimpleImageViewer
          src={ [currentImageSrc] } 
          currentIndex={ currentImage }
          disableScroll={ true }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}
    </ul>
    </div>
  );
}

export interface ButtonProps {
    icon: 'eye' | 'download'
    onClick: () => void;

}

const Button = ({onClick, icon}:ButtonProps) => {

    return <button onClick={(e) => {
      e.preventDefault();
      onClick();
    }} className={styles['btn']}>
      { icon === 'eye' &&
        <img src={eyeIcon} className={styles['btn__icon']} />
      }
      { icon === 'download' &&
        <img src={dowloadIcon}  className={styles['btn__icon']} />
      }
      </button>
}



