import React from "react";
import styles from "./ReturnBackBtn.module.scss";
import ArrowBack from "./assets/arrow-back.svg";
import { Link } from "react-router-dom";

export interface ReturnBackBtnProps {
  linnkTo?: string;
  onClick?: () => void;
  label: string;
}
export default function ReturnBackBtn({
  linnkTo,
  onClick,
  label = "Retourner",
}: ReturnBackBtnProps) {

  const content =   (<>  <img
  className={styles["close-btn__icon"]}
  src={ArrowBack}
  alt="Retourner"
/>
<span className={styles["close-btn__label"]}>{label}</span>
</>)
  if (linnkTo) {
    return (
      <Link to={linnkTo} className={styles["close-btn"]}>
        {content}
      </Link>
    );
  }
  return (
    <button
      className={styles["close-btn"]}
      onClick={(e) => {
        if (onClick) onClick();
      }}
    >
      {content}
    </button>
  );
}
