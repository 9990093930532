import React from 'react'
import Button from '../../../../../components/molecules/Button/Button'
import plusIcon from './assets/plus.svg'
import useUserAuth from '../../../../auth/hook/useUserAuth'

import { chechUserIsAuthorizedTo } from '../../../utils/team.utils'
export default function CreateNewTeamBtnPresenter() {

  const {userInfo} = useUserAuth()
  if (!userInfo){
    return <></>
  }
  if (!chechUserIsAuthorizedTo('create', userInfo?.user_role)){
     return <></>
   }
  return (  <Button 
    label="Créer une équipe"
    style="primary"
    linkTo="/teams/new"
    IconComponentRight={() => <img src={plusIcon} alt="plus" style={{marginTop: '4px', width: '18px'}}/>}
    />

  )
}
