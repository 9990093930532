import React from 'react'
import ProjectInfoCardView from '../../../../project/components/ProjectInfoCardView/ProjectInfoCardView'
import { useSingleTeam } from '../../../hooks'

export interface ProjectInfoCardPresenterProps {
  TitleComponent?: React.FC
}
export default function ProjectInfoCardPresenter({TitleComponent}: ProjectInfoCardPresenterProps) {

    const {singleTeamData} = useSingleTeam()

    const teamProject = singleTeamData?.team_project
    
    if(!singleTeamData || !teamProject ) {return <></>}
  return (
    <>
    {TitleComponent && <TitleComponent/>}
    { teamProject &&
      <ProjectInfoCardView
        description={teamProject?.project_desc}
        title={teamProject?.project_name}
       />
    }
    </>

  )
}
