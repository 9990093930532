import React from 'react'
import LoginFormView from './LoginFormView'
import useUserAuth from '../../hook/useUserAuth'

export default function LoginFormPresenter() {

    const {authentificateTheUser} = useUserAuth()
    const handleSubmit = (email: string, password: string) => {
        authentificateTheUser( email, password, () => {
            
        })
    }
  return (
    
    <LoginFormView handleSubmit={handleSubmit}/>
  )
}
