import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types";
import { FetchTeamFilterOption } from "../services/teamAPI";
import { TeamDetail } from "../types/team.types";
import { teamTypes } from "./team.types";

export const setTeamListAction = (teamList:ResponseAPIWithPagination<TeamDetail> | null) => ({
    type: teamTypes.SET_TEAM_LIST,
    payload: teamList
})

export const setIsLoadingTeamAction = (isLoadingTeam: boolean) => ({
    type: teamTypes.SET_IS_LOADING_TEAM,
    payload: isLoadingTeam
})

export const setFilterTeamListAction = (filterTeamList: FetchTeamFilterOption | null) => ({
    type: teamTypes.SET_FILTER_TEAM_LIST,
    payload: filterTeamList
})

export const setCurrentPageAction = (currentPage: number) => ({
    type: teamTypes.SET_CURRENT_PAGE,
    payload: currentPage
})