import React from "react";
import SpendingDemandListView, {
  ItemSpending,
} from "./SpendingDemandListView/SpendingDemandListView";

import { dateConverter } from "../../../../../utils/dateConverter";

import { useSpendingDemand } from "../../../hooks";
import Pagination from "../../../../../components/organismes/Pagination/Pagination";
import { spendingDemandStatusRawToReadableStatus } from "../../../utils/spenpdingDemandStatusConverter";
import useUserAuth from "../../../../auth/hook/useUserAuth";
import EmptyListIcon from "../../../../../components/atoms/EmptyListIcon/EmptyListIcon";
import Loader from "../../../../../components/atoms/Loader/Loader";

export default function SpendingDemandListPresenter() {
  const {
    allSpendingDemandList: { data: spendingDemandListData, isLoading },
  } = useSpendingDemand();

  const { userInfo } = useUserAuth();
  const pagination = spendingDemandListData?.pagination;

  const formated: ItemSpending[] | undefined =
    spendingDemandListData?.data?.map((spending) => {
      const isToProcess = userInfo?.user_employee_id
        ? spending.manager_employee_id_waiting_for_validation.includes(
            userInfo?.user_employee_id
          )
        : false;
      const statusObject = spendingDemandStatusRawToReadableStatus(
        spending.spending_demand_status,
        "for-user",
        isToProcess
      );

      return {
        id: spending.spending_demand_id,
        title: spending.spending_demand_desc,
        userFirstName: spending.spending_demand_user.username,
        userLastName: spending.spending_demand_user.lastname,
        createdDate: dateConverter(spending.spending_demand_date),
        grossAmmount: spending.spending_demand_amountTTC,
        status: statusObject.status,
        pinColor:
          spendingDemandStatusRawToReadableStatus(
            spending.spending_demand_status,
            "general"
          ).color.normal || undefined,
        withPin: isToProcess,
      };
    });

  if (isLoading) {
    return <Loader paddingTop="20%" centered />;
  }
  if (!formated) {
    return <EmptyListIcon message="Une erreur est survenue" />;
  }
  if (formated && formated.length === 0) {
    return <EmptyListIcon message="Aucune demande de dépense trouvée" />;
  }

  return (
    <>
      <SpendingDemandListView spendingList={formated || []} />
      <Pagination
        currentPage={pagination?.current_page}
        linkBase="/spending_demand"
        lastPage={pagination?.total_pages}
      />
    </>
  );
}
