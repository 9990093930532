import { Employee } from "../../../features/employee/types/employee.type";
import { Project } from "../../project/types/project.type";


export type TeamRoles = "COMPTA_ADMIN" | "OTHER"

export type TeamOption =  {value: TeamRoles, label: string, disable?: boolean, message?:string }
export const TEAM_OPTIONS :TeamOption[] = [{value: "COMPTA_ADMIN", label: "Comptable"}, {value: "OTHER", label: "Autre"}]

export interface Team {
    team_id: string,
    team_name: string,
    team_description: string,
    team_creator:string,
    team_project: string,
    team_manager: string,
    team_creation_date: string,
    team_update_date: string,
    team_employees: string[],
    team_members_count: number,
    team_role: TeamRoles
}

export interface TeamDetail extends Omit<Team, 'team_manager' | 'team_project'> {
    team_manager: null | Employee;
    team_project: null | Project;
}

export type TeamPatch  = Partial<Team>
