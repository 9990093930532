import React, { useState } from 'react'
import { Employee } from '../../../../../employee/types/employee.type';
import SearchEmployeeMemberModal from '../../../../../employee/components/SearchEmployeeMemberModal';
import { Label } from '@mui/icons-material';
import Button from '../../../../../../components/molecules/Button/Button';
import { employeeProhibitedItem } from '../../../../../employee/components/SearchPickEmployee/SearchSelectEmployeePresenter';

export interface AddManagerBtnProps {
  setManager: (manager: Employee) => void;
  label?: string;
  titleModal?: string;
  employeeProhibitedList?: employeeProhibitedItem[];

 }
export default function AddManagerBtn({setManager, label = 'Ajouter un manager', titleModal='Ajouter un manager', employeeProhibitedList} : AddManagerBtnProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen &&
        <SearchEmployeeMemberModal titleModal={titleModal} onClose={handleModalClose} setEmployeeOne={setManager}  employeeProhibitedList={employeeProhibitedList} noList={true}/>
      }
      <Button onClick={() => {handleModalOpen()}} maxWidth="180px" label={label} style="black" />
    </>
  )
}
