/*================================================================
 *                Badges
 *================================================================*/


import './badge.scss';
function Badge({iconSource, text, color}:{iconSource: string, text: string, color:'black' | 'primary' | 'blue'}) {
    return <div className={`badge badge--${color}`}>
      <img className='badge--image'src={iconSource} alt={text}/>
      <p className='badge--text'>{text}</p>
    </div>
  }

  export default Badge;