import axios from "axios";
import { ResponseAPIWithPagination } from "../../../types/responsesAPI.types";
import { Employee } from "../types/employee.type";

const BASE_URL = process.env.REACT_APP_URL_BACKEND

const searchEmployeeProtectedAPI = async (query: string, page: number = 0, pageSize = 0 ) => {
    return (await axios.get<ResponseAPIWithPagination<Employee>>(
        `${BASE_URL}/api/v2/employees/search?query=${query}&pageSize=${pageSize}&currentPage=${page}`)).data;
}

const getSingleEmployeeProtectedAPI = async (employeeId: string) => {
    return (await axios.get<Employee>(        `${BASE_URL}/api/v2/employees/${employeeId}`)).data;
}
export default {
    searchEmployeeProtectedAPI,
    getSingleEmployeeProtectedAPI
}