import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useEffect } from "react";
import teamAPI from "../../services/teamAPI";

export const QUERY_KEY_TEAM = "signle-team"
export const QUERY_KEY_TEAM_MEMBERS_LIST = "team-members-list"
export const QUERY_KEY_SINGLE_TEAM_SPENDING_DEMAND_LIST = "team-single-spending-demands-list"
function useSingleTeamCore(singleTeamId: string,
  handleNetworkErrorFunction:
    | ((error: AxiosError<unknown, any>, SreenContext: string | null) => void)
    | null,
  handleInternErrorFunction:
    | ((errorMessage: string | null, errorCode: number | null) => void)
    | null, 
    UserEmployeeId: string 
) {
  const handleInternError = (
    errorMessage: string | null,
    errorCode: number | null
  ) => {
    if (handleInternErrorFunction) {
      handleInternErrorFunction(errorMessage, errorCode);
    }
  };

  const handleNetworkError = (
    error: AxiosError<unknown, any>,
    SreenContext: string | null
  ) => {
    if (handleNetworkErrorFunction) {
      handleNetworkErrorFunction(error, SreenContext);
    }
  };


  const queryClient = useQueryClient(); 



  const { data: singleTeam, isLoading, isError, error, isFetching, isSuccess, refetch } = useQuery({
    queryFn: () => teamAPI.getFecthSingleTeamAPI(singleTeamId),
    queryKey: ["teams", singleTeamId]
    
  })

  const { data: membersList, isLoading : isLoadingMemberList} = useQuery({
    queryFn: () => teamAPI.getFetchMembersListOfTeam(singleTeamId),
    queryKey: ["teams", singleTeamId, "members"]
  })


  const { data: spendingDemandList, isLoading : isLoadingspendingDemandList} = useQuery({
    queryFn: () => teamAPI.getFetchTeamAllSpendingDemand(singleTeamId, UserEmployeeId),
    queryKey: ["teams", singleTeamId, "spending-demands"]
  })

  useEffect(() => {
    if(isError){
      handleNetworkError(error as AxiosError, "teamList")
    }
  },[isError])

  return {
    singleTeamData : singleTeam,
    isLoading : isLoading,
    memberList: {
      membersListData : membersList,
      isLoading : isLoadingMemberList
    },
    spendingDemandList : {
      spendingDemandListData : spendingDemandList,
      isLoading : isLoadingspendingDemandList
    },
  };
}

export default useSingleTeamCore;
