import AproveDisaproveActionBtn from "./AproveDisaproveActionBtn";
import GeneralInfo from "./GeneralInfo/GeneralInfoPresenter";
import HistoricStatusPresenter from "./HistoricStatus/HistoricStatusPresenter";

export const SingleSpendingDemand = {
    GeneralInfo : GeneralInfo,
    AproveDisaproveActionBtn : AproveDisaproveActionBtn,
    HistoricStatut : HistoricStatusPresenter
}

export default SingleSpendingDemand