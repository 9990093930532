import { SpendingDemand, SpendingDemandExtended } from "../../spendingDemand/types/spendingDemand.types";
import { Spending, SpendingDemandFrontAdapted, SpendingDemandRequest, SpendingDemandRequestModify, SpendingExtended, SpendingReq, SpendingReqModify } from "../types/spending.types";

export function adaptSpendingDemandToSpending (spendingDemand:SpendingDemand) :SpendingDemandFrontAdapted {

    
    return{
        spending_id: spendingDemand.spending_demand_id,
        spending_payments: spendingDemand.spending_demand_payments,
        spending_user: spendingDemand.spending_demand_user,
        admin_account_id: spendingDemand.admin_account_id,
        spending_category: spendingDemand.spending_demand_category,
        spending_supplier: spendingDemand.spending_demand_supplier,
        spending_project: spendingDemand.spending_demand_project,
        spending_chantier: spendingDemand.spending_demand_chantier,
        spending_chantier_part:spendingDemand.spending_demand_chantier_part ,
        spending_equipement: spendingDemand.spending_demand_equipement,
        spending_equipement_part: spendingDemand.spending_demand_equipement_part,
        spending_amount: spendingDemand.spending_demand_amount,
        spending_TVA: spendingDemand.spending_demand_TVA,
        spending_taxes: spendingDemand.spending_demand_taxes,
        spending_taxes_debit: spendingDemand.spending_demand_taxes_debit,
        spending_amountTTC: spendingDemand.spending_demand_amountTTC,
        spending_total_payed: spendingDemand.spending_demand_total_payed,
        spending_rest_to_pay: spendingDemand.spending_demand_rest_to_pay,
        spending_fully_paid: spendingDemand.spending_demand_fully_paid,
        spending_total_matched: spendingDemand.spending_demand_total_matched,
        spending_rest_to_match: spendingDemand.spending_demand_rest_to_match,
        spending_fully_matched: spendingDemand.spending_demand_fully_matched,
        spending_available: spendingDemand.spending_demand_available,
        spending_files: spendingDemand.spending_demand_files,
        spending_desc: spendingDemand.spending_demand_desc,
        spending_date: spendingDemand.spending_demand_date,
        spending_update_date: spendingDemand.spending_demand_update_date,
        spending_status: spendingDemand.spending_demand_status,
        spending_remarks: spendingDemand.spending_demand_remarks,
        spending_labels: spendingDemand.spending_demand_labels,
        type: spendingDemand.type,
        isDemand: true,
    }
}

export function adaptSpendingDemandExtendedToSpendingExtended (spendingDemand:SpendingDemandExtended) :SpendingExtended {
    return{
        spending_id: spendingDemand.spending_demand_id,
        spending_payments: spendingDemand.spending_demand_payments,
        spending_user: spendingDemand.spending_demand_user,
        admin_account_id: spendingDemand.admin_account_id,
        spending_category: spendingDemand.spending_demand_category,
        spending_supplier: spendingDemand.spending_demand_supplier,
        spending_project: spendingDemand.spending_demand_project,
        spending_chantier: spendingDemand.spending_demand_chantier,
        spending_chantier_part:spendingDemand.spending_demand_chantier_part ,
        spending_equipement: spendingDemand.spending_demand_equipement,
        spending_equipement_part: spendingDemand.spending_demand_equipement_part,
        spending_amount: spendingDemand.spending_demand_amount,
        spending_TVA: spendingDemand.spending_demand_TVA,
        spending_taxes: spendingDemand.spending_demand_taxes,
        spending_taxes_debit: spendingDemand.spending_demand_taxes_debit,
        spending_amountTTC: spendingDemand.spending_demand_amountTTC,
        spending_total_payed: spendingDemand.spending_demand_total_payed,
        spending_rest_to_pay: spendingDemand.spending_demand_rest_to_pay,
        spending_fully_paid: spendingDemand.spending_demand_fully_paid,
        spending_total_matched: spendingDemand.spending_demand_total_matched,
        spending_rest_to_match: spendingDemand.spending_demand_rest_to_match,
        spending_fully_matched: spendingDemand.spending_demand_fully_matched,
        spending_available: spendingDemand.spending_demand_available,
        spending_files: spendingDemand.spending_demand_files,
        spending_desc: spendingDemand.spending_demand_desc,
        spending_date: spendingDemand.spending_demand_date,
        spending_update_date: spendingDemand.spending_demand_update_date,
        spending_status: spendingDemand.spending_demand_status,
        spending_remarks: spendingDemand.spending_demand_remarks,
        spending_labels: spendingDemand.spending_demand_labels,
        type: spendingDemand.type,
        isDemand: true,
    }
}

export const adaptSpendingReqModifyToSpendingDemandReqModify = (spendingReqModify: SpendingReqModify) : SpendingDemandRequestModify => {
    return {
        spending_demand_desc: spendingReqModify.spending_desc,
        spending_demand_amount: spendingReqModify.spending_amount,
        /**
         * Float
         */
        spending_demand_amountTTC:spendingReqModify.spending_amountTTC,
        /**
         * Float
         */
        spending_demand_rest_to_pay: spendingReqModify.spending_rest_to_pay,

        spending_demand_total_payed: spendingReqModify.spending_total_payed,
        spending_demand_date: spendingReqModify.spending_date,
        spending_demand_files: spendingReqModify.spending_files,
        spending_demand_category: spendingReqModify.spending_category,
        spending_demand_status: spendingReqModify.spending_status
    }
}

export const adaptSpendingReqModifyToSpendingDemandReq = (spendingReq: SpendingReq) : SpendingDemandRequest => {
    return {
        spending_demand_desc: spendingReq.spending_desc,
        spending_demand_amount: spendingReq.spending_amount,
        /**
         * Float
         */
        spending_demand_amountTTC:spendingReq.spending_amountTTC,
        /**
         * Float
         */
        spending_demand_rest_to_pay: spendingReq.spending_rest_to_pay,

        spending_demand_total_payed: spendingReq.spending_total_payed,
        spending_demand_date: spendingReq.spending_date,
        spending_demand_files: spendingReq.spending_files,
        spending_demand_category: spendingReq.spending_category,
        spending_demand_update_date: spendingReq.spending_update_date,

    }
}

