/*===================================================================
=       Info Modal View                =
====================================================================*/
/**
 * 
 */

import React, { useEffect } from 'react'

import styles from './InfoModalView.module.scss'
import Button from '../../../../../molecules/Button/Button'



export type iconType =  'good' | 'bad' | 'neutral' | 'question' | 'sending'| 'loader'

/*============ TYPES =================*/
export interface InfoModalViewProps {
    title?: string | null,
    contentText?: string | null,
    handleClose?: () => void,
    buttonLabel?: string | null,
    iconType?: iconType
    noCloseButton?: boolean,
    progressBarvalue?: number | undefined,
    loaderMode?: boolean
  }


const iconSlector = (type: iconType ) => {

    switch (type) {
        case 'good':
            return () => <></>
        case 'bad':
            return () => <></>
        case 'question':
            return () =>  <></>
        case 'neutral':
            return () =>  <></>
        case 'sending':
            return () =>  <></>
        default: 
            return () =>  <></>

    }
}



/**
 * 
 * @param param0 
 * @returns 
 */
const InfoModalView = ({handleClose, title, contentText, buttonLabel, iconType, noCloseButton,  progressBarvalue, loaderMode}: InfoModalViewProps) => {

    const Icon = iconSlector((iconType) as iconType || 'neutral')

    if(loaderMode){
        return (
            <div className= {styles['background']}>
                <div className={styles['modale']}>
                    Loader
                </div>
            </div>
        )
    }
  return (
    <div className= {styles['background']}>
    <div className={styles['modal-card']}>
        <div className={styles['modal-card__header']}>
          <h2 className={styles['modal-card__title']}>{title}</h2>
        </div>
        <div className={styles['modal-card__content']}>
          <p>{contentText}</p>
        </div>
        <div className={styles['modal-card__footer']}>
            {handleClose &&  <div className={styles['btn']}><Button label="OK" onClick={() => {handleClose()}}  /></div>}
          


        </div>
      </div>
    </div>
  )
}

export default InfoModalView