import React from 'react'
import styles from './ModalCard.module.scss'
import Button from '../../molecules/Button/Button';

export interface ModalCardProps {
    title: string;
    description?:    string;
    onClose: () => void;
    handleValidate?: () => void;
    labelValidate?: string;
    withCancelBtn?: boolean;

}
export default function ModalCard({title, description, onClose, labelValidate="OK", withCancelBtn, handleValidate}: ModalCardProps) {
  return (
    <div className={styles['background']}>
      <div className={styles['modal-card']}>
        <div className={styles['modal-card__header']}>
          <h2 className={styles['modal-card__title']}>{title}</h2>
        </div>
        <div className={styles['modal-card__content']}>
          <p>{description}</p>
        </div>
        <div className={styles['modal-card__footer']}>
            {withCancelBtn &&  <div className={styles['btn']}><Button label="Annuler" onClick={() => {onClose()}} style="gray" /></div>}
          
            <div className={styles['btn']}><Button label={labelValidate} onClick={() => { if(handleValidate){ handleValidate() }else{onClose()}}}/></div>

        </div>
      </div>
    </div>
  )
}
