
/*===================================================================
=                        USER TYPES                                     =
====================================================================*/

/**
 * User Roles
 * 
 * 0: 
 * 1: User
 * 2: Admin
 * 3: Super Admin
 * 4: Admin Plus
 * 10:User Plus
 */


export const userRolesCode = {
    USER: 1,
    NULL: 0,
    ADMIN: 2,
    SUPER_ADMIN: 3,
    ADMIN_PLUS: 4,
    USER_PLUS: 10
}

export type UserRole = 0 | -1 | 1 | 2 | 3 |4 | 10 
/**
 * User Types from the beckend
 */

export interface IUser {
    user_id: string,
    email:  string,
    user_password: string,
    /**
     * Code roles
     * user: >= 1
     * employee: 0 | -1
     * 
     * info employee is employee
     */
    user_role: UserRole,
    admin_account_id:string,
    username: string,
    lastname: string,
    account: number,
    birthdate: string,
    user_address: string,
    user_address2:string ,
    user_zipcode:string,
    user_city:string,
    user_country:string,
    gender:string,
    phone:string,
    user_creation_date: string,
    user_update_date: string,
    user_status: string,
    user_is_active: boolean,
    active_notif: boolean,
    from_employee: boolean,
    user_employee_id:string,
    user_type:string,
    user_collab_account:string,
    type: string
}

export type IUserEmployee = {
    employee_id: string,
    admin_account_id: string,
    employee_matricule: string,
    employee_firstname: string,
    employee_lastname: string,
    employee_gender: string |"male",
    employee_hire_date: string,
    employee_service: string,
    employee_function: string,
    employee_salary: number,
    employee_sit_matr: string,
    employee_children: number,
    employee_creation_date:string,
    employee_update_date: string,
    employee_status: string,
    employee_cnss: string,
    employee_accounting: string,
    employee_personal_account: string,
    employee_password:string,
    type: string
}