/*================================================================
 *ListBoxItem
 *================================================================*/

import React from "react";

import "./ListBoxItem.scss";

import arrowSVG from "./assets/arrow.svg";
import { Link } from "react-router-dom";
export interface ListBoxItemProps {
  titleLeft?: string;
  titleRight?: string;
  subTitleRight?: string;
  subTitleLeft?: string;
  headerTitleLeft?: string;
  headerTitleRight?: string;
  spanTitleLeft?: string;
  spanTitleRight?: string;
  withIconArrowRight?: boolean;
  handleClick?: () => void;
  linkTo?: string;
  ButtomComponent?: React.ComponentType;
  titleSize?: string;
  lableSize?: string;
  titleLabelRight?: string;
  titleLabelLeft?: string;
  pinColor?: string;
  withPin?: boolean;
}

function ListBoxItem({ titleLeft, ButtomComponent, linkTo, withIconArrowRight, titleSize, titleRight, subTitleLeft, spanTitleLeft, spanTitleRight, subTitleRight, headerTitleLeft, headerTitleRight, lableSize, titleLabelLeft, titleLabelRight, pinColor, withPin}: ListBoxItemProps) {

  const titleStyle = {
    fontSize: titleSize
  }

  const labelStyle = {
    fontSize: lableSize
  }
  const pinStyle = {
    backgroundColor: pinColor
  }
  const content = (
      <>
      { (withPin) &&
        <div className={"pin"} style={pinStyle}/>
      }
        <div className="content">
          <div className="content-body">
          <div className="content-container content-container--left">
            { (headerTitleLeft || headerTitleRight) &&
              <h3 className="content-container__header-title content-container__header-title--left">{headerTitleLeft}</h3>
            }

            { titleLeft &&
              <div className="title-container"><h2 style={titleStyle}  className="title-container__title title-container__title--left">{titleLeft}</h2><span className="title-container__label title-container__label--left"  style={labelStyle}>{titleLabelLeft}</span></div>
            }
            { subTitleLeft &&
              <h3 className="content-container__sub-title content-container__sub-title--left">{subTitleLeft}</h3>
            }
          </div>

          <div className="content-container content-container--right"> 
            { (headerTitleLeft || headerTitleRight)  &&
              <h3 className="content-container__header-title content-container__header-title--right">{headerTitleRight}</h3>
            }

            { titleRight &&
              <div className="title-container title-container--right"><h2 style={titleStyle}  className="title-container__title title-container__title--right">{titleRight}</h2><span className="title-container__label title-container__label--right"  style={labelStyle}>{titleLabelRight}</span></div>
            }
            { subTitleRight &&
              <h3 className="content-container__sub-title content-container__sub-title--right">{subTitleRight}</h3>
            }
          </div>


          </div>
          {ButtomComponent && (
            <div className="bottom-content">
              <ButtomComponent />
            </div>
          )}
        </div>
        {  withIconArrowRight &&
          <div className="right-btn">
            <img src={arrowSVG} />
          </div>
        }
      </>
   
  );


  if(linkTo !== undefined){
    return <Link to={linkTo} className="list-box-item list-box-item--clickable">{content}</Link>
  }

  return   <div className="list-box-item">{content}</div>;
}

export default ListBoxItem;
