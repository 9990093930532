/*===================================================================
=   USER REDUCER                     =
====================================================================*/
/**
 * User Reducer
 */

import { IActionReducer } from "../../../types/action.types"
import { userTypes } from "./user.types"

/* ============ TYPES ============ */
export interface IUserState {
    isLoadingSingleUser: boolean
}

/* ============ INITIAL STATE ============ */
const initialState = {
    isLoadingSingleUser: false 
}

export const userReducer = (state: IUserState = initialState, action: IActionReducer): IUserState => {

    switch (action.type){
        case userTypes.SET_IS_LOADING_SINGLE_USER:
            return {
                ...state,
                isLoadingSingleUser: action.payload
            }
        default:
            return state
    }
}

export default userReducer;