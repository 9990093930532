/*===================================================================
=               API SERVICES                               =
====================================================================*/
/**
 * Service to get data from the backend
 */
import axios from "axios";


const BASE_URL = process.env.REACT_APP_URL_BACKEND
/**
 * Get user credentials
 * @param param0  Password, Email
 */
export const getAuthService = (email: string, password: string,) => {
    const body = {email: email, user_password: password}
    return axios.post(`${BASE_URL}/login`, body )
}
export const getUsersByTokenService = () => {
    return axios.get(`${BASE_URL}/login` )
}
/**
 * Get the User information from the server
 * 
 * @param userEmployeeId Id of the user
 * @returns 
 */
export const getUserInfoServiceProtected = (userEmployeeId: string) => {
    return axios.get(`${BASE_URL}/employees/user/info/${userEmployeeId}`) ;
}

/**
 * Ask to the backend to start the 'i forgot my password' process
 */
export const resetPasswordService = (email: string) => {
    return axios.post(`${BASE_URL}/resetPWD`, {email});
}
