import SpendingDemandList from "./SpendingDemandList";
import ManagerInfoCard from "./ManagerInfoCard";
import MembersList from "./MembersList";
import ProjectInfoCard from "./ProjectInfoCard";
import TeamHeaderTitle from "./TeamHeaderTitle";
import ModifyTeamBtn from "./ModifyTeamBtn";

export default {
    TeamHeaderTitle,
    MembersList,
    ManagerInfoCard,
    ProjectInfoCard,
    SpendingDemandList,
    ModifyTeamBtn
}