import React, { ReactNode, createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface LayoutContextHandler{
    setNoSideBar: (isNoSideBar: boolean) => void
    isNoSideBar: boolean;

}

const LayoutContext = createContext<LayoutContextHandler | null>(null)

export const LayoutContextProvider = ({children} :{children:ReactNode}) => {

    const [isNoSideBar, setIsNoSideBar] = useState(false)
    const location : string = useLocation().search;
    let  hidesidebar  = new URLSearchParams(location).get('nosidebar');

    useEffect(() => {
        if(hidesidebar === 'true'){
            setIsNoSideBar(true)
        }
        if(hidesidebar === 'false'){
            setIsNoSideBar(false)
        }
    },[hidesidebar]
    )

    
    const data : LayoutContextHandler = {
        setNoSideBar: setIsNoSideBar,
        isNoSideBar
    }
    return (

        <LayoutContext.Provider value={data}>
            {children}
        </LayoutContext.Provider>
    )
}

export function useLayoutContext(){
    const context = React.useContext(LayoutContext)
    if(!context){
        throw new Error('useLayoutContext must be used within a LayoutContextProvider')
    }
    const {setNoSideBar, isNoSideBar} = context
    return {
        setNoSideBar,
        isNoSideBar
    }
}