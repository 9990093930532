import React from 'react'
import useSpendingCore from './core/useSpendingCore'
import useErrorHandler from '../../../hook/useErrorHandler'

function useSpending() {
    
    const {handleNetworkError} = useErrorHandler()
    
  return useSpendingCore(null, handleNetworkError, null, null)
}

export default useSpending
