import React from 'react'
import styles from './GeneralInfoTeamFormView.module.scss'
import TextInput from '../../../../../../components/atoms/TextInput/TextInput'
import { TeamOption, TeamRoles } from '../../../../types/team.types';
import SelectInput from '../../../../../../components/atoms/SelectInput/SelectInput';

export interface GeneralInfoTeamFormViewProps {

  setNameValue: (value: string) => void,
  setDescriptionValue: (value: string) => void,
  nameValue: string,
  descriptionValue: string,
  isErrorName ?: boolean;
  isErrorDescription ?: boolean;
  teamRoleOptions?: TeamOption[];
  teamRoleValue?: TeamRoles;
  setTeamRoleValue?: (value: TeamRoles) => void;
}
export default function GeneralInfoTeamFromView({
  setNameValue,
  setDescriptionValue,
  nameValue,
  descriptionValue,
  isErrorDescription,
  isErrorName,
  teamRoleOptions,
  teamRoleValue,
  setTeamRoleValue
  
}: GeneralInfoTeamFormViewProps) {

  return (
    <div className={styles['form-team']}>
      <div className={[styles['form-team__input-text'], styles['form-team__input-text--team-name']].join(" ")}>
      <TextInput 
      style={{
        fontSize: '2rem'
      }}
        label="Nom"
        onChange={(e) => {
          e.preventDefault()
          setNameValue(e.target.value)
        }}
        value={nameValue}
        error= {isErrorName}
        />
      </div>

      <div className={[styles['form-team__input-text'], styles['form-team__input-text--team-description']].join(" ")}>
      <TextInput 
        label="Description"
        rows={4}
        multiline
        onChange={(e) => {
          e.preventDefault()
          setDescriptionValue(e.target.value)
        }}
        value={descriptionValue}
        error= {isErrorDescription}
        />
      </div>
      { setTeamRoleValue && 
      <div className={[styles['form-team__input-text'], styles['form-team__input-text--team-description']].join(" ")}>
        <SelectInput
          label="Rôle"
          value={teamRoleValue}
          handleChange={setTeamRoleValue}
          options={teamRoleOptions}
        />
      </div>
}

    </div>
  )
}
