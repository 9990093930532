import React from "react";
import styles from "./SelectButton.module.scss";
import arrowDown from "./assets/arrow-down.svg";
import Loader from "../Loader/Loader";
import crossIcon from './assets/cross.svg'

export interface SelectButtonProps {
  options?: string[];
  selectedOption?: string;
  setSelectedOption?: (option: string) => void;
  multiple?: boolean;
  valueList?: any[] | null;
  deleteItem?: (item: any) => void;
  placeholder?: string;
  onClick?: () => void;
  label?: string;
  getNameItem: (item: any) => string;
  isLoading?: boolean;
  deleteAllItems?: () => void;
}
export default function SelectButton({
  label,
  onClick,
  placeholder = "Selectionner",
  valueList,
  deleteItem,
  getNameItem,
  isLoading,
  deleteAllItems
  
}: SelectButtonProps) {
    
  return (

    <div className={styles["select-button"]}>
      {label && <p className={styles["select-button__label"]}>{label}</p>}
      <button className={styles["button"]} onClick={  (deleteAllItems && valueList && valueList.length > 0 ) ? undefined: onClick}>
        <div className={styles["button-container"]}>
          <div className={styles["button-container__selected-content"]}>
            {valueList && valueList.length > 0 ? (
              <>
                <Item
                  key={valueList[0].index}
                  item={valueList[0].item}
                  getNameItem={() => getNameItem(valueList[0])}
                  onClick={(deleteAllItems && valueList && valueList.length > 0 )  ?  onClick : undefined}
                />
                {valueList.length > 1 && (
                  <Item getNameItem={() => "+" + (valueList.length - 1)}                   onClick={(deleteAllItems && valueList && valueList.length > 0 )  ?  onClick : undefined}/>
                )}
              </>
            ) : 
            ( isLoading ? 
              <div className={styles["loader"]}><Loader size={20} paddingTop="0" centered/></div>
              :
              <span>{placeholder}</span>
            )}
          </div>
          {
            (deleteAllItems && valueList && valueList.length > 0 ) ? <div className={styles["button-container__img--close"]} onClick={deleteAllItems}>            <img
            className={styles["button-container__img"]}
            src={crossIcon}
            alt="cross"
          /></div>
        
             :
            <img
            className={styles["button-container__img"]}
            src={arrowDown}
            alt="arrow-down"
          />
          }

        </div>
      </button>
    </div>
  );
}

interface ItemProps {
  item?: any;
  deleteItem?: (item: any) => void;
  getNameItem: (item: any) => string;
  onClick?: () => void;
}

const Item = ({ item, deleteItem, getNameItem, onClick }: ItemProps) => {
  const nameFormatted =
    getNameItem(item).length > 20
      ? getNameItem(item).substring(0, 20) + "..."
      : getNameItem(item);
  return (
    <div className={styles["item"]} onClick={onClick}>
      <span className={styles["item__name"]}>{nameFormatted}</span>
      {deleteItem && <button  className={styles["item__delete"]} onClick={() => deleteItem(item)}>x</button>}
    </div>
  );
};
