/*===================================================================
=             ROOT REDUCER                            =
====================================================================*/
/**
 * Combine all reducers into a single root reducer.
 */

import { combineReducers } from 'redux';

import userAuth, { IUserAuthState } from '../features/auth/redux/userAuth.reducer';
import spending, { ISpendingState } from '../features/spending/redux/spending.reducer';
import user,{ IUserState} from '../features/user/redux/user.reducer';
import spendingDemand, { ISpendingDemandState } from '../features/spendingDemand/redux/spendingDemand.reducer';
import { ITeamState } from '../features/team/redux/team.reducer';
import team from '../features/team/redux/team.reducer';
// Reducers





/*======== TYPES =========== */
export interface IRootState {
    userAuth: IUserAuthState,
    spending: ISpendingState,
    user:IUserState,
    spendingDemand: ISpendingDemandState,
    team: ITeamState
}

/*======== ROOT REDUCER =========== */
const appReducer = combineReducers({
    userAuth,
    spending,
    user,
    spendingDemand,
    team
});


export const rootReducer = (state: any, action: never) => {
    /*
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    */

    return appReducer(state, action)
}

export default rootReducer;