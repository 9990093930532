/*===================================================================
=       SPENDING REDUX MAPSTATES               =
====================================================================*/
/**
 * 
 */

import { IRootState } from "../../../redux/rootReducer";

import { spendingDemandListFilter, spendingDemandListSorter, spendingListFilter, spendingListSorter } from "../utils/spendingListSoterFilter";



/**
 * Spending Map State
 * 
 * return the state
 * 
 * @param state 
 * @returns 
 */
export const spendingMapState = (state: IRootState) => ({
    spending: state.spending,
})

/**
 * Filtered Spending Demand MapState
 * 
 * Return the list of spending demand filtered and sorted
 * @param state 
 * @returns 
 */
export const filtredSpendingListMapState = (state: IRootState) => {
    const {spending: {filterSpendingDemandsList: filter, sorterSpendingDemandsList: sorter, listSpending}} = state;


    if(!listSpending){
        return listSpending
    }

    const listSpendingSorted = listSpending.sort((a,b) => {
        const dateA = new Date(a.spending_update_date)
        const dateB = new Date(b.spending_update_date)
        return  dateB.getTime() - dateA.getTime()
    })
    return  spendingListSorter(sorter, spendingListFilter( filter,listSpendingSorted))
}


/**
 * Raw Spending Demannd List Map State
 * 
 * Retrun the raw list of the spending demands
 * @param state 
 * @returns 
 */
export const rawSpendingListStateMapState = (state: IRootState) => {
    const {spending: {filterSpendingDemandsList: filter, sorterSpendingDemandsList: sorter, listSpending}} = state;


    if(!listSpending){
        return listSpending
    }

    const listSpendingSorted = listSpending.sort((a,b) => {
        const dateA = new Date(a.spending_update_date)
        const dateB = new Date(b.spending_update_date)
        return  dateB.getTime() - dateA.getTime()
    })
    return  listSpendingSorted
}


/**
 * Filtered Spending MapState
 * 
 * Return the list of spending demand filtered and sorted
 * @param state 
 * @returns 
 */
export const filtredSpendingDemandMapState = (state: IRootState) => {
    const {spending: {filterSpendingDemandsList: filter, sorterSpendingDemandsList: sorter, listSpendingDemands}} = state;


    if(!listSpendingDemands){
        return listSpendingDemands
    }

    const listSpendingDemandSorted = listSpendingDemands.sort((a,b) => {
        const dateA = new Date(a.spending_demand_update_date)
        const dateB = new Date(b.spending_demand_update_date)
        return  dateB.getTime() - dateA.getTime()
    })
    return  spendingDemandListSorter(sorter, spendingDemandListFilter( filter,listSpendingDemandSorted))
}

/**
 * Raw Spending Demannd List Map State
 * 
 * Retrun the raw list of the spending demands
 * @param state 
 * @returns 
 */
export const rawSpendingDemandListStateMapState = (state: IRootState) => {
    const {spending: {filterSpendingDemandsList: filter, sorterSpendingDemandsList: sorter, listSpendingDemands}} = state;


    if(!listSpendingDemands){
        return listSpendingDemands
    }

    const listSpendingDemandSorted = listSpendingDemands.sort((a,b) => {
        const dateA = new Date(a.spending_demand_update_date)
        const dateB = new Date(b.spending_demand_update_date)
        return  dateB.getTime() - dateA.getTime()
    })
    return  listSpendingDemandSorted
}