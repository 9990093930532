/*===================================================================
=       VALIDITY CHECKER                 =
====================================================================*/
/**
 * Check the validity of the input
 */


//===============TYPES==============================

export type TypeInput = 'text-not-empty'|'email'|'number-int'|'number-float' |'all'

export type TextInputCheckingItem = {
    value: string; 
    id: string;
    typeChecking:TypeInput
}

export type InputValidityState = {
    [id:string] : boolean
}

export type CheckMultiTextInputValidityResult = {
    isAllValid: boolean;
    inputValidityStateObject: InputValidityState
}


/**
 * 
 * @param textInputList 
 * @returns 
 */
export const checkMultiTextInputValidity = (textInputList: TextInputCheckingItem[]) => {

    return textInputList.reduce<CheckMultiTextInputValidityResult>((result, textInput) => {
        
        const isValid = checkValidity(textInput.value,textInput.typeChecking)
        if(!isValid){
          return { isAllValid:false, inputValidityStateObject:{...result.inputValidityStateObject, [textInput.id]: false}}
        }else{
            return { ...result, inputValidityStateObject:{...result.inputValidityStateObject, [textInput.id]: true}}
        }
    }, {isAllValid:true, inputValidityStateObject:{}})

}


/**
 * CheckValidity of the text
 * @param value 
 * @param type 
 */
export const checkValidity = (value: string | undefined, type: TypeInput) => {
    switch(type) {
        case 'text-not-empty':
            return validateNotEmptyText(value)
        case 'email':
            return validateEmail(value)
        case 'number-int':
            return validateNumberInt(value)
        case 'number-float':
            return validateNumberFloat(value)
        case 'all':
            return true
        default: 
            return true
    }
}


/**
 * 
 * @param email 
 * @returns 
 */
export const validateEmail = (email: string | undefined) => {
    if (email === undefined) {
        return false
    }
    const result = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      return result !== null ? true : false;
};

/**
 * 
 * @param text 
 * @returns 
 */
export const validateNotEmptyText = (text: string | undefined) => {
    if (text === undefined) {
        return false
    }
    const result = String(text)
        .toLocaleLowerCase()
        .match(/(.|\s)*\S(.|\s)*/)

    return result !== null ? true : false;
}

/**
 * 
 * @param number 
 * @returns 
 */
export const validateNumberFloat = (number: string | undefined) => {
    if (number === undefined) {
        return false
    }
    const result = String(number)
        .toLocaleLowerCase()
        .match(/[+-]?([0-9]*[.])?[0-9]+/)

    return result !== null ? true : false;
}


/**
 * 
 * @param number 
 * @returns 
 */
export const validateNumberInt = (number: string | undefined) => {
    if (number === undefined) {
        return false
    }
    const result = String(number)
        .toLocaleLowerCase()
        .match(/^[0-9]+[^0]$/)

    return result !== null ? true : false;
}

/**
 * handle check Input 
 * create and set the error state of the object
 * @param inputCheckList 
 * @param setStateValidity 
 * @returns 
 */
export const handleCheckInput = (inputCheckList:TextInputCheckingItem[], setStateValidity: ( inputValidityState:InputValidityState | null) => void) => {
    const {isAllValid, inputValidityStateObject} = checkMultiTextInputValidity(inputCheckList)
    setStateValidity(null)
    if(!isAllValid){
        setStateValidity({...inputValidityStateObject})
    }

    return {isAllValid, inputValidityStateObject}

}

/**
 *  Check Input Validity
 * @param stateValidity 
 * @param idInput 
 * @returns 
 */
export const getInputValidityStateById = (stateValidity:InputValidityState | null , idInput: string) => {
    return stateValidity?.[idInput] !== undefined ? !(stateValidity[idInput]) : false
}