import React from "react";
import ReturnBackBtn from "../../atoms/ReturnBackBtn/ReturnBackBtn";
import { useNavigate } from "react-router-dom";

function GenericGoBackBtn({ label = "Retourner" }) {
  const navigate = useNavigate();

  return (
    <>
      {window.history.length > 2 && (
        <ReturnBackBtn label={label} onClick={() => navigate(-1)} />
      )}
    </>
  );
}

export default GenericGoBackBtn;
