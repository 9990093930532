
import { FilterSpendingDemand } from "../types/spendingDemand.types";

import { useDispatch, useSelector } from "react-redux";
import { spendingDemandMapState } from "../redux/spendingDemand.mapstate";
import { setFilterSpendindDemandAction } from "../redux/spendingDmeand.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUrlParameter } from "../../../utils/urlQueryGenerator";

export default function useSpendingDemandFilter() {
  const { spendingDemand } = useSelector(spendingDemandMapState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname, search } = location;

  const url = `${pathname}${search}`;

  const urlBase = updateUrlParameter(url, "page", "1");

  const setFilter = (filter: FilterSpendingDemand | null) => {
    
    navigate(urlBase);
    dispatch(setFilterSpendindDemandAction(filter));
  };

  return {
    filter: spendingDemand.filter,
    setFilter,
  };
}
