import { AxiosError } from "axios"
import { handlerErrorFunctions } from "../../../../utils/errorHlandlerUtils"
import { useMutation, useQueries, useQuery, useQueryClient } from "@tanstack/react-query"
import SpendingDemandAPI from "../../services/SpendingDemandAPI"
import { isPending } from "@reduxjs/toolkit"
import employeeAPI from "../../../employee/services/employeeAPI"
import { isError } from "util"
import { useEffect } from "react"

 export const useSingleSpendingDemandCore = ( singleSpendingDemandId: string, handleNetworkErrorFunction:
    | ((error: AxiosError<unknown, any>, SreenContext: string | null) => void)
    | null,
  handleInternErrorFunction:
    | ((errorMessage: string | null, errorCode: number | null) => void)
    | null,
    employeeId: string
) => {
    const  {handleNetworkError, handleInternError} = handlerErrorFunctions( handleNetworkErrorFunction,handleInternErrorFunction )


    const queryClient = useQueryClient()

    const historicStatus = useQuery({
        queryKey: ["spendingdemand",singleSpendingDemandId, "historic_status"],
        queryFn: () => SpendingDemandAPI.getFetchHistoricStatusSpendingDemand(singleSpendingDemandId)
        })

    const querySingleSpendingDemand = useQuery({
        queryFn: () => SpendingDemandAPI.getFetchSingleSpendingDemandAPIProtectedV2(singleSpendingDemandId,  employeeId),
        queryKey: ["spendingdemand",singleSpendingDemandId]
    })



    const sendAskForUpdateSpendingDemand = useMutation<any,any,{message: string,spendingDemandIdSpecific?: string } >({
        mutationFn: ({message,spendingDemandIdSpecific }:{message: string, spendingDemandIdSpecific?: string}) => {
            return SpendingDemandAPI.askForUpdateSpendingDemandAPIProtected(spendingDemandIdSpecific || singleSpendingDemandId, message  )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['spendingdemand']})
            queryClient.invalidateQueries({ queryKey: ['teams']})
            queryClient.invalidateQueries({ queryKey:["spendingdemand",singleSpendingDemandId, "historic_status"]})
        }
    })

    const sendRefuseSpendingDemand= useMutation<any,any,{message: string,spendingDemandIdSpecific?: string } >({
        mutationFn: ({message,spendingDemandIdSpecific }:{message: string, spendingDemandIdSpecific?: string}) => {
            return SpendingDemandAPI.refuseSpendingDemandAPIProtected(spendingDemandIdSpecific || singleSpendingDemandId, message  )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['spendingdemand']})
            queryClient.invalidateQueries({ queryKey: ['teams']})
            queryClient.invalidateQueries({ queryKey:["spendingdemand",singleSpendingDemandId, "historic_status"]})
        }
    })
    
    const sendValidateSpendingDemand= useMutation<any,any,{message: string,spendingDemandIdSpecific?: string } >({
        mutationFn: ({spendingDemandIdSpecific }:{spendingDemandIdSpecific?: string}) => {
            return SpendingDemandAPI.validateSpendingDemandAPIProtected(spendingDemandIdSpecific || singleSpendingDemandId )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['spendingdemand']})
            queryClient.invalidateQueries({ queryKey: ['teams']})
            queryClient.invalidateQueries({ queryKey:["spendingdemand",singleSpendingDemandId, "historic_status"]})
        }
    })

    useEffect(() => {
        
        if(querySingleSpendingDemand.isError){
            handleNetworkError(querySingleSpendingDemand.error as AxiosError, "singleSpendingDemand")
        }

        if(sendAskForUpdateSpendingDemand.isError){
            handleNetworkError(sendAskForUpdateSpendingDemand.error as AxiosError, "sendAskForUpdateSpendingDemand")
        }
        
        if(sendRefuseSpendingDemand.isError){
            handleNetworkError(sendRefuseSpendingDemand.error as AxiosError, "sendRefuseSpendingDemand")
        }

        if (sendValidateSpendingDemand.isError){
            handleNetworkError(sendValidateSpendingDemand.error as AxiosError, "sendValidateSpendingDemand")
        }

    }, [querySingleSpendingDemand.isError, sendAskForUpdateSpendingDemand.isError, sendRefuseSpendingDemand.isError, sendValidateSpendingDemand.isError])

  
   const managerWaitingQueries = useQueries({
    queries: (querySingleSpendingDemand?.data?.manager_employee_id_waiting_for_validation ?? []).map( managerIdWaiting => {
        return {
            queryKey: ["employees",managerIdWaiting],
            queryFn: () => employeeAPI.getSingleEmployeeProtectedAPI(managerIdWaiting)
        }
    })
   })



    



    return {
        singleSpendingDemandData: querySingleSpendingDemand.data,
        isLoading: querySingleSpendingDemand.isLoading,

        sendAskForUpdateSpendingDemand :{
            run : sendAskForUpdateSpendingDemand.mutate,
            isPending : sendAskForUpdateSpendingDemand.isPending,
            isSucess : sendAskForUpdateSpendingDemand.isSuccess,
            isError : sendAskForUpdateSpendingDemand.isError

        },
        
        sendRefuseSpendingDemand : {
            run : sendRefuseSpendingDemand.mutate,
            isPending : sendRefuseSpendingDemand.isPending,
            isSucess : sendRefuseSpendingDemand.isSuccess,
            isError : sendRefuseSpendingDemand.isError
        },
        sendValidateSpendingDemand : {
            run: sendValidateSpendingDemand.mutate,
            isPending : sendValidateSpendingDemand.isPending,
            isSucess : sendValidateSpendingDemand.isSuccess,
            isError : sendValidateSpendingDemand.isError
        },
        managerWaitingValidation : {
            isPending: managerWaitingQueries.some(query => query.isLoading),
            data: managerWaitingQueries.map(query => query.data),
            isError : managerWaitingQueries.some(query => query.isError)
        },
        historicStatus: {
            isPending: historicStatus.isLoading,
            data: historicStatus.data,
            isError: historicStatus.isError
        }


    }
} 