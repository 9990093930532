import React, { useState } from "react";
import Button from "../../../../../../components/molecules/Button/Button";
import ModalAddProjectView from "../../../../../project/components/ModalAddProjetView/ModalAddProjectView";

export interface AddProjectBtnProps {
 setProject: (project: any) => void;
 label?: string;
}

export default function AddProjectBtn({setProject, label = "Ajouter un projet"}:AddProjectBtnProps) {
  const [isOpenModal, setOpenModal] = useState<Boolean>(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  return (<>
    {
        isOpenModal ?
        <ModalAddProjectView onClose={handleCloseModal} title={label} setProject={setProject}/> : 
        <Button label={label} onClick={() => handleOpenModal()} style={"black"} maxWidth={"150px"}/>
    }
  </>);
}
