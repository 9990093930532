import React from 'react'

import { useSingleTeam } from '../../../hooks'
import Button from '../../../../../components/molecules/Button/Button'
import editIcon from './assets/edit.svg'
import useUserAuth from '../../../../auth/hook/useUserAuth'

import { chechUserIsAuthorizedTo } from '../../../utils/team.utils'
export default function ModifyTeamBtnPresenter() {
   const {singleTeamData, isLoading} = useSingleTeam()
   const {userInfo} = useUserAuth()
   if (!userInfo){
     return <></>
   }
   if (!chechUserIsAuthorizedTo('modify', userInfo?.user_role)){
      return <></>
    }
   
  return (
    <div>
      <Button 
        label="Modifier l'équipe" 
        IconComponentLeft={() => <img src={editIcon} alt="edit" />}
        linkTo={"/spending_demand/"+singleTeamData?.team_id+"/edit"}
        style="tercery"/>

    </div>
  )
}
