import React, { useState } from "react";
import ImportBtnView, { FilePiked } from "./ImportBtnView/ImportBtnView";
import { useTeam } from "../../../hooks";
import useUserAuth from "../../../../auth/hook/useUserAuth";
import { chechUserIsAuthorizedTo } from "../../../utils/team.utils";

export default function ImportBtnPresenter() {
  const [filePicked, setFilePicked] = useState<FilePiked | null>(null);


  const { importCSVTeam } = useTeam();
  const handleImportCSV = (file: FilePiked) => {
    setFilePicked(file);
  };

  const cleanFilePicked = () => {
    setFilePicked(null);
  };

  const handleSendCSV = () => {
    if (filePicked?.content) {
      importCSVTeam.run({csvData: filePicked.content });
    }
  }

  const {userInfo} = useUserAuth()

  if (!userInfo ||
    chechUserIsAuthorizedTo( 'create', userInfo?.user_role) === false
  ){
    return (<></>)
  }
  return (
    <ImportBtnView
      handleImport={handleImportCSV}
      fileSelected={filePicked?.fileName || null}
      accept=".csv"
      cleanFilePicked={cleanFilePicked}
      handleSendCSV={handleSendCSV}
      isPending={importCSVTeam.isLoading}
    />
  );
}
