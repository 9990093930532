/*===================================================================
=      Spending Reducer                       =
====================================================================*/
/**
 * Spending Reducer
 */

import { IActionReducer } from "../../../types/action.types";
import { SpendingDemand, SpendingDemandExtended } from "../../spendingDemand/types/spendingDemand.types";
import { Spending, SpendingCategory, SpendingDemandFrontAdapted, SpendingDemandStatus, SpendingExtended } from "../types/spending.types";
import { spendingActionTypes } from "./spending.types";

//=============== TYPE ==================
export type OrderByType = 'spending_date_asc' | 'spending_date_desc'| null

export interface FilterSpendingDemandsList{
    status?:  SpendingDemandStatus[];
}

export interface SorterSpendingDemandsList{
    orderby?:OrderByType
}
export type ISpendingState = {
    isLoadingSpendingList: boolean,
    listSpending: null | Spending[],
    listSpendingDemands: null | SpendingDemand[],
    isLoadingCategory: boolean,
    spendingCategoriesList: SpendingCategory[] | null,
    isLoadingSendSpending: boolean,
    sendingProgressPourcentage: number,
    filterSpendingDemandsList: FilterSpendingDemandsList | null,
    sorterSpendingDemandsList: SorterSpendingDemandsList | null,
    singleSpendingToModify: null  |SpendingDemandFrontAdapted,
    isLoadingSpendingDemandsList: boolean,
    singleSpending: null | SpendingExtended,
    isLoadingSingleSpending: boolean,
    isLoadingSpendingDemandListAdmin: boolean,
    listSpendingDemandsAdmin: SpendingDemandExtended[] | null
}

//=============== INITIAL STATE ==================
const initialState: ISpendingState = {
    isLoadingSpendingList:false,
    listSpending: null,
    listSpendingDemands: null,
    spendingCategoriesList: null,
    isLoadingCategory: false,
    isLoadingSendSpending: false,
    sendingProgressPourcentage:0,
    filterSpendingDemandsList: null,
    sorterSpendingDemandsList: null,
    singleSpendingToModify: null,
    isLoadingSpendingDemandsList: false,
    isLoadingSingleSpending: false,
    singleSpending: null,
    isLoadingSpendingDemandListAdmin: false,
    listSpendingDemandsAdmin: null
}

//=============== RDUCER ==================

export const spendingReducer = (state: ISpendingState = initialState, action: IActionReducer) :ISpendingState => {
    switch (action.type) {
        case spendingActionTypes.SET_IS_LOADING_SPENDING_LIST:
            return {
              ...state,
              isLoadingSpendingList: action.payload
            }

        case spendingActionTypes.SET_SPENDING_LIST:
            return {
              ...state,
                listSpending: action.payload
            }
        
        case spendingActionTypes.FETCH_LIST_SPENDING_SUCCESS:
            return {
                ...state,
                listSpending: action.payload,
                isLoadingSpendingList: false
            }
        
        case spendingActionTypes.SET_IS_LOADING_SPENDING_CATEGORY:
            return {
                ...state,
                isLoadingCategory: action.payload
            }

        case spendingActionTypes.SET_SPENDING_CATEGORY_LIST:
            return {
                ...state,
                spendingCategoriesList: action.payload
            }
        
        case spendingActionTypes.SET_LOADING_SEND_NEW_SPENDING:
            return {
                ...state,
                spendingCategoriesList: action.payload
            }
        case spendingActionTypes.SET_NEW_SPENDING_SENDING_PROGRESS_PROGRESS:
            return {
                ...state,
                sendingProgressPourcentage: action.payload
            }

        case spendingActionTypes.SET_SORTER_SPENDING_DEMAND_LIST:
            return{
                ...state,
                sorterSpendingDemandsList: action.payload
            }
        
        case spendingActionTypes.SET_FILTER_SPENDING_DEMANDE_LIST:
            return{
                ...state,
                filterSpendingDemandsList: action.payload
            }
        case spendingActionTypes.SET_SINGLE_SPENDING_TO_MODIFY:
            return {
                ...state, 
                singleSpendingToModify: action.payload
            }
        case spendingActionTypes.SET_IS_LOADING_SPENDING_DEMAND_LIST:
            return {
                ...state,
                isLoadingSpendingDemandsList: action.payload
            }
        case spendingActionTypes.SET_SPENDING_DEMAND_LIST:
            return {
                ...state,
                listSpendingDemands: action.payload
            }
        case spendingActionTypes.SET_SINGLE_SPENDING:
            return {
                ...state,
                singleSpending: action.payload
            }
        case spendingActionTypes.SET_IS_LOADING_SINGLE_SPENDING:
            return {
                ...state,
                isLoadingSingleSpending: action.payload
            }
        case spendingActionTypes.SET_IS_LOADING_SPENDING_DEMAND_LIST_ADMIN:
            return {
                ...state,
                isLoadingSpendingDemandListAdmin: action.payload
            }
        case spendingActionTypes.SET_SPENDING_DEMAND_LIST_ADMIN:
            return {
                ...state,
                listSpendingDemandsAdmin: action.payload
            }
        default:
            return state
    }
}
export default spendingReducer
