/*================================================================
 *                SingleTeamHeaderTitle
 *================================================================*/

import React from "react";
import TeamHeaderTitleView from "./TeamHeaderTitleView";
import { useSingleTeam } from "./../../../../team/hooks/index";

import { dateConverter } from "../../../../../utils/dateConverter";
import { getTeamLabelOfTeamRole } from "../../../utils/team.utils";

export default function SingleTeamHeaderTitlePresenter() {
  const { isLoading, singleTeamData } = useSingleTeam();

  const createAt = singleTeamData?.team_creation_date
    ? dateConverter(singleTeamData?.team_creation_date)
    : undefined;

  const updatedAt = singleTeamData?.team_update_date
    ? dateConverter(singleTeamData?.team_update_date)
    : undefined;

    console.log(singleTeamData?.team_role)
  return (
    <div>
      <TeamHeaderTitleView
        title={isLoading ? "..." : singleTeamData?.team_name || "Erreur de chargement"}
        description={singleTeamData?.team_description}
        createdAt={createAt}
        updatedAt={updatedAt}
        membersCount={singleTeamData?.team_members_count}
        projectName={singleTeamData?.team_project?.project_name}
        role={getTeamLabelOfTeamRole(singleTeamData?.team_role) }
      />
    </div>
  );
}
