
import styles from "./singleTeamPage.module.scss";
import SingleTeam from "./../../../features/team/components/SingleTeam/";
import ReturnBackBtn from "../../../components/atoms/ReturnBackBtn/ReturnBackBtn";
import { useSingleTeam } from "../../../features/team/hooks";
import Loader from "../../../components/atoms/Loader/Loader";
import GenericGoBackBtn from "../../../components/organismes/GenericGoBackBtn/GenericGoBackBtn";

function SingleTeamPage() {

  const {isLoading} =useSingleTeam();

  if(isLoading){
    return <div className={styles['loader-screen']}><Loader/></div>
  }
  return (
    <div className={styles["single-team"]}>
      <div className={styles["single-team__return-btn"]}><GenericGoBackBtn/></div>
          <SingleTeam.ModifyTeamBtn />
      <div className={styles["single-team__team-header"]}>
        <SingleTeam.TeamHeaderTitle />
      </div>
      <div className={styles["body"]}>
        <div className={styles["body-left-container"]}>
            
            <div className={styles["body-left-container__member-list"]}>
                <SingleTeam.MembersList TitleComponent={({nbMember}) => <h2 className={[styles["body-left-container__title"], styles["title"]].join(" ")}>Membre{nbMember && nbMember > 1 && 's'} ({nbMember})</h2> } />
            </div>

        </div>
        <div className={styles["body-right-container"]}>
          <div className={styles["manager-bloc"]}>

            <div className={styles["manager-bloc__manager"]}>
              <SingleTeam.ManagerInfoCard TitleComponent={() => <h2 className={[styles["manager-bloc__title"],styles["title"]].join(" ")}>Manager</h2> }/>
            </div>
          </div>
          <div className={styles["project-bloc"]}>

            <div className={styles["project-bloc__project"]}>
                <SingleTeam.ProjectInfoCard 
                TitleComponent={() => <h2 className={[styles["project-bloc__project__title"], styles["title"]].join(" ")}>Projet</h2>}
                />
            </div>
          </div>
        </div>

      </div>
        <div className={styles["body-footer"]}>
            <div className={styles["spending-demand-list-bloc"]}>
                <h2 className={[styles["spending-demand-list-bloc__title"], styles["title"]].join(" ")}>Demandes de Paiement</h2>
                <div className={styles["spending-demand-list-bloc__spending-demand-list"]}>
                    <SingleTeam.SpendingDemandList />
                </div>
            </div>

        </div>
    
    </div>
  );
}

export default SingleTeamPage;
