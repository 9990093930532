import { Create } from '@mui/icons-material'
import React from 'react'

import CreateTeamFormPresenter from '../../CreateTeam/CreateTeamForm/CreateTeamFormPresenter'
import { useSingleTeam } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import useUserAuth from '../../../../auth/hook/useUserAuth'
import { chechUserIsAuthorizedTo } from '../../../utils/team.utils'

export default function UpdateTeamFormPresenter() {

  const {singleTeamData,isLoading, memberList } = useSingleTeam()
    //TODO: Get the team to update
    const navigate = useNavigate()


    if (isLoading) return <div>Chargement...</div>

    if (!singleTeamData) return <div>Erreur lors du chargement de l'équipe</div>
    
    const callbackUpdate = ({result, error} : {result?: any, error?: any}) => {
      if(result){
        navigate(-1)
        //navigate('/teams/'+singleTeamData.team_id)
      }
    }
    
  return (
    <CreateTeamFormPresenter initData={{
      teamName: singleTeamData.team_name,
      teamDescription: singleTeamData.team_description,
      manager: singleTeamData.team_manager,
      project: singleTeamData.team_project,
      role: singleTeamData.team_role,
      membersList: memberList.membersListData?.data || []
    }} idTeamToUpdate={singleTeamData.team_id}
    callbackUpdate={callbackUpdate}
    />
  )
}
