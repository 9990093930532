/*===================================================================
=       USER AUTH HOOK                             =
====================================================================*/
/**
 * Manage the user data, sessions and authentication.
 */

import { useDispatch, useSelector } from "react-redux";
import jwt from 'jsonwebtoken';

//SERVICE API
import { getAuthService, resetPasswordService, getUserInfoServiceProtected, getUsersByTokenService } from "../services/api";

//REDUX
import { userAuthMapState } from "../redux/userAuth.mapstate";
import { IUserAuthState } from "../redux/userAuth.reducer";
import { setIsAuthAction, setIsLoadingUserAuthAction, setAccessTokenAction, setUserDataAction, logoutUserAction, setIsEmployee, setEmployeeData } from "../redux/userAuth.actions";

//LIB
import axios, { AxiosError, AxiosResponse } from "axios";

//UTILS
import { inputAuthValidation } from "../utils/validationInputLogin.utils";
import setAuthToken from "../../../utils/setAuthToken";

//TYPE
import { IUser, IUserEmployee } from "../types/user.types";
import useErrorHandler, { internalErrorCodes } from "../../../hook/useErrorHandler";
import { useEffect } from "react";


export interface IConfigInfoModalAuth {
    message?: string | null;
  }
  
  export type InfoModalPresetAuth ={

  } 



/**
 * Manage the user data, sessions and authentication.
 * @returns 
 */
function useUserAuthCore(
    handleNetworkErrorFunction :  ((error: AxiosError<unknown, any>, SreenContext: string | null) => void) | null,
    handleInternErrorFunction : ((errorMessage: string | null, errorCode: number | null) => void) | null,
 ) {
    const dispatch = useDispatch();
    const {userAuth}: {userAuth: IUserAuthState} = useSelector(userAuthMapState);
   
    useEffect(() => {
        if(userAuth.isAuth){
            addTokenIntheHeaderRequest()
        }
    },[userAuth.isAuth])
   const  handleInternError = (errorMessage: string | null, errorCode: number | null) => {
    if (handleInternErrorFunction){
        handleInternErrorFunction(errorMessage, errorCode)
    }
   }

   const handleNetworkError = (error: AxiosError<unknown, any>, SreenContext: string | null)  => {
    if(handleNetworkErrorFunction){
        handleNetworkErrorFunction(error,SreenContext )
    }
   }

    const userImageSrcByDefault= "../assets/profil-default.png"
   

    const addTokenIntheHeaderRequest = () => {
        setAuthToken(userAuth.accessToken);
    }
    /**
     * auth to Server
     * 
     * @param email email of the usera
     * @param password password of the user
     */
    const authentificateTheUser = async  (email: string, password: string, callback?:(({err, result}:{err?: any, result?: any}) => void)) => {
     
        dispatch(setIsLoadingUserAuthAction(true))
        //VALIDATION INPUT
        if(!inputAuthValidation(email, password)) {
            handleInternError(null,internalErrorCodes.PASSWORD_EMAIL_NOT_PROVIDED)
            dispatch(setIsLoadingUserAuthAction(false))
            if(callback){
                callback({err:'input not valid',result: false})
            }
            return false; 
        }
        try{
               const authDataResponse :AxiosResponse <{accessToken: string |null, error: any,username:string, user:IUser|null }> = await getAuthService(email, password);
                if(!authDataResponse?.data?.accessToken) {
                    handleInternError(null,internalErrorCodes.SESSION_ENDED)
                    dispatch(setIsLoadingUserAuthAction(false))
                    if(callback){
                        callback({err:'token not valid',result: false})
                    }
                    return false; 
                }


                if(!authDataResponse?.data?.user){
                    handleInternError('Aucun utilisateur trouvé, veuillez réessayer svp', null)
                    dispatch(setIsLoadingUserAuthAction(false))
                    if(callback){
                        callback({err:'no user found',result: false})
                    }
                    return false;
                }

                dispatch(setAccessTokenAction(authDataResponse.data.accessToken))
                //Add token to the header of all furur requests
                setAuthToken(authDataResponse.data.accessToken);

                //SET USER ROLE
                if(authDataResponse.data.user.user_role === 0 || authDataResponse.data.user.user_role === -1){
                    dispatch(setIsEmployee(true));
                }


                //IF the user have a id employee then the user is an employee
                const userEmployeeId = authDataResponse.data.user.user_employee_id

                if(userEmployeeId){
                    try{
                        const employeeDataResponse: AxiosResponse<IUserEmployee> = await getUserInfoServiceProtected(userEmployeeId)
                        if(employeeDataResponse?.data?.employee_id){
                            const {data: employeeData} = employeeDataResponse
                            dispatch(setIsEmployee(true));
                            dispatch(setEmployeeData(employeeData));
                        }
                } catch(e){
                    const error = e as AxiosError
                    dispatch(setIsEmployee(false));
                    handleNetworkError(error, 'Login')
                }
                }
                //SET USER AS AUTHENTICATED
                dispatch(setIsAuthAction(true))
                dispatch(setUserDataAction(authDataResponse.data.user))
                dispatch(setIsLoadingUserAuthAction(false))
                if(callback){
                    callback({result: true})
                }
                return true
        } catch(e) {
            dispatch(setIsLoadingUserAuthAction(false))
            const error = e as AxiosError
            console.log('auht error')
            handleNetworkError(error, 'Login')
         
        }
        dispatch(setIsLoadingUserAuthAction(false))
        if(callback){
            callback({err:'User not authenticated',result: false})
        }
        return false
        
    }


    /**
     * Start the forgot password process ton the backend
     * @param email email of the user
     */
    const startPasswordFogotProcess = async (email: string ) => {
        dispatch(setIsLoadingUserAuthAction(true))
        //VALIDATION INPUT
        resetPasswordService(email).then(reponse => {
            console.log('I forgot my password successfully')
       
        }).catch(e => {
            const error = e as AxiosError
            handleNetworkError(error, null)
            console.log(error)
         
        })

        dispatch(setIsLoadingUserAuthAction(false))

    }

    /**
     * function to logout the user
     */
    const logoutUser = async () => {
      
        dispatch(logoutUserAction())
        dispatch(setIsLoadingUserAuthAction(false))
    

    }

    const setIsLoading =(isLoading: boolean) =>{
        dispatch(setIsLoadingUserAuthAction(isLoading))
    }

    const getTokenBearer = () => {
        return 'Bearer ' +userAuth.accessToken
    }



    const fetchUserDataByToken =async  () => {
        try {
            const userDataResponse: AxiosResponse<IUser> = await  getUsersByTokenService()
            if(userDataResponse?.data){
                dispatch(setUserDataAction(userDataResponse.data))
            }
        }catch(err){
            const error = err as AxiosError
            dispatch(setIsEmployee(false));
            handleNetworkError(error, 'Login')
        }

    }

    const authenticateUserWithToken = (token: string) => {
        dispatch(setAccessTokenAction(token))
        dispatch(setIsAuthAction(true))
        setAuthToken(token);
        fetchUserDataByToken()
    }

    return {
        /**
         * the Authentication status of the user in the application
         */
        isUserAuthentificated: userAuth.isAuth,
        /**
         * function to authentificate the user
         */
        authentificateTheUser,
        /**
         * functio to start the password fogot process
         */
        startPasswordFogotProcess,
        /**
         * User Data Store
         */
        userAuthStore: userAuth,
        /**
         * function to Logout the user
         */
        logoutUser,
        /**
         * specifies if the user is an employee or not
         */
        isEmployee: userAuth.isEmployee,
        /**
         * Uer info data
         */
        userInfo: userAuth.userData,
        /**
         * profil image of the user
         */
        userImageProfilSrc: userImageSrcByDefault,

        addTokenIntheHeaderRequest,

        setIsLoading,

        getTokenBearer,

        authenticateUserWithToken 

    }
}

export default useUserAuthCore
