/*===================================================================
=       USER AUTH TYPES                                             =
====================================================================*/

/**
 * Dictionary of user auth types.
 */
export const userAuthTypes = {
    SET_IS_AUTH: 'SET_IS_AUTH_ACTION',
    SET_ACCESS_TOKEN: 'SET_TOKEN',
    SET_IS_LOADING_USER_AUTH: 'SET_IS_LOADING_USER_AUTH',
    SET_USER_DATA: 'SET_USER_DATA',
    LOGOUT_USER: 'LOGOUT_USER',
    SET_IS_EMPLOYEE: 'SET_IS_EMPLOYEE',
    SET_EMPLOYEE_DATA: 'SET_EMPLOYEE_DATA',
}