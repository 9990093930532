import React, { useEffect } from 'react'
import useTeamCore from '../../../hooks/core/useTeamCore'
import { SingleTeamListView } from './TeamListView'
import { Link } from 'react-router-dom'
import Pagination from '../../../../../components/organismes/Pagination/Pagination'
import { useTeam } from '../../../hooks'
import EmptyListIcon from '../../../../../components/atoms/EmptyListIcon/EmptyListIcon'
import Loader from '../../../../../components/atoms/Loader/Loader'
import { getTeamLabelOfTeamRole } from '../../../utils/team.utils'


function TeamListPresenter() {
   const {listTeam, isLoadingList, setPage} = useTeam()
   const pagination = listTeam?.pagination


  if(isLoadingList) return <Loader paddingTop='20%' centered/>
  if(!listTeam) return <EmptyListIcon message='Erreur lors du chargement des équipes'/>
  if(listTeam?.data && listTeam.data.length === 0) return <EmptyListIcon message='Aucune équipe trouvée'/>

  return (
    <div style={{width: 'fit-content'}}>{listTeam?.data && listTeam.data.map(team => {
      const {team_project, team_name , team_members_count} = team
      const projectName = team_project?.project_name
    return <SingleTeamListView memberCount={team_members_count} name={team_name} key={team.team_id} projectName={projectName} linkTo={'/teams/'+team.team_id} role={getTeamLabelOfTeamRole(team?.team_role) }/>
      })}
      <div style={{display: 'flex', justifyContent: 'center'}}>
      <Pagination currentPage={pagination?.current_page} lastPage={pagination?.total_pages} setCurrentPage={setPage} />
      </div>
      </div>
  )
}

export default TeamListPresenter