import React, { ChangeEvent, useState } from "react";
import styles from "./ImportBtnView.module.scss";
import iconUpload from './assets/upload.svg'
import iconClose from './assets/cross.svg'
import cloudUpload from './assets/cloud-upload.svg'
import fileIcon from './assets/file.svg'
import Button from "../../../../../../components/molecules/Button/Button";
export interface ImportBtnViewProps {
  handleImport: (i:FilePiked) => void;
  accept?: string;
  fileSelected ? : string | null;
  cleanFilePicked: () => void;
  handleSendCSV?: () => void; 
  isPending ? : boolean;

}

export interface FilePickerBtnViewProps {
  handleImport: (i:FilePiked) => void;
  accept?: string;
  handleClose?: () => void;
  fileSelected ? : string | null;
  handleSendCSV?: () => void; 
  isPending ? : boolean;
  
}

export interface FilePiked  {
  fileName: string , content?: string
}
export default function ImportBtnView({
  handleImport,
  accept,
  fileSelected,
  cleanFilePicked,
  isPending,
  handleSendCSV
}: ImportBtnViewProps) {

  
  const [isShowModal, setShowModal] = useState<boolean>(false)

  const closeModal = () => {setShowModal(false)
    cleanFilePicked()
  }

  if(isPending){
    return <div>Importation en cours...</div>
  }

  return( <>
    {isShowModal &&
    <div className={styles["modal"]}>
      <div className={styles["modal__content"]}>
        <FileUploader accept={accept} handleImport={handleImport} handleClose={closeModal} fileSelected={fileSelected} handleSendCSV={handleSendCSV} isPending={isPending}/>
      </div>
      </div>
}
   


    <Button
    label="Importer"
      style="gray"
      onClick={() => {

        setShowModal(true);
      }}
      IconComponentRight={() =><img  className={styles["button-import-csv__icon"]} src={cloudUpload } alt="upload" />}
      />
    </>)
    }

export function FileUploader({ accept, handleImport, handleClose,  fileSelected, handleSendCSV  }: FilePickerBtnViewProps) {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {

      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target?.result as string;

        handleImport({fileName: file.name, content: content});
      };
      reader.readAsText(file);
    }
  };
  return (
    <div>
      <div className={styles["container-btn-chose-file"]}>

        <div className={styles["file-upload"]}>
          {fileSelected === null?
          <img src={iconUpload} alt="upload" />:
          <img src={fileIcon} alt="file" />
          }
          <span> {fileSelected || 'Choisir un fichier CSV'} </span>
        <input
          className={styles["input-file-item"]}
          accept={accept ? accept : ""}
          type="file"
          onChange={handleFileChange}
        />
        </div>
      </div>
      <button className={styles["container-btn-chose-file__btn-close"]} onClick={handleClose}> <img src={iconClose} alt="close" /></button>
      <button className={[styles["container-btn-chose-file__btn-send" ], fileSelected === null ? styles["container-btn-chose-file__btn-send--disabled"] : styles["container-btn-chose-file__btn-send--active"]].join(" ")} disabled={fileSelected === null} onClick={() => {
        handleSendCSV && handleSendCSV()

        handleClose && handleClose()
      
      }} >Importer</button>

    </div>
  );
}
