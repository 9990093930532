import React, { useState } from "react";
import SearchEmployeeMemberModal from "../SearchEmployeeMemberModal";
import SelectButton from "../../../../components/atoms/SelectButton/SelectButton";
import { Employee } from "../../types/employee.type";

export interface SelectEmployeeListBtnProps {
  titleModal?: string;
  setManagerList?: (manager: Employee[]) => void;
  currentEmployeeList?: Employee[] | null | undefined;
  titleListSelected?: { single: string; plural: string };
  label?: string;
  isLoading?: boolean;
  placeholder?: string;
  withDeleteAllBtn?: boolean
}
export default function SelectEmployeeBtn({
  setManagerList,
  titleModal = "Ajouter des managers",
  currentEmployeeList,
  label,
  isLoading,
  withDeleteAllBtn,

  titleListSelected = {
    single: "Employé Sélectionné",
    plural: "Employés Sélectionnés",
  },
  placeholder= "Tous",
}: SelectEmployeeListBtnProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen && (
        <SearchEmployeeMemberModal
        
          titleListSelected={titleListSelected}
          titleModal={titleModal}
          onClose={handleModalClose}
          setNewMemberList={setManagerList}
          currentMembersList={currentEmployeeList}
          employeeProhibitedList={
            currentEmployeeList?.map((employee) => ({
              employee_id: employee.employee_id,
              description: "Déjà dans la liste",
            })) || []
          }
        />
      )}
      <SelectButton
            deleteAllItems={ withDeleteAllBtn ? () => {   setManagerList &&  setManagerList([])} : undefined}
        label={label}
        getNameItem={(item: Employee) => `${item.employee_firstname} ${item.employee_lastname}`}
        valueList={currentEmployeeList}
        onClick={handleModalOpen}
        isLoading={isLoading}
        placeholder={placeholder} 
      />
    </>
  );
}
