/*===================================================================
=       SPENDING REDUX TYPES                     =
====================================================================*/
/**
 * 
 */

const PREFIX = 'SPENDING_REDUCER_'
export const spendingActionTypes = {
    SET_IS_LOADING_SPENDING_LIST : PREFIX+"SET_LOADING_LIST_SPENDING",
    SET_SPENDING_LIST : PREFIX+"SET_SPENDING_LIST",
    FETCH_LIST_SPENDING_SUCCESS : PREFIX+"FETCH_LIST_SPENDING_SUCCESS",
    SET_IS_LOADING_SPENDING_CATEGORY : PREFIX+"SET_IS_LOADING_SPENDING_CATEGORY",
    SET_SPENDING_CATEGORY_LIST : PREFIX+"SET_SPENDING_CATEGORY_LIST",
    SET_LOADING_SEND_NEW_SPENDING : PREFIX+"SET_LOADING_SEND_NEW_SPENDING",
    SET_NEW_SPENDING_SENDING_PROGRESS_PROGRESS : PREFIX+"SET_NEW_SPENDING_SENDING_PROGRESS_PROGRESS",
    SET_FILTER_SPENDING_DEMANDE_LIST : PREFIX+"SET_FILTER_SPENDING_DEMANDE_LIST",
    SET_SORTER_SPENDING_DEMAND_LIST : PREFIX+"SET_SORTER_SPENDING_DEMAND_LIST",
    SET_SINGLE_SPENDING_TO_MODIFY : PREFIX+"SET_SINGLE_SPENDING_TO_MODIFY",
    SET_IS_LOADING_SPENDING_DEMAND_LIST : PREFIX+"SET_IS_LOADING_SPENDING_DEMAND_LIST",
    SET_SPENDING_DEMAND_LIST : PREFIX+"SET_SPENDING_DEMAND_LIST",
    SET_SINGLE_SPENDING : PREFIX+"SET_SINGLE_SPENDING",
    SET_IS_LOADING_SINGLE_SPENDING : PREFIX+"SET_IS_LOADING_SINGLE_SPENDING",
    SET_IS_LOADING_SPENDING_DEMAND_LIST_ADMIN : PREFIX+"SET_IS_LOADING_SPENDING_DEMAND_LIST_ADMIN",
    SET_SPENDING_DEMAND_LIST_ADMIN : PREFIX+"SET_SPENDING_DEMAND_LIST_ADMIN",
}