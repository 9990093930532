import React from 'react'
import BigBadgeImage from '../../../../../../components/molecules/BigBadgeImage/BigBadgeImage'
import './managerInfoCardView.scss'
import avatar from './../../../../assets/avatar-img.svg'
export interface ManagerCardProps {

    firstName?: string;
    lastName?: string;
    imageUrl?: string;
}
function ManagerInfoCardView({firstName, lastName}: ManagerCardProps) {
  return (
    <div className='manager-card'>
    <BigBadgeImage title={firstName} subTitle={lastName} imgSrc={avatar} />
    </div>
  )
}

export default ManagerInfoCardView