import React, { useState } from 'react'

export interface LoginFormViewProps {
    handleSubmit: (email: string, password: string) => void;
}
function LoginFormView({handleSubmit}: LoginFormViewProps) {
    const [password, setPassword] = useState<string>("")
    const [email, setEmail] = useState<string>("")
  return (
    <div>
        <label>email</label>
        <input type='text' value={email}onChange={(e: any) => {
            setEmail(e.target.value)
        }}/>
        <label>password</label>
        <input type='text' value={password} onChange={(e:any) => {
            setPassword(e.target.value)
        }}/>
        <button type='submit' onClick={() => {
            console.log('submit')
            handleSubmit(email, password)
        }}>Se connecter</button>
    </div>
  )
}

export default LoginFormView