/*===================================================================
=     SPENDING ACTION               =
====================================================================*/
/**
 * Spending action
 */

import { spendingActionTypes } from "./spending.types"

import { Spending, SpendingCategory,  SpendingDemandFrontAdapted, SpendingExtended } from "../types/spending.types"

import { FilterSpendingDemandsList, SorterSpendingDemandsList } from "./spending.reducer"
import { SpendingDemand, SpendingDemandExtended } from "../../spendingDemand/types/spendingDemand.types"


export const setIsLoadingSpendingDemandListAdminAction = (isLoading: boolean) => ({
    type: spendingActionTypes.SET_IS_LOADING_SPENDING_DEMAND_LIST_ADMIN,
    payload: isLoading
})

export const setSpendingDemandListAdminAction = (list: SpendingDemandExtended[] | null) => ({
    type: spendingActionTypes.SET_SPENDING_DEMAND_LIST_ADMIN,
    payload: list
})

/**
 * Set is loanding value
 * @param isLoading 
 * @returns 
 */
export const setIsLoadingSpendingListAction = (isLoading: boolean) => ({
     type: spendingActionTypes.SET_IS_LOADING_SPENDING_LIST,
     payload: isLoading
})
    


/**
 * 
 * @param listSpending 
 * @returns 
 */
export const setListSpendingAction = (listSpending: Spending[] | null) => ({
    type: spendingActionTypes.SET_SPENDING_LIST,
    payload: listSpending
})

/**
 * 
 * @param isLoading 
 * @returns 
 */
export const  isLoadingCategoriesListAction = (isLoading: boolean) => ({
    type: spendingActionTypes.SET_IS_LOADING_SPENDING_CATEGORY,
    payload: isLoading
})

/**
 * 
 * @param spendingCategoriesList 
 * @returns 
 */
export const setSpendingCategoriesListAction = (spendingCategoriesList: SpendingCategory[] | null) => ({
    type: spendingActionTypes.SET_SPENDING_CATEGORY_LIST,
    payload: spendingCategoriesList
})

/**
 * 
 * @param isLoading 
 * @returns 
 */
export const setIsLoadingSendNewSpendingAction = (isLoading: boolean) => ({
    type: spendingActionTypes.SET_LOADING_SEND_NEW_SPENDING,
    payload: isLoading
})

/**
 * 
 * @param pourcentageProgress 
 * @returns 
 */
export const setNewSpendingSendingProgressPoncentageAction = (pourcentageProgress: number) => ({
    type: spendingActionTypes.SET_NEW_SPENDING_SENDING_PROGRESS_PROGRESS,
    payload: pourcentageProgress
})

export const setSorterSpendingDemandListAction= (sorter: SorterSpendingDemandsList | null) => ({
    type: spendingActionTypes.SET_SORTER_SPENDING_DEMAND_LIST,
    payload: sorter
})

export const setFilterSpendingDemandListAction= (filter: FilterSpendingDemandsList | null) => ({
    type: spendingActionTypes.SET_FILTER_SPENDING_DEMANDE_LIST,
    payload: filter
})

export const setSingleSpendingToModifyAction = (spending: SpendingDemandFrontAdapted | Spending | null) => ({
    type: spendingActionTypes.SET_SINGLE_SPENDING_TO_MODIFY,
    payload: spending
})

export const setSpendingDemandList = (list: SpendingDemand[] | null) => ({
    type: spendingActionTypes.SET_SPENDING_DEMAND_LIST,
    payload: list
})

export const setIsLoadingSpendingDemandListAction = (isLoading: boolean) => ({
    type: spendingActionTypes.SET_IS_LOADING_SPENDING_DEMAND_LIST,
    payload: isLoading
})

export const setIsLoadingSingleSpendingAction = (isLoading: boolean) => ({
    type: spendingActionTypes.SET_IS_LOADING_SINGLE_SPENDING,
    payload: isLoading
})

export const setSingleSpendingAction = (spending: SpendingExtended | null) => ({
    type: spendingActionTypes.SET_SINGLE_SPENDING,
    payload: spending
})