import useUserAuth from "../../../../auth/hook/useUserAuth";
import useSpendingDemandFilter from "../../../hooks/useSpendingDemandFilter";
import { FilterSpendingDemand } from "../../../types/spendingDemand.types";
import { spendingDemandStatusRawToReadableStatus } from "../../../utils/spenpdingDemandStatusConverter";
import SpendingDemandListFilterView, {
  StatusItem,
} from "./SpendingDemandListFilterView/SpendingDemandListFilterView";

export default function SpendingDemandListFilterPresenter() {
  const { filter, setFilter } = useSpendingDemandFilter();
  const { userInfo } = useUserAuth();

  const statusList: StatusItem[] = [
    {
      value: "WAITING_VALIDATION",
      label: "En attente de traitement / À traiter",
    },


    {
      value: "VALIDATE",
      label: "Validé",
    },
    {
      value: "SAVED",
      label: "Sauvegardé",
    },
    {
      value: "WAITING_UPDATE",
      label: "En attente de correction",
    },
    {
      value: "REFUSED",
      label: "Refusé",
    },
  ];

  const convertFilter = (filter:FilterSpendingDemand ) => {

    const result = filter

    if (filter.status && filter.status?.includes('WAITING_VALIDATION')){

      filter.status = ['WAITING_VALIDATION']
      result.managerWaitingValidation = [userInfo?.user_employee_id || ""]
    }

    return result

  }

  const handleClickStatus = (valueCliked: string) => {
    if (valueCliked) {
      const currentStatus = filter?.status || [];

      

      if (filter?.status?.includes(valueCliked)) {
        setFilter(convertFilter({
          ...filter,
          status: currentStatus.filter((status) => status !== valueCliked),
        }));
      } else {
        setFilter( convertFilter({ ...filter, status: [ valueCliked] }));
      }
    }
  };

  return (
    <SpendingDemandListFilterView
      handleClickStatus={handleClickStatus}
      statusList={statusList}
      currentStatusSelected={
        filter?.status || []
      }

      
    />
  );
}
