import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SideBar, { SideButton } from "../../components/organismes/SideBar/SideBar";

import useUserAuth from "../../features/auth/hook/useUserAuth";

import homeRoutes from "../../navigation/homeRoutes";
import { useLayoutContext } from "../../utils/LayoutContext/LayoutContext";


const routesList = homeRoutes

const sideBarButtonList : SideButton[] = routesList.reduce<SideButton[]>((listSideBarBtn,route) =>{
  if(route.isInSideBar){
    return [...listSideBarBtn,
      {
        path: route.path,
        name: route.name
      }
    ]
  }else{
    return listSideBarBtn
  }

},[])

export const Home = () => {

  let hidesidebar  = useLayoutContext().isNoSideBar;

  const {authenticateByURL, userInfo} =useUserAuth()
  useEffect(() => {
      authenticateByURL();
  }, [])


  return (
    <>
      <div style={{ display: "flex", height: "100vh" }}>
      {!hidesidebar &&

    
        <div
          style={{
            padding: "10px",
            width: "100px",
            background: "#f0f0f0",
            marginRight: 15
          }}
        >

          <SideBar sideButtonList={sideBarButtonList} />

        </div>
      }
        <div style={{ flex: 1, height: "100%"}}>
          
  
            <Routes>
              {routesList.map((route, index) =>{
                return (
                <Route key={index} path={route.path} element={<route.main />} />)
}
)}
            </Routes>
          
        </div>
      </div>
    
    </>
  );
};

export default Home;
