/*===================================================================
=       USE SPENDING HOOK                      =
====================================================================*/
/**
 * Hook use spending
 * Manage all spending information of the user
 */

import { useDispatch, useSelector } from "react-redux";
import {
  setListSpendingAction,
  setIsLoadingSpendingListAction,
  setSpendingCategoriesListAction,
  isLoadingCategoriesListAction,
  setIsLoadingSendNewSpendingAction,
  setSorterSpendingDemandListAction,
  setFilterSpendingDemandListAction,
  setSingleSpendingToModifyAction,
  setIsLoadingSpendingDemandListAction,
  setSpendingDemandList,
  setIsLoadingSingleSpendingAction,
  setSingleSpendingAction,
  setIsLoadingSpendingDemandListAdminAction,
  setSpendingDemandListAdminAction,
} from "../../redux/spending.actions";
import {
  filtredSpendingDemandMapState,
  filtredSpendingListMapState,
  rawSpendingDemandListStateMapState,
  rawSpendingListStateMapState,
  spendingMapState,
} from "../../redux/spending.mapstate";

//HOOK

import {
  FileReq,
  FileToSend,
  Spending,
  SpendingCategory,
 
  SpendingDemandFrontAdapted,
  SpendingDemandStatus,
  SpendingExtended,
  SpendingReq,
  SpendingReqModify,
} from "../../types/spending.types";
import { AxiosError, AxiosResponse } from "axios";
import {
  AddSpendingAPIProtected,
  AddSpendingDemandAPIProtected,
  fecthSpendingDemandsListAdminAPIProtected,
  fecthSpendingDemandsListUserAPIProtected,
  fecthSpendingListAPIProtected,
  fetchSpendingCategoriesListAPIProtected,
  getSingleSpendingDemandProtectedAPI,
  getSingleSpendingProtectedAPI,
  patchSpendingAPIProtected,
  patchSpendingDemandAPIProtected,
  uploadDocumentAPIProtected,
} from "../../services/SpendingAPI";


import { getDataURLFromUrlFile } from "../../../../utils/fileReader";
import {
  SorterSpendingDemandsList,
} from "../../redux/spending.reducer";
import { adaptSpendingDemandExtendedToSpendingExtended, adaptSpendingDemandToSpending, adaptSpendingReqModifyToSpendingDemandReq, adaptSpendingReqModifyToSpendingDemandReqModify } from "../../utils/adaptater";
import { SpendingDemand, SpendingDemandExtended } from "../../../spendingDemand/types/spendingDemand.types";

export interface IConfigInfoModalSpending {
  message?: string | null;
}

export type InfoModalPresetSpending ={
 NEW_SPENDING_DEMAND_SENDED : IConfigInfoModalSpending
 IS_SENDING_LOADER : IConfigInfoModalSpending
} 

/**
 * Hook use spending
 * Manage all spending information of the user
 * @returns
 */
const useSpendingCore = (displayInfoModal:
  ((config:any) => void) | null,
  handleNetworkError :  ((error: AxiosError<unknown, any>, SreenContext: string | null) => void) | null,
  infoModalConfigPreset: InfoModalPresetSpending | null,
  addTokenIntheHeaderRequest : (() => void) | null
  ) => {
  const { spending: spendingStore } = useSelector(spendingMapState);

 
  const dispatch = useDispatch();



  const spendingListSortedFiltred = useSelector(filtredSpendingListMapState);
  const spendingListRaw = useSelector(rawSpendingListStateMapState);


  const spendingDemandListSortedFiltred = useSelector(filtredSpendingDemandMapState);
  const spendingDemandRaw = useSelector(rawSpendingDemandListStateMapState)



  /**
   * Fetch and store Spending List
   */
  const fetchAndStoreSpendingList = async (callback?: (({err, result}:{err?: any, result?: any})=> void), noProcessIsLoading?: boolean) => {
    if(addTokenIntheHeaderRequest){
      addTokenIntheHeaderRequest();
    }

    try {
      const listSpendingResponse: AxiosResponse<Spending[]> =
        await fecthSpendingListAPIProtected();
      if (listSpendingResponse?.data) {
        if(!noProcessIsLoading){
          dispatch(setIsLoadingSpendingListAction(true));
        }
        dispatch(setListSpendingAction(listSpendingResponse.data));
        dispatch(setIsLoadingSpendingListAction(false));
        if(callback){
          callback({result: listSpendingResponse.data})
        }
      }else{
        if(callback){
          callback({err: 'no data'})
        }
      }


    } catch (e) {
      const error = e as AxiosError;
      if(handleNetworkError){
        handleNetworkError(error, null);
      }else{
        console.log(error)
      }

      if(callback){
        callback({err: error})
      }
    }
    dispatch(setIsLoadingSpendingListAction(false));
  };


  //TODO : Get the list of spending demand admin 
  /**
   * Fetch and store Spending demand list Admin
   * @param callback 
   * @param noProcessIsLoading 
   */
  const fetchAndStoreSpendingDemandListAdmin = async (callback?: (({err, result}:{err?: any, result?: any})=> void), noProcessIsLoading?: boolean) => {
    if(!noProcessIsLoading){
      dispatch(setIsLoadingSpendingDemandListAdminAction(true))
    }

    try {
      const result: AxiosResponse<SpendingDemandExtended[]| null> =  await fecthSpendingDemandsListAdminAPIProtected();
      const spendingDemandList = result.data

      dispatch(setSpendingDemandListAdminAction(spendingDemandList))
      dispatch(setIsLoadingSpendingDemandListAdminAction(true))

      if(callback){
        callback({result: spendingDemandList})
      }

  }catch(e){
    const error = e as AxiosError;
    if(handleNetworkError){
      handleNetworkError(error, null);
    }else{
      console.log(error)
    }

    if(callback){
      callback({err: error})
    }
  }
}

  const fetchAndStoreSpendingDemandList = async (callback?: (({err, result}:{err?: any, result?: any})=> void), noProcessIsLoading?: boolean) => {
    const { filterSpendingDemandsList, isLoadingSpendingDemandsList } = spendingStore;

    if(!noProcessIsLoading){
      dispatch(setIsLoadingSpendingDemandListAction(true))
    }

    try {
      const result: AxiosResponse<SpendingDemand[]| null> =  await fecthSpendingDemandsListUserAPIProtected();
      const spendingDemandList = result.data

      dispatch(setSpendingDemandList(spendingDemandList))
      dispatch(setIsLoadingSpendingDemandListAction(false))


      if(callback){
        callback({result: spendingDemandList})
      }
    }catch(e){
      const error = e as AxiosError;
      if(handleNetworkError){
        handleNetworkError(error, null);
      }else{
        console.log(error)
      }
      dispatch(setIsLoadingSpendingDemandListAction(false))
      if(callback){
        callback({err: error})
      }

    }
    dispatch(setIsLoadingSpendingDemandListAction(false))

  }

  /**
   * Add New Speending Demands
   */
  const AddNewSpendingDemands = async (SpendingDemande: SpendingReq) => {
    if(addTokenIntheHeaderRequest){
      addTokenIntheHeaderRequest();
    }


    //VERIFICATION OF THE VALUE FORM

    try {
      const result: AxiosResponse<any> = await AddSpendingAPIProtected(
        SpendingDemande
      );
    } catch (e) {
      const error = e as AxiosError;
      if(handleNetworkError){
        handleNetworkError(error, null);
      }else{
        console.log(error)
      }
    }
  };

  /**
   * fetchAndStoreSpendingCategories
   * Fetch and store the Spending
   */
  const fetchAndStoreSpendingCategories = async () => {
    try {
      dispatch(isLoadingCategoriesListAction(true));
      if(addTokenIntheHeaderRequest){
        addTokenIntheHeaderRequest();
      }


      const spendingCategoriesListReponse: AxiosResponse<SpendingCategory[]> =
        await fetchSpendingCategoriesListAPIProtected();

      dispatch(
        setSpendingCategoriesListAction(spendingCategoriesListReponse.data)
      );
      dispatch(isLoadingCategoriesListAction(false));

      return spendingCategoriesListReponse.data;
    } catch (e) {
      const error = e as AxiosError;

      if(handleNetworkError){
        handleNetworkError(error, null);
      }else{
        console.log(error)
      }
      dispatch(isLoadingCategoriesListAction(false));
    }
    dispatch(isLoadingCategoriesListAction(false));
    return null;
  };

  /**
   * Add a new spending
   * @param newSpending
   */
  const addSendNewSpending = async (
    newSpending: SpendingReq,
    proofFilesList: FileToSend[] | null,
    sucessCallBack: (() => void) | null,
    isDemand?: boolean
  ) => {
    try {
      dispatch(setIsLoadingSendNewSpendingAction(true));
      if(addTokenIntheHeaderRequest){
        addTokenIntheHeaderRequest();
      }

      if(displayInfoModal && infoModalConfigPreset){
        displayInfoModal(infoModalConfigPreset.IS_SENDING_LOADER);
      }

      const files: FileReq[] = [];

      if (proofFilesList && proofFilesList.length > 0) {
        for (let i = 0; i < proofFilesList.length; i++) {
          const fileItem = proofFilesList[i];
          const dataURL: any = await getDataURLFromUrlFile(fileItem.uri);
          files.push({
            data_uri: dataURL,
            filetype: fileItem.mimeType || "",
            filename: fileItem.name,
          });
        }
      }

      const newSpendingReq = { ...newSpending, spending_files: files };

      if(!isDemand){
        await AddSpendingAPIProtected(
          newSpendingReq,
          (progress: number) => {
            if(displayInfoModal && infoModalConfigPreset){
              displayInfoModal({
                ...infoModalConfigPreset.IS_SENDING_LOADER,
                message: Math.round(progress) + "%",
              });
            }

          },
        );
  
      }else{
        await AddSpendingDemandAPIProtected(
          adaptSpendingReqModifyToSpendingDemandReq(newSpendingReq),
          (progress: number) => {
            if(displayInfoModal && infoModalConfigPreset){
              displayInfoModal({
                ...infoModalConfigPreset.IS_SENDING_LOADER,
                message: Math.round(progress) + "%",
              });
            }
          },
        );
      }

      dispatch(setIsLoadingSendNewSpendingAction(false));
      if(displayInfoModal && infoModalConfigPreset){
        displayInfoModal({
          ...infoModalConfigPreset.IS_SENDING_LOADER,
          message: 100 + "%",
        });
      }

      //refresh spending list
      fetchAndStoreSpendingList();
      if(displayInfoModal && infoModalConfigPreset){
      //Validation modale
        displayInfoModal(infoModalConfigPreset.NEW_SPENDING_DEMAND_SENDED);
      }

      //Success
      if (sucessCallBack) {
        sucessCallBack();
      }
    } catch (e) {
      const error = e as AxiosError;
      if(handleNetworkError){
        handleNetworkError(error, null);
      }else{
        console.log(error)
      }
   
      dispatch(setIsLoadingSendNewSpendingAction(false));
    }
  };


    /**
   * Add a new spending
   * @param newSpending
   */
    const sendModificationNewSpending = async (
      newSpending: SpendingReqModify,
      proofFilesList: FileToSend[] | null,
      sucessCallBack: (() => void) | null,
      spendingId: string,
      isDemand?: boolean
    ) => {
      try {
        dispatch(setIsLoadingSendNewSpendingAction(true));
        if(addTokenIntheHeaderRequest){
          addTokenIntheHeaderRequest();
        }

        if(displayInfoModal && infoModalConfigPreset){
          displayInfoModal(infoModalConfigPreset.IS_SENDING_LOADER);
        }

        const files: FileReq[] = [];
  
        if (proofFilesList && proofFilesList.length > 0) {
          for (let i = 0; i < proofFilesList.length; i++) {
            const fileItem = proofFilesList[i];
            const dataURL: any = await getDataURLFromUrlFile(fileItem.uri);
            files.push({
              data_uri: dataURL,
              filetype: fileItem.mimeType || "",
              filename: fileItem.name,
            });
          }
        }
  
        const newSpendingReq : SpendingReqModify = { ...newSpending, spending_files_update: files };
  
        

        if(!isDemand){
        
          await patchSpendingAPIProtected(
            newSpendingReq,
            spendingId,
            (progress: number) => {
              if(displayInfoModal && infoModalConfigPreset){
                displayInfoModal({
                  ...infoModalConfigPreset.IS_SENDING_LOADER,
                  message: Math.round(progress) + "%",
                });
              }

            }
          );
        }else{
          await patchSpendingDemandAPIProtected(
            adaptSpendingReqModifyToSpendingDemandReqModify(newSpendingReq),
            spendingId,
            (progress: number) => {
              if(displayInfoModal && infoModalConfigPreset){
                displayInfoModal({
                  ...infoModalConfigPreset.IS_SENDING_LOADER,
                  message: Math.round(progress) + "%",
                });
            }
            }
          );
        }

  
        dispatch(setIsLoadingSendNewSpendingAction(false));
        if(displayInfoModal && infoModalConfigPreset){
        displayInfoModal({
          ...infoModalConfigPreset.IS_SENDING_LOADER,
          message: 100 + "%",
        });
      }
  
        //refresh spending list
        fetchAndStoreSpendingList();
  
        //Validation modale
        if(displayInfoModal && infoModalConfigPreset){
        displayInfoModal(infoModalConfigPreset.NEW_SPENDING_DEMAND_SENDED);
        }
        //Success
        if (sucessCallBack) {
          sucessCallBack();
        }
      } catch (e) {
        const error = e as AxiosError;
        if(handleNetworkError){
          handleNetworkError(error, null);
        }else{
          console.log(error)
        }

        dispatch(setIsLoadingSendNewSpendingAction(false));
      }
    };

  const addStatusOnFilterSpendingDemands = (
    status: SpendingDemandStatus,
    isOnly?: boolean
  ) => {
    const { filterSpendingDemandsList } = spendingStore;

    if (!filterSpendingDemandsList) {
      dispatch(setFilterSpendingDemandListAction({ status: [status] }));
      return;
    }

    if (!filterSpendingDemandsList?.status) {
      dispatch(
        setFilterSpendingDemandListAction({
          ...filterSpendingDemandsList,
          status: [status],
        })
      );
      return;
    }

    const currentStatusList = filterSpendingDemandsList.status;
    if (!currentStatusList.includes(status)) {
      const newStatusList = isOnly ? [status] : [...currentStatusList, status];
      dispatch(
        setFilterSpendingDemandListAction({
          ...filterSpendingDemandsList,
          status: newStatusList,
        })
      );
      return;
    }
  };

  const resetStatusFilterSpendingDemandList = () => {
    const { filterSpendingDemandsList } = spendingStore;
    if (!filterSpendingDemandsList?.status) {
      return;
    }

    dispatch(
      setFilterSpendingDemandListAction({
        ...filterSpendingDemandsList,
        status: [],
      })
    );
  };
  const removeStatusOnFilterSpendingDemands = (
    status: SpendingDemandStatus
  ) => {
    const { filterSpendingDemandsList } = spendingStore;
    if (!filterSpendingDemandsList?.status) {
      return;
    }
    const newStatusList = filterSpendingDemandsList.status.filter(
      (statusItem: any) => statusItem !== status
    );

    dispatch(
      setFilterSpendingDemandListAction({
        ...filterSpendingDemandsList,
        status: newStatusList,
      })
    );
  };

  /**
   *
   *
   * @param status if null we check if they are status in filter
   * @returns
   */
  const isStatusOnFilterSpendingDemands = (
    status?: SpendingDemandStatus | null
  ) => {
    const { filterSpendingDemandsList } = spendingStore;

    if (status) {
      if (!filterSpendingDemandsList?.status) {
        return false;
      }
      return filterSpendingDemandsList.status.includes(status);
    } else {
      if (!filterSpendingDemandsList?.status) {
        return false;
      }

      if (filterSpendingDemandsList.status.length <= 0) {
        return false;
      }
      return true;
    }
  };

  const getNumberFilterSpendingDemandListAcitved = () => {
    const { filterSpendingDemandsList } = spendingStore;
    let nbFilterActived = 0;
    if (filterSpendingDemandsList?.status) {
      nbFilterActived =
        nbFilterActived + filterSpendingDemandsList.status.length;
    }
    return nbFilterActived;
  };

  const setSorterSpendingDemandlist = (
    sorter: SorterSpendingDemandsList | null
  ) => {
    dispatch(setSorterSpendingDemandListAction(sorter));
  };

  const setSpendingToModify = (spending: Spending| SpendingDemandFrontAdapted | null) => {
    dispatch(setSingleSpendingToModifyAction(spending))
  }



  /**
   * 
   * @param spendingId Sepnding or Spending demand ID
   * @param isDemand is a spending demand 
   * @param callback function call at the end of the process
   */
  const fetchAndStoreSingleSpendingAndSingleSpendingDemand = async (spendingId: string, isDemand:boolean ,callback?: ({err, result}:{err?: any, result?: SpendingExtended | null} ) => void ) => {
    dispatch(setSingleSpendingAction(null))
    dispatch(setIsLoadingSingleSpendingAction(true))
    try {

      let singleSpendingFound =  null;
      if(isDemand){
        const SingleSpendingDemandResponse = await getSingleSpendingDemandProtectedAPI(spendingId)
        const SingleSpending: SpendingExtended = adaptSpendingDemandExtendedToSpendingExtended(SingleSpendingDemandResponse.data)
        singleSpendingFound = SingleSpending
        dispatch(setSingleSpendingAction(SingleSpending))
      }else{
        const SingleSpendingResponse = await getSingleSpendingProtectedAPI(spendingId)
        const SingleSpending: SpendingExtended = SingleSpendingResponse.data
        singleSpendingFound = SingleSpending
        dispatch(setSingleSpendingAction(SingleSpending))
      }

      if (callback){
        callback({result:singleSpendingFound})
      }
      dispatch(setIsLoadingSingleSpendingAction(false))
    }catch(e){
      const error = e as AxiosError;
      if( handleNetworkError){
        handleNetworkError(error, null);
      }else{
        console.log(error)
      }
   
      if (callback){
        callback({err: error.message})
      }
      dispatch(setIsLoadingSingleSpendingAction(false))
    }
    dispatch(setIsLoadingSingleSpendingAction(false))
  }

  const getSingleSpendingDataStore = () => {

    if(spendingStore.singleSpending && !spendingStore.isLoadingSingleSpending){

      console.warn('singleSpending is null, call the function  [fetchAndStoreSingleSpendingAndSingleSpendingDemand] before to use it ')
    }
    return spendingStore.singleSpending
  }
  return {
    /**
     * Specifies whether the spending list is loading
     */
    isloadingSpendingList: spendingStore.isLoadingSpendingList,
    /**
     * Direct access to the sending Store
     */
    spendingStore,
    /**
     * Add New Speending Demands to Backend
     */
    AddNewSpendingDemands,

    /**
     * Fetch and store spending list
     */
    fetchAndStoreSpendingList,
    /**
     * fetchAndStoreSpendingCategories
     *
     * Fetch and store the Spending
     */
    fetchAndStoreSpendingCategories,

    /**
     * List categories of spending
     */
    spendingCategorieList: spendingStore.spendingCategoriesList,
    /**
     * isLoadingSpendingCatgoryList
     */
    isLoadingSpendingCatgoryList: spendingStore.isLoadingSpendingList,
    /**
     *
     * Add and send new spending
     */
    addSendNewSpending,

    /**
     * sending status-
     */
    isloadingSendingNewSpending: spendingStore.isLoadingSendSpending,

    /**
     * Sending progress when the sending is loading
     */
    newSpendingSendingProgressPourcentage:
      spendingStore.sendingProgressPourcentage,

    spendingListDemand: spendingListSortedFiltred,

    spendingListRaw: spendingListRaw,

    //Filter status
    removeStatusOnFilterSpendingDemands,

    addStatusOnFilterSpendingDemands,

    isStatusOnFilterSpendingDemands,
    getNumberFilterSpendingDemandListAcitved,
    resetStatusFilterSpendingDemandList,
    setSorterSpendingDemandlist,

    // Spending Modification 
    spendingToModify: spendingStore.singleSpendingToModify,
    setSpendingToModify,
    sendModificationNewSpending,

    spendingDemandListSortedFiltred: spendingDemandListSortedFiltred,
    isLoadingSpendingDemandList: spendingStore.isLoadingSpendingDemandsList,
    fetchAndStoreSpendingDemandList,
    spendingDemandRaw,

    //Single Spending
    fetchAndStoreSingleSpendingAndSingleSpendingDemand,
    singleSpending: getSingleSpendingDataStore(),
    isLoadingSingleSpending: spendingStore.isLoadingSingleSpending,

    //Admin 
    fetchAndStoreSpendingDemandListAdmin,
    SpendingDemandListAdmin: spendingStore.listSpendingDemandsAdmin,
  };
};

export default useSpendingCore;
