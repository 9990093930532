import { UserRole, userRolesCode } from "../../auth/types/user.types";
import { TEAM_OPTIONS, TeamRoles } from "../types/team.types";

export const getTeamLabelOfTeamRole = ( roleRaw: TeamRoles | undefined) => {
    const teamRoleList = TEAM_OPTIONS 

    if (!roleRaw){
        return undefined
    }

    let result = undefined

    teamRoleList.forEach(option => {
        if (option.value === roleRaw){
            result = option.label
        }
    });

    return result


}

export type Action = 'create' | 'modify' | 'delete' | 'read'

export const chechUserIsAuthorizedTo = (action: Action, userRole: UserRole| undefined) => {

    if (!userRole){
        return false
    }

    if (action === 'create'){
        if (userRole === userRolesCode.ADMIN_PLUS || userRole === userRolesCode.SUPER_ADMIN){
            return true
        }
    }

    if (action === 'modify'){
        if (userRole === userRolesCode.ADMIN_PLUS || userRole === userRolesCode.SUPER_ADMIN){
            return true
        }
    }

    return false


}