import React from "react";
import drawerIcon from "./assets/drawer-icon.svg";
import styles from "./EmptyListIcon.module.scss";
export interface EmptyListIconProps {
  message?: string;
  marginTop?: string;
}
export default function EmptyListIcon({ message, marginTop = '20%'}: EmptyListIconProps) {
  const styleDynamic= {
    marginTop: marginTop
  }
  return (
    <div style={styleDynamic} className={styles["empty-list-icon"]}>
      <img className={styles["empty-list-icon__icon"]} src={drawerIcon} />
      <p className={styles["empty-list-icon__text"]}>{message}</p>
    </div>
  );
}
