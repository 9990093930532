import React from "react";
import styles from "./ProjectList.module.scss";
import ProjectInfoCardView from "../ProjectInfoCardView/ProjectInfoCardView";
import Loader from "../../../../components/atoms/Loader/Loader";
import CompasIcon from "./assets/compas.svg";
export interface ProjectItem {
  title?: string;
  description?: string;
  onClick?: () => void;
  linkTo?: string;
}
export interface ProjectItemProps {
  projectList: ProjectItem[];
  isLoading?: boolean;
}
export default function ProjectList({
  projectList = [],
  isLoading,
}: ProjectItemProps) {
  if (isLoading)
    return (
      <div className={styles["screen"]}>
        <Loader />
      </div>
    );
  if (!projectList.length) return <div className={styles["screen"]}>
    <img className={styles["screen__icon"]}src={CompasIcon} alt="Compas" />
    <p>Aucun projet trouvé</p>
    </div>;
  return (
    <ul className={styles["project-list"]}>
      {projectList.map((project, index) => (
        <li key={index} className={styles["project-list--item"]}>
          <ProjectInfoCardView
          maxCharDescription={80}
            maxWidth="500px"
            title={project.title}
            description={project.description}
            onClick={project.onClick}
            linkTo={project.linkTo}
          />
        </li>
      ))}
    </ul>
  );
}
