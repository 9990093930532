import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useSpending from '../../../features/spending/hooks/useSpending'
import SingleSpendingDemand from '../../../features/spendingDemand/components/SingleSpendingDemand'
import styles from './SingleSpendingDemandPage.module.scss'
import GenericGoBackBtn from '../../../components/organismes/GenericGoBackBtn/GenericGoBackBtn'

export default function SingleSpendingDemandPage() {
  const {spendingDemandId} = useParams()

  const {fetchAndStoreSingleSpendingAndSingleSpendingDemand } = useSpending()

  useEffect(() => {
    if(spendingDemandId)
     fetchAndStoreSingleSpendingAndSingleSpendingDemand(spendingDemandId, true)
  },[])
  return (
    <div className={[styles['page']].join(" ")}>
      <GenericGoBackBtn />
      <h1  className={[styles['page__title']].join(" ")}>
        Détail de la demande de dépense
      </h1>
      <div>
     <SingleSpendingDemand.GeneralInfo/>
     </div>
     <div>
     <SingleSpendingDemand.AproveDisaproveActionBtn />
     </div>
    </div>
  )
}
