import React from "react";
import styles from "./SearchPickProjectView.module.scss";
import TextInput from "../../../../../components/atoms/TextInput/TextInput";
import ProjectInfoCardView from "../../ProjectInfoCardView/ProjectInfoCardView";
import ProjectList, { ProjectItem } from "../../ProjectList/ProjectList";
import Pagination from "../../../../../components/organismes/Pagination/Pagination";
interface SearchPickProjectViewProps {
  searchValue?: string;
  setSearchValue: (value: string) => void;
  isLoading?: boolean;
  results?:  ProjectItem[];
  nbFound?: number;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  lastPage?: number;
}
export default function SearchPickProjectView({
  searchValue,
  setSearchValue,
  isLoading,
  results = [],
  nbFound,
  currentPage,
  setCurrentPage,
  lastPage,
  
}: SearchPickProjectViewProps) {
  const displayNbFound = ((results && (nbFound)) && (nbFound > 0)) ? true : false;
  return (
    <div className={styles["search-pick"]}>
      <div className={styles["search-pick__search-bar"]}>
        <TextInput
          placeholder="Nom du projet"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </div>
      <div className={styles["search-pick__result-count"]}>
        {  (displayNbFound  && nbFound) &&
          <span>{nbFound} { nbFound > 1 ?'projets trouvés' : 'projet trouvé'}</span>
        }
        </div>
      <div className={styles["search-pick__result"]}>
      <ProjectList projectList={results}  isLoading={isLoading}/>
      <Pagination currentPage={currentPage} setCurrentPage={  setCurrentPage} lastPage={lastPage } nbBtnToDisplay={5} centered/>
      </div>
    </div>
  );
}
