import { ThemeOptions } from "@mui/material";

export const themeMJB : ThemeOptions ={
    palette: {
      primary: {
        light: '#f9e66f',
        main: '#95862d', // couleur principale
        dark: '#95862d',
      },
      secondary: {
        main: '#000000', // couleur secondaire
      },


    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 20, // arrondir les bords
            fontFamily: 'Ubuntu', // changer la police
            label: {
              fontFamily:  'Ubuntu', // changer la police du label
            },
          },
          notchedOutline: {
            '&:hover': {
              borderColor: '#000000', // couleur lors du survol
            },
            '&.Mui-focused': {
              borderColor: '#000000', // couleur lors du focus
            },
          },

        },
      },
    },
  }