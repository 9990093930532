import React, { useEffect, useState } from "react";
import { checkValidity } from "../utils/inputValidityChecker";

export default function useDynamicSearch(
  findSearch: (query: string, callback: () => void) => void, minLenghtQuery: number = 4
) {
  const [readyToSearch, setReadyToSearch] = useState(false);
  const [isLoadingListSuggest, setIsLoadingListSuggest] = useState(false);
  const [refrechedSearch, setRefrechedSearch] = useState(false);

  const [query, setQuery] = useState("");

  const refrechSearch = () => {
    setRefrechedSearch(!refrechedSearch);
  }
  //
  useEffect(() => {
    if (readyToSearch) {
      findSearch(query, () => {
        setIsLoadingListSuggest(false);
      });
    }
  }, [readyToSearch]);

  useEffect(() => {

    findSearch(query, () => {
      setIsLoadingListSuggest(false);
    });
    
  }, [refrechedSearch]);


  const onSetQuery = (query: string) => {
    setQuery(query);

    if (!checkValidity(query, "text-not-empty")) {
      return;
    }

    if (String(query).length >= minLenghtQuery) {
      setReadyToSearch(false);
      setTimeout(() => {
        setReadyToSearch(true);
      }, 1000);
    }

    setIsLoadingListSuggest(true);
    if (readyToSearch) {
      setReadyToSearch(false);

      setTimeout(() => {
        setReadyToSearch(true);
        setIsLoadingListSuggest(false);
      }, 1000);
    }
  };

  return  [
    query,
    onSetQuery,
    isLoadingListSuggest,
    refrechSearch
  ] as [
    string, 
    (value: string) => void,
    boolean,
    /* Refrech */
    () => void
];
}
