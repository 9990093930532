
import { useLocation } from "react-router-dom";
import useErrorHandler from "../../../hook/useErrorHandler";
import useUserAuthCore from "./useUserAuthCore";

/**
 * Manage the user data, sessions and authentication.
 * @returns 
 */
function useUserAuth() {
    const {handleNetworkError, handleInternError } = useErrorHandler()
    const location : string = useLocation().search;

    const {authenticateUserWithToken, ...otherFunctions} = useUserAuthCore(handleNetworkError,handleInternError)
    /**
     * Autentication with url Token
     */
    const authenticateByURL = () => {
        let  token  = new URLSearchParams(location).get('t');
        if(token){
            console.log('t= '+token)
            authenticateUserWithToken(token);
 
        }

  
    }

    return {
        authenticateUserWithToken,
        ...otherFunctions,
        /**
         * Autentication with url Token
         */
        authenticateByURL
    }
}

export default useUserAuth
