import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import teamAPI, { FetchTeamFilterOption } from '../../services/teamAPI'

import { handlerErrorFunctions } from '../../../../utils/errorHlandlerUtils'

import { TeamPatch } from '../../types/team.types'

import { useDispatch, useSelector } from 'react-redux'

import { teamMapState } from '../../redux/team.mapstate'

import { setCurrentPageAction, setTeamListAction } from '../../redux/team.actions'

const QUERY_KEY_TEAM = "teams"

export type CallbackFunction = (result:{result?: any, error?:any}) => void
export default function useTeamCore(
    handleNetworkErrorFunction :  ((error: AxiosError<unknown, any>, SreenContext: string | null) => void) | null,
    handleInternErrorFunction : ((errorMessage: string | null, errorCode: number | null) => void) | null,
    getCurrentPage : (() => number | null) | null,
    goToPage : ((page: number) => void) | null,
    setFilterListTeam : ((filter: FetchTeamFilterOption,  pageCurrent?: number) => void) | null,
    getFilterListTeam : (() => FetchTeamFilterOption) | null
 ) {

    const queryClient = useQueryClient()
    const [curPage, setCurPage] = useState(1)
    
    const currentPage = getCurrentPage ? getCurrentPage() : null
    const filterURL = getFilterListTeam ? getFilterListTeam() : null
    const dispatch = useDispatch()
    const {team:{currentPage: page, teamList}} = useSelector(teamMapState)

    const listTeamQuery= useQuery({
        queryFn: () => teamAPI.getFetchTeamAPI(filterURL || {}, page),
        queryKey: [QUERY_KEY_TEAM, {curPage, filterURL}],
    })
    
    const setPage = (page: number) => {
        dispatch(setCurrentPageAction(page))
        setCurPage(page)
        goToPage && goToPage(page)
    }
    
    useEffect(() => {
        if(currentPage){
            setCurPage(currentPage)
            setPage(Number(currentPage))
        }
    },[currentPage])

    
    useEffect(() => {

        dispatch(setTeamListAction(listTeamQuery.data || null))

    }
  
    ,[listTeamQuery.data])


    useEffect(() => {
        if(listTeamQuery.isError){
            const error = listTeamQuery.error as AxiosError
            handleNetworkError(error as AxiosError, "teamList")
        }
    },[listTeamQuery.isError])
    
    const  {handleNetworkError} = handlerErrorFunctions( handleNetworkErrorFunction,handleInternErrorFunction )

    const importCSVTeam = useMutation<any,any, {csvData: string, callback?:CallbackFunction}>(
        {
            mutationFn: async ({csvData, callback}) => {
                try{
                const result = await teamAPI.importTeamByCSV(csvData)
                callback && callback({result})

                }catch(e){
                    const error = e as AxiosError
                    callback && callback({error: e})
                    handleNetworkError(error, "teamList")
                }
            },
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: ['teams']})
            }
        }
    )

    const createNewTeam = useMutation<any,any, {newTeam: TeamPatch, callback?:CallbackFunction}>(
        {
            mutationFn: async ({newTeam, callback}) => {
                try{
                const result = await teamAPI.createNewTeam(newTeam)
                queryClient.invalidateQueries({ queryKey: ['teams']})
                callback && callback({result})

                }catch(e){
                    const error = e as AxiosError
                    callback && callback({error: e})
                    handleNetworkError(error, "teamList")
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['teams']})
                
            }
        }
    )
    const updateTeam = useMutation<any,any, {idTeam: string, teamUpdated: TeamPatch, callback?:CallbackFunction}>(
        {
            mutationFn: async ({teamUpdated, idTeam, callback}) => {
                try{
                const result = await teamAPI.updateTeam(idTeam,teamUpdated)
                queryClient.invalidateQueries({ queryKey: ['teams']})
                callback && callback({result})

                }catch(e){
                    const error = e as AxiosError
                    callback && callback({error: e})
                    handleNetworkError(error, "teamList")
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['teams']})
                
            }
        }
    )

    const deleteTeam = useMutation<any,any, {idTeam: string,  callback?:CallbackFunction}>(
        {
            mutationFn: async ({idTeam, callback}) => {
                try{
                const result = await teamAPI.deleteTeam(idTeam)
                queryClient.invalidateQueries({ queryKey: ['teams']})
                callback && callback({result: true})

                }catch(e){
                    const error = e as AxiosError
                    callback && callback({error: e})
                    handleNetworkError(error, "teamList")
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['teams']})
                
            }
        }
    )
    
    const setFilter = (filter: FetchTeamFilterOption,  pageCurrent?: number) => {
        (setFilterListTeam && setFilterListTeam(filter, pageCurrent))
    }
    return {
        listTeam :teamList,
        setFilterListTeam :setFilter ,
        getFilterListTeam,
        isLoadingList: listTeamQuery.isLoading,
        listTeamQuery, 
        importCSVTeam: {
            run : importCSVTeam.mutate,
            isLoading: importCSVTeam.isPending,
            isSuccess: importCSVTeam.isSuccess,
            isError: importCSVTeam.isError,
        },

        createNewTeam: {
            run : createNewTeam.mutate,
            isLoading: createNewTeam.isPending,
            isSuccess: createNewTeam.isSuccess,
            isError: createNewTeam.isError,
        },

        updateTeam:{
            run : updateTeam.mutate,
            isLoading: updateTeam.isPending,
            isSuccess: updateTeam.isSuccess,
            isError: updateTeam.isError,
        },
        
        deleteTeam: {
            run : deleteTeam.mutate,
            isLoading: deleteTeam.isPending,
            isSuccess: deleteTeam.isSuccess,
            isError: deleteTeam.isError,
        },

        setPage,
        currentPage: page,
    }
}